import React, { Component } from "react";
import AS from "../../../../lib/ASLibrary";
import { ApiService } from "../../../services/ApiService";
import styles from "./saletab.module.scss";
import TraficFormHelper from "../../forms/sale/trafic/trafic";
import InsuranceFormHelper from "../../forms/sale/insurance/insurance";
import DaskFormHelper from "../../forms/sale/dask/dask";
import HousingFormHelper from "../../forms/sale/housing/housing";
import GreenCardFormHelper from "../../forms/sale/greencard/greencard";
import HealthFormHelper from "../../forms/sale/health/health";
import ComplementaryHealthFormHelper from "../../forms/sale/complementaryhealth/complementaryhealth";
import CyberRiskFormHelper from "../../forms/sale/cyberrisk/cyberrisk";
import SmilePackFormHelper from "../../forms/sale/smilepack/smilepack";
import FlyFormHelper from "../../forms/sale/fly/fly";
import MysFormHelper from "../../forms/sale/mys/mys";
import TravelFormHelper from "../../forms/sale/travel/travel";
import lodash from "lodash";
import { handleArrowVisibility as handleFormsArrowVisibility } from "../../forms/sale/scrollFormContent";

const mobileAndTabletCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|iPhone|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const mediaWidthCheck = () => {
  let check = false;
  if (window.matchMedia("(max-width: 1200px)").matches) {
    check = true;
  }
  return check;
};

const isMobileOrTablet = mobileAndTabletCheck() || mediaWidthCheck();

class SaleTabHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      items: [],
      open: undefined,
      selectedIndex: 0,
    };
    this.anchorRef = React.createRef(null);
  }

  componentDidMount() {
    this.getSaleableProducts();
    if (!isMobileOrTablet) {
      window.onresize = () => {
        if (
          window.location.pathname === "/" ||
          window.location.pathname.includes("agency-view") ||
          window.location.pathname.includes("sale")
        ) {
          this.handleArrowsVisibility(
            document.getElementById("titleMainContainer").scrollLeft
          );
          let index = lodash.findIndex(this.state.items, {
            id: this.state.active,
          });
          let formContainerElemet =
            document.getElementsByClassName("saleFormContainer")[index];
          handleFormsArrowVisibility(
            formContainerElemet.scrollLeft,
            this.state.items[index].productReference
          );
        }
      };
    }
  }

  handleArrowsVisibility = (scrollLeft) => {
    let container = document.getElementById("titleMainContainer");
    let width = container?.offsetWidth;
    let childrenCount = container?.childElementCount;
    let arrowLeft = document.getElementById("productArrowLeft");
    let arrowRight = document.getElementById("productArrowRight");
    let itemsWidth = (childrenCount - 1) * 172;
    if (width >= itemsWidth) {
      arrowLeft.style.visibility = "hidden";
      arrowRight.style.visibility = "hidden";
    } else {
      arrowLeft.style.visibility = "visible";
      arrowRight.style.visibility = "visible";
      if (scrollLeft <= 0) {
        let leftArrowIcon = document.getElementById("leftArrowIcon");
        leftArrowIcon.style.animation = "none";
        leftArrowIcon.style.background = "gray";
        arrowLeft.style.pointerEvents = "none";
        arrowLeft.style.cursor = "initial";
      } else {
        arrowLeft.style.removeProperty("background");
        let leftArrowIcon = document.getElementById("leftArrowIcon");
        leftArrowIcon.style.animation = "";
        leftArrowIcon.style.background = "";
        arrowLeft.style.pointerEvents = "";
        arrowLeft.style.cursor = "pointer";
      }
      if (scrollLeft + 50 + container.offsetWidth >= itemsWidth) {
        let rightArrowIcon = document.getElementById("rightArrowIcon");
        rightArrowIcon.style.animation = "none";
        rightArrowIcon.style.background = "gray";
        arrowRight.style.pointerEvents = "none";
        arrowRight.style.cursor = "initial";
      } else {
        arrowRight.style.removeProperty("background");
        let rightArrowIcon = document.getElementById("rightArrowIcon");
        rightArrowIcon.style.animation = "";
        rightArrowIcon.style.background = "";
        arrowRight.style.pointerEvents = "";
        arrowRight.style.cursor = "pointer";
      }
    }
  };

  getSaleableProducts = () => {
    ApiService.getDapSaleableProducts().subscribe((resp) => {
      let items = this.setProductForms(resp);
      this.setState({ active: items[0].id, items: [...items] }, () => {
        if (!isMobileOrTablet) {
          this.handleArrowsVisibility(0);
          this.handleIndicatorChange(items[0].id);
        }
        handleFormsArrowVisibility(0, items[0].productReference);
      });
    });
  };

  setProductForms = (items) => {
    items = items.map((item) => {
      switch (item.productReference) {
        case "trafic":
          item.form = <TraficFormHelper />;
          return item;
        case "kasko":
          item.form = <InsuranceFormHelper />;
          return item;
        case "dask":
          item.form = <DaskFormHelper />;
          return item;
        case "konut":
          item.form = <HousingFormHelper />;
          return item;
        case "greencard":
          item.form = <GreenCardFormHelper />;
          return item;
        //TODO bireysel donusumde saglik sigortasi devreye alinirsa burayi devreye alicağiz
        // case "saglik":
        //   item.form = <HealthFormHelper />;
        //   return item;
        case "tss":
          item.form = <ComplementaryHealthFormHelper />;
          return item;
        case "cyberrisk":
          item.form = <CyberRiskFormHelper />;
          return item;
        case "gulumseten":
          item.form = <SmilePackFormHelper />;
          return item;
        case "fly":
          item.form = <FlyFormHelper />;
          return item;
        //TODO bireysel donusumde mys devreye alinirsa burayi devreye alicağiz
        // case "mys":
        //   item.form = <MysFormHelper />;
        //   return item;
        case "travel":
          item.form = <TravelFormHelper />;
          return item;
        default:
      }
    });
    return items.filter((item) => item !== undefined);
  };

  handleChange = (id) => {
    this.handleIndicatorChange(id);
    this.setState(
      {
        active: id,
      },
      () => {
        let index = lodash.findIndex(this.state.items, {
          id: this.state.active,
        });
        handleFormsArrowVisibility(0, this.state.items[index].productReference);
      }
    );
  };

  handleIndicatorChange = (id) => {
    let element = document.getElementById(`productTitle${id}`);
    let indicator = document.getElementById("productTitleIndicator");
    indicator.style.left = element.offsetLeft + "px";
  };

  handleLeftArrow = () => {
    let width = document.getElementById("titleMainContainer").offsetWidth;
    let productSize = Math.floor(width / 170);
    let scrollLeft =
      document.getElementById("titleMainContainer").scrollLeft -
      productSize * 170;
    document.getElementById("titleMainContainer").scrollLeft = scrollLeft;
    this.handleArrowsVisibility(scrollLeft);
    if (window.screen.width < 530) {
      let index = lodash.findIndex(this.state.items, {
        id: this.state.active,
      });
      let newActive = this.state.items[index - 1].id;
      this.handleChange(newActive);
    }
  };

  handleRightArrow = () => {
    let width = document.getElementById("titleMainContainer").offsetWidth;
    let productSize = Math.floor(width / 170);
    let scrollLeft =
      document.getElementById("titleMainContainer").scrollLeft +
      productSize * 170;
    document.getElementById("titleMainContainer").scrollLeft = scrollLeft;
    this.handleArrowsVisibility(scrollLeft);
    if (window.screen.width < 530) {
      let index = lodash.findIndex(this.state.items, {
        id: this.state.active,
      });
      let newActive = this.state.items[index + 1].id;
      this.handleChange(newActive);
    }
  };

  handleFormLeftArrow = () => {
    let index = lodash.findIndex(this.state.items, { id: this.state.active });
    let formContainerElemet =
      document.getElementsByClassName("saleFormContainer")[index];
    let width = formContainerElemet.offsetWidth;
    let productSize = Math.floor(width / 215);
    let scrollLeft = formContainerElemet.scrollLeft - productSize * 215;
    formContainerElemet.scrollLeft = scrollLeft;
    this.handleContentArrowVisibility(scrollLeft);
  };

  handleFormRightArrow = () => {
    let index = lodash.findIndex(this.state.items, { id: this.state.active });
    let formContainerElemet =
      document.getElementsByClassName("saleFormContainer")[index];
    let width = formContainerElemet.offsetWidth;
    let productSize = Math.floor(width / 215);
    let scrollLeft = formContainerElemet.scrollLeft + productSize * 215;
    formContainerElemet.scrollLeft = scrollLeft;
    this.handleContentArrowVisibility(scrollLeft);
  };

  handleClick = () => {};

  handleMenuItemClick = (event, index, id) => {
    handleFormsArrowVisibility(0, this.state.items[index].productReference);
    this.setState({
      selectedIndex: index,
      open: false,
      active: id,
    });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleClose = (event) => {
    if (
      this.anchorRef.current &&
      this.anchorRef.current.contains(event.target)
    ) {
      return;
    }

    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <>
        <div className={styles.container} id="container">
          {this.state.items.length > 0 && (
            <div className={styles.mainContainer} id="mainContainer">
              <div className={styles.glassContainer}>
                {isMobileOrTablet ? (
                  <AS.Grid container direction="column" alignItems="center">
                    <AS.Grid item xs={12}>
                      <AS.ButtonGroup
                        variant="contained"
                        color="primary"
                        aria-label="split button"
                        ref={this.anchorRef}
                      >
                        <AS.Button
                          className={styles.productButton}
                          onClick={this.handleClick}
                        >
                          {this.state.items[this.state.selectedIndex]?.title}
                        </AS.Button>
                        <AS.Button
                          color="primary"
                          size="small"
                          aria-controls={
                            this.state.open ? "split-button-menu" : undefined
                          }
                          aria-expanded={this.state.open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={this.handleToggle}
                          className={styles.productIconButton}
                        >
                          <AS.ArrowDropDownIcon />
                        </AS.Button>
                      </AS.ButtonGroup>
                      <AS.Popper
                        sx={{
                          zIndex: 1,
                        }}
                        style={{
                          zIndex: 1000,
                        }}
                        open={this.state.open}
                        anchorEl={this.anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <AS.Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                              overflow: "auto",
                              maxHeight: "200px",
                            }}
                          >
                            <AS.Paper>
                              <AS.ClickAwayListener
                                onClickAway={this.handleClose}
                              >
                                <AS.MenuList
                                  id="split-button-menu"
                                  autoFocusItem
                                  className={styles.productMenuList}
                                >
                                  {this.state.items.map((option, index) => (
                                    <AS.MenuItem
                                      key={option.id}
                                      selected={
                                        index === this.state.selectedIndex
                                      }
                                      onClick={(event) =>
                                        this.handleMenuItemClick(
                                          event,
                                          index,
                                          option.id
                                        )
                                      }
                                      className={styles.productMenuListItem}
                                    >
                                      {option.title}
                                    </AS.MenuItem>
                                  ))}
                                </AS.MenuList>
                              </AS.ClickAwayListener>
                            </AS.Paper>
                          </AS.Grow>
                        )}
                      </AS.Popper>
                    </AS.Grid>
                  </AS.Grid>
                ) : (
                  <div className={styles.titleContainer} id="titleContainer">
                    <div
                      className={styles.navigateIconContainer}
                      id="productArrowLeft"
                      onClick={() => this.handleLeftArrow()}
                    >
                      <AS.NavigateBeforeIcon
                        className={`${styles.navigateIcon} ${styles.leftArrowIcon}`}
                        id="leftArrowIcon"
                      />
                    </div>
                    <div
                      className={styles.titleMainContainer}
                      id="titleMainContainer"
                      onScroll={(e) =>
                        this.handleArrowsVisibility(e.target.scrollLeft)
                      }
                    >
                      <div
                        className={styles.indicator}
                        id="productTitleIndicator"
                      >
                        <div className={styles.indicatorMain}></div>
                      </div>
                      {this.state.items.map((item) => {
                        return (
                          <div
                            className={styles.title}
                            onClick={() => this.handleChange(item.id)}
                            aria-expanded={this.state.active === item.id}
                            key={item.id}
                            id={`productTitle${item.id}`}
                          >
                            <div className={styles.insetTitleContainer}>
                              <div className={styles.insetTitle}>
                                {item.title}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div
                      className={styles.navigateIconContainer}
                      id="productArrowRight"
                      onClick={() => this.handleRightArrow()}
                    >
                      <AS.NavigateNextIcon
                        className={`${styles.navigateIcon} ${styles.rightArrowIcon}`}
                        id="rightArrowIcon"
                      />
                    </div>
                  </div>
                )}
                <div
                  className={styles.contentContainer}
                  id="saleContentContainer"
                >
                  {this.state.items.map((item) => {
                    return (
                      <div
                        className={styles.content}
                        aria-expanded={this.state.active === item.id}
                        key={item.id}
                        id={`product${item.id}`}
                      >
                        {item.form}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SaleTabHelper;
