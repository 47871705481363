export const CONTACTFORM_DEFAULT = {
  alertConfig: {
    datasource: {
      alertShow: false,
      severity: undefined,
      message: undefined,
    },
    functions: {
      closeAlert: undefined,
    },
  },
};
