import React, { Component } from "react";
import styles from "./healthinstitutions.module.scss";
import AS from "../../../../lib/ASLibrary";
import { withTranslation } from "react-i18next";
import HealthInstutionsImage from "../../../assets/images/health-instutions.jpg";
import { AuthorizationContext } from "../../../contexts/AuthorizationContext";
import _lodash from "lodash";
import { HEALTH_INSTITUTIONS_DEFAULT } from "./model";
import MetaDecorator from "../../../../util/MetaDecorator";

class HealthInstitutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        model: _lodash.cloneDeep(HEALTH_INSTITUTIONS_DEFAULT),
      },
    };
  }

  static contextType = AuthorizationContext;

  handleHealthNavigation = () => {
    window.open(
      "https://www.anadolusigorta.com.tr/tr/iletisim/bize-ulasin/anlasmali-saglik-kurumu-bul",
      "_blank"
    );
  };

  handleInstitutionsNavigation = () => {
    this.props.history.push("/kurumlar");
  };

  render() {
    const t = this.props.t;
    const akyAgency = this.context.akyAgency;
    return (
      <>
        <MetaDecorator
          title={`Anlaşmalı Sağlık Kurumları | ${akyAgency.agencyTitle} Anadolu Sigorta `}
          description={this.state.datasource.model.helmetConfig.description}
        />
        <div className={styles.container}>
          <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
              <div
                className={styles.institutionsTitleLink}
                onClick={() => this.handleInstitutionsNavigation()}
              >
                {t("institutions.health_institutions.main_path")}
              </div>
              {" > "}
              {t("institutions.health_institutions.sub_path")}
            </div>
            <div className={styles.servicesContainer}>
              <div className={styles.contactedServicesMainContainer}>
                <div className={styles.contractedServicesContainer}>
                  <div
                    className={styles.accordionMainContentContainer}
                    style={{
                      backgroundImage: `url(${HealthInstutionsImage})`,
                    }}
                  >
                    <div className={styles.contractedLogoContainer}></div>
                    <div className={styles.contractedTitleContainer}>
                      {t("institutions.health_institutions.health_title")}
                    </div>
                  </div>
                </div>
                <div className={styles.contractedServicesBack}>
                  <AS.Button
                    className={styles.serviceButton}
                    onClick={() => this.handleHealthNavigation()}
                    endIcon={<AS.ExitToAppIcon />}
                  >
                    {t("institutions.health_institutions.all_institution")}
                  </AS.Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(HealthInstitutions);
