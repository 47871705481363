export const PRODUCT_DEFAULT = {
  helmetConfig: {
    description: "Ürünlerimiz Sayfası",
    title:
      "Sağlık sigortasından araç sigortasına, konut sigortasından seyahat sigortasına tüm poliçelerimiz hakkında detaylı bilgi için sayfamızı ziyaret edin.",
  },
  parentProductCardsConfig: {
    datasource: {
      parentProducts: [],
      otherProducts: [],
    },
    functions: {},
  },
};
