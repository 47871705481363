import React, { Component } from "react";
import styles from "./products.module.scss";
import _lodash from "lodash";
import { PRODUCT_DEFAULT } from "./model";
import { ApiService } from "../../services/ApiService";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import { withTranslation } from "react-i18next";
import ParentProductsCardHelper from "../../helpers/cards/parentproductcards/parentproductcards";
import { withRouter } from "react-router-dom";

const webProductSize = Array.from({ length: 100 }, (_, i) => i);

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        model: _lodash.cloneDeep(PRODUCT_DEFAULT),
        selectedParentId: undefined,
      },
    };
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    if (window.location.href.includes("?")) {
      this.handlePreviewParentWebProducts();
    } else {
      this.getParentWebProducts();
    }
  }

  handlePreviewParentWebProducts = () => {
    let location = window.location.href;
    let locationInfoList = location.split("?");
    ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
      let mainLocation = locationInfoList[0] + "?" + resp;
      let url = new URL(
        decodeURI(mainLocation.replace(/\+/g, "%20"))
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
      );
      let urlParams;
      let match,
        pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, " ")),
        query = url.href;
      urlParams = {};
      while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
      }
      let previewParentWebProducts = [];
      let previewOtherWebProducts = [];
      for (let counter of webProductSize) {
        let webProduct = {};
        if (urlParams[`wp${counter}ppid`] != 0) {
          continue;
        }
        webProduct.id = urlParams[`wp${counter}id`];
        webProduct.photoUrl = urlParams[`wp${counter}photo`];
        webProduct.name = urlParams[`wp${counter}name`];
        if (previewParentWebProducts.length < 7) {
          previewParentWebProducts.push(webProduct);
        } else {
          previewOtherWebProducts.push(webProduct);
        }
      }
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.parentProductCardsConfig.datasource.parentProducts = [
          ...previewParentWebProducts,
        ];
        datasource.model.parentProductCardsConfig.datasource.otherProducts = [
          ...previewOtherWebProducts,
        ];
        return { datasource };
      });
    });
  };

  getParentWebProducts = () => {
    ApiService.getDapWebProductsByParentProduct("parent").subscribe((resp) => {
      let parentProducts = [];
      let otherProducts = [];
      resp.forEach((item) => {
        if (parentProducts.length < 7) {
          parentProducts.push(item);
        } else {
          item.otherProductPath = `diger-urunler/${this.handleLink(item.name)}`;
          otherProducts.push(item);
        }
      });

      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.parentProductCardsConfig.datasource.parentProducts = [
          ...parentProducts,
        ];
        datasource.model.parentProductCardsConfig.datasource.otherProducts = [
          ...otherProducts,
        ];
        return { datasource };
      });
    });
  };

  handleQueryParams = () => {
    let location = window.location.href;
    if (window.location.href.includes("?")) {
      return location.slice(location.lastIndexOf("?"), location.length);
    }
    return "";
  };

  handleLink = (name) => {
    return name
      .toLowerCase()
      .replace(/[()-/,]/g, "")
      .replace(/\s+/g, "-")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u");
  };

  render() {
    const t = this.props.t;
    return (
      <div className={styles.container}>
        <div
          className={`${styles.productsTitleContainer} mobile-title-font-size`}
        >
          {t("products.title")}
        </div>
        <div className={styles.parentProductsContainer}>
          <ParentProductsCardHelper
            config={this.state.datasource.model.parentProductCardsConfig}
            params={this.handleQueryParams()}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Products));
