import { BehaviorSubject } from "rxjs";
import jwt_decode from "jwt-decode";

const token = new BehaviorSubject();
const token$ = token.asObservable();

const getToken = () => {
  if (!token.getValue()) {
    return "";
  }
  return token.getValue();
};

const setToken = (dapToken) => {
  token.next(dapToken);
  sessionStorage.setItem("token", token.value);
};

const isAdminUser = (authorities) => {
  return authorities.some((role) => role === "dap-admin");
};

const isAdminUserHasAgency = (authorities) => {
  let agentInfo = getAgentId();
  let hasAgent = agentInfo != 0 && agentInfo !== null;
  return isAdminUser(authorities) && hasAgent;
};

const getAgentId = () => {
  return tokenExist() ? jwt_decode(getToken()).agentId : null;
};

const tokenExist = () => {
  return !!getToken();
};

export const AuthorizationService = {
  isAdminUser,
  isAdminUserHasAgency,
  getToken,
  setToken,
  getAgentId,
  token,
};
