import {
  Avatar,
  Table,
  ComboBox,
  TcknField,
  NumberField,
  DatePicker,
  PhoneField,
} from "as-react-component-library";
import { TextField as ASTextField } from "as-react-component-library";
import { Paper, Chip } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Badge from "@material-ui/core/Badge";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import SaveIcon from "@material-ui/icons/Save";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuIcon from "@material-ui/icons/Menu";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import RefreshIcon from "@material-ui/icons/Refresh";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ListSubheader from "@material-ui/core/ListSubheader";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import EmailIcon from "@material-ui/icons/Email";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import HouseIcon from "@material-ui/icons/House";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import BusinessCenterOutlinedIcon from "@material-ui/icons/BusinessCenterOutlined";
import StoreMallDirectoryOutlinedIcon from "@material-ui/icons/StoreMallDirectoryOutlined";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';

const AS = {
  Button,
  TextField,
  Paper,
  Avatar,
  Chip,
  Table,
  DeleteIcon,
  EditIcon,
  AddIcon,
  Pagination,
  VisibilityIcon,
  BorderColorIcon,
  CloseIcon,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  FormControl,
  FormHelperText,
  Tooltip,
  Typography,
  Alert,
  Snackbar,
  WallpaperIcon,
  IconButton,
  PhotoCamera,
  Badge,
  Checkbox,
  FormControlLabel,
  InputLabel,
  SaveIcon,
  Select,
  MenuItem,
  Autocomplete,
  SearchIcon,
  InputAdornment,
  ComboBox,
  ArrowDownwardIcon,
  List,
  ListItem,
  TextareaAutosize,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress,
  MenuIcon,
  FavoriteBorderIcon,
  EmojiTransportationIcon,
  LinkedInIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ExpandMoreIcon,
  ArrowRightAltIcon,
  VerifiedUserIcon,
  KeyboardBackspaceIcon,
  RefreshIcon,
  OpenInNewIcon,
  TcknField,
  NumberField,
  DatePicker,
  ASTextField,
  PhoneField,
  ListSubheader,
  PhoneInTalkIcon,
  PhoneInTalkOutlinedIcon,
  EmailIcon,
  EmailOutlinedIcon,
  LocationOnIcon,
  LocationOnOutlinedIcon,
  VerifiedUserOutlinedIcon,
  AppBar,
  Toolbar,
  Drawer,
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
  useTheme,
  Hidden,
  Divider,
  ExpandLessIcon,
  ArrowForwardIosIcon,
  ArrowBackIosIcon,
  Tabs,
  Tab,
  HouseIcon,
  ExitToAppIcon,
  PauseIcon,
  PlayArrowIcon,
  NavigateNextIcon,
  NavigateBeforeIcon,
  DoubleArrowIcon,
  LiveHelpIcon,
  BusinessCenterOutlinedIcon,
  StoreMallDirectoryOutlinedIcon,
  AutorenewIcon,
  ButtonGroup,
  ArrowDropDownIcon,
  ClickAwayListener,
  Grow,
  Popper,
  MenuList,
  Grid
};

export default AS;
