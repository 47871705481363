import React from "react";
import { withRouter } from "react-router";
import AS from "../../../../lib/ASLibrary";
import styles from "./message.module.scss";

const MessageDialogHelper = (props) => {
  const handleBack = () => {
    window.history.back();
  };
  return (
    <AS.Dialog
      open={props.config.datasource.dialogShow}
      disableBackdropClick={true}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      PaperProps={{
        style: {
          minWidth: "300px",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <AS.DialogTitle
        id="form-dialog-title"
        style={{ paddingBottom: "0", marginTop: "2rem" }}
      />
      <AS.DialogContent style={{ paddingTop: "0" }}>
        <div className={styles.contentContainer}>
          {props.config.datasource.message}
        </div>
        <div style={{ textAlign: "center", marginBottom: "2rem" }}>
          <AS.Button className={styles.backButton} onClick={handleBack}>
            Önceki Sayfaya Git
          </AS.Button>
        </div>
      </AS.DialogContent>
    </AS.Dialog>
  );
};

export default withRouter(MessageDialogHelper);
