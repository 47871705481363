import { withStyles } from "@material-ui/core/styles";
import lodash from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AS from "../../../../../lib/ASLibrary";
import { AuthorizationContext } from "../../../../contexts/AuthorizationContext";
import { ApiService } from "../../../../services/ApiService";
import MessageDialogHelper from "../../../dialogs/message/message";
import CustomPlateSelect from "../../../inputs/plateselect/plateselect";
import CustomSelect from "../../../inputs/select/select";
import { validatePlateText } from "../../../validations/platevalidation";
import { Validation } from "../../../validations/validation";
import { encryptForm } from "../saleformredirection";
import {
  handleArrowVisibility,
  handleFormLeftArrow,
  handleFormRightArrow,
} from "../scrollFormContent";
import styles from "./insurance.module.scss";
import { INSURANCE_DEFAULT } from "./model";

const { REACT_APP_OFFER_REDIRECTION_URL } = process.env;

const selectItems = [
  { id: 0, name: "Kasko poliçemi yenilemek istiyorum." },
  {
    id: 2,
    name: "İkinci el araç satın aldım, poliçe yaptırmak istiyorum.",
  },
  {
    id: 1,
    name: "Sıfır km araç satın aldım, poliçe yaptırmak istiyorum.",
  },
];

const plateCodes = Array.from({ length: 81 }, (_, i) => {
  if (i < 9) {
    return "0" + (i + 1);
  } else {
    return i + 1;
  }
});

const PACKET_TYPE = "casco";

const TextField = withStyles((theme) => ({
  root: {
    color: "#162a3b !important",
    width: "388px",
    "& .MuiInputBase-root": {
      color: "#162a3b",
      height: "40px",
    },
  },
  input: {
    color: "#162a3b",
  },
}))(AS.TextField);

class InsuranceFormHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionType: 0,
      id: undefined,
      plateCode: "01",
      plateText: undefined,
      plateTextError: "",
      showIdError: false,
      idError: "",
      model: lodash.cloneDeep(INSURANCE_DEFAULT),
    };
  }

  componentDidMount() {
    this.setState({
      model: this.setDefaults(),
    });
  }

  setDefaults = () => {
    let model = lodash.cloneDeep(INSURANCE_DEFAULT);
    model.messageDialogConfig.functions.closeDialog = this.closeMessageDialog;
    return model;
  };

  static contextType = AuthorizationContext;

  closeMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = false;
      return { datasource };
    });
  };

  openMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = true;
      return { datasource };
    });
  };

  handleSelectChange = (e) => {
    this.setState(
      {
        transactionType: e.target.value,
      },
      () => {
        handleArrowVisibility(0, "kasko");
      }
    );
  };

  handlePlateCodeChange = (e) => {
    this.setState({
      plateCode: e.target.value,
    });
  };

  handlePlateTextChange = (e) => {
    let text = e.target.value.toUpperCase();
    let plateTextError = "";
    this.setState({
      plateText: text,
      plateTextError: plateTextError,
    });
  };

  handlePlateTextValidation = (text) => {
    let regex = /^(([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2,3}))$/;
    let plateTextError = "";
    if (!text || text.match(regex) === null) {
      plateTextError = "sale.plate_warn_message";
    }
    return plateTextError;
  };

  checkPlateText = (e) => {
    validatePlateText(e, this.state.plateText);
  };

  checkIdText = (e) => {
    const onlyNumber = e.target.value.replace(/[^\d]+/g, "");
    let error = "";
    if (Validation.validateTckn(onlyNumber)) {
      error = "";
    } else if (Validation.validateVkn(onlyNumber)) {
      error = "";
    } else {
      error = "Lütfen TC Kimlik/Vergi Kimlik Numarasını kontrol ediniz.";
    }
    this.setState({
      idError: error,
      showIdError: false,
    });
  };

  handleIdChange = (e) => {
    const onlyNumber = e.target.value.replace(/[^\d]+/g, "");
    this.setState({
      id: onlyNumber,
      showIdError: false,
    });
  };

  handleAcreageChange = (e) => {
    this.setState({
      acreage: e.target.value,
    });
  };

  handleBackNavigation = () => {
    this.props.history.push("/satis");
  };

  handleValidation = () => {
    let isValid = true;
    let plateTextError = "";
    let idError = this.state.idError;
    if (
      this.state.transactionType !== 2 &&
      this.state.plateText !== "YK" &&
      this.handlePlateTextValidation(this.state.plateText)
    ) {
      plateTextError = this.handlePlateTextValidation(this.state.plateText);
      isValid = false;
    }
    if (!this.state.id) {
      idError = "Lütfen TC Kimlik/Vergi Kimlik Numarası giriniz.";
      isValid = false;
    } else {
      if (idError.length !== 0) {
        isValid = false;
      }
    }
    this.setState({
      plateTextError: plateTextError,
      showIdError: true,
      idError: idError,
    });
    return isValid;
  };

  quickOffer = () => {
    const preview = this.context.preview;
    if (!this.handleValidation() || preview) {
      return;
    }
    const dapAgency = this.context.dapAgency;
    let formInputs = `&ac=${dapAgency.id}&product=${PACKET_TYPE}&id=${this.state.id}&plateText=${this.state.plateText}&plateCode=${this.state.plateCode}&transactionType=${this.state.transactionType}&registrationNo=${this.state.registrationNo}&registrationCode=${this.state.registrationCode}`;
    let encrpytedFormInputs = encryptForm(formInputs, this.context.akyAgency);
    ApiService.saveToRedis(encrpytedFormInputs).subscribe((resp) => {
      window.location.href = `${REACT_APP_OFFER_REDIRECTION_URL}?${resp}`;
    });
  };

  render() {
    const t = this.props.t;
    return (
      <div className="saleContainer">
        <div
          className="navigateIconContainer"
          id="productFormArrowLeftkasko"
          onClick={() => handleFormLeftArrow("kasko")}
        >
          <AS.NavigateBeforeIcon
            className="navigateIcon contentArrowIcon"
            id="formLeftArrowIconkasko"
          />
        </div>
        <div className="saleMainFormInputContainer">
          <div
            className="saleFormContainer"
            onScroll={(e) =>
              handleArrowVisibility(e.target.scrollLeft, "kasko")
            }
          >
            <div className="saleInputContainer">
              <div className="saleLabel">{t("sale.transaction_type")}</div>
              <CustomSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.transactionType}
                onChange={this.handleSelectChange}
                className="saleSelect"
              >
                {selectItems.map((item) => {
                  return (
                    <AS.MenuItem value={item.id} className={styles.menuItem}>
                      {item.name}
                    </AS.MenuItem>
                  );
                })}
              </CustomSelect>
            </div>
            <div className="saleInputContainer">
              <div className="saleLabel">{t("sale.tckn_vkn")}</div>
              <TextField
                className={styles.textField}
                value={this.state.id}
                onChange={this.handleIdChange}
                onKeyDown={(e) => this.checkIdText(e)}
                onKeyUp={(e) => this.checkIdText(e)}
                inputProps={{ maxLength: 11 }}
              />
              {this.state.showIdError && (
                <AS.FormHelperText
                  id="helper-text-id"
                  error={this.state.idError.length !== 0}
                >
                  {this.state.idError}
                </AS.FormHelperText>
              )}
            </div>
            <div className="saleInputContainer">
              <div className="saleLabel">{t("sale.plate")}</div>
              <div className="salePlateContainer">
                <div className="salePlateCodeContainer">
                  <CustomPlateSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.plateCode}
                    onChange={this.handlePlateCodeChange}
                    className="saleSelect"
                    MenuProps={{
                      disableScrollLock: false,
                      classes: {
                        paper: {
                          maxHeight: 500,
                        },
                      },
                    }}
                  >
                    {plateCodes.map((item) => {
                      return (
                        <AS.MenuItem value={item} className={styles.menuItem}>
                          {item}
                        </AS.MenuItem>
                      );
                    })}
                  </CustomPlateSelect>
                </div>
                <div className="salePlateTextContainer">
                  <TextField
                    className={styles.textField}
                    value={this.state.plateText}
                    onChange={this.handlePlateTextChange}
                    onKeyDown={(e) => this.checkPlateText(e)}
                    onKeyUp={(e) => this.checkPlateText(e)}
                  />
                  <AS.FormHelperText
                    id="helper-text"
                    error={this.state.plateTextError.length !== 0}
                  >
                    {t(this.state.plateTextError)}
                  </AS.FormHelperText>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="navigateIconContainer"
          id="productFormArrowRightkasko"
          onClick={() => handleFormRightArrow("kasko")}
        >
          <AS.NavigateNextIcon
            className="navigateIcon contentArrowIcon"
            id="formRightArrowIconkasko"
          />
        </div>
        <div className="saleButtonContainer">
          <AS.Button
            className="proposalButton"
            onClick={() => this.quickOffer()}
          >
            {t("sale.show_proposals")}
          </AS.Button>
        </div>
        <MessageDialogHelper config={this.state.model.messageDialogConfig} />
      </div>
    );
  }
}

export default withTranslation()(InsuranceFormHelper);
