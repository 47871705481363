export const TRAFIC_DEFAULT = {
  registryNoDialogConfig: {
    datasource: {
      dialogShow: false,
    },
    functions: {
      closeDialog: undefined,
    },
  },
  messageDialogConfig: {
    datasource: {
      dialogShow: false,
      message: undefined,
    },
    functions: {
      closeDialog: undefined,
    },
  },
};
