import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const containerStyle = {
  position: "relative",
  height: "275px",
  border: "2px solid white",
  borderRadius: "5px",
  boxShadow: "-1px 0px 30px 0.5px rgba(255, 255, 255, 0.2)",
};

export class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ padding: "10px 0" }}>
        <Map
          google={this.props.google}
          zoom={14}
          initialCenter={{
            lat: this.props.coordinateY,
            lng: this.props.coordinateX,
          }}
          containerStyle={containerStyle}
        >
          <Marker onClick={this.onMarkerClick} name={"Current location"} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(MapContainer);
