import { Component } from "react";
import { withRouter } from "react-router-dom";
import { AuthorizationContext } from "../contexts/AuthorizationContext";

class ScrollToTop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthorizationContext;

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      const offerButtonScroll = this.context.offerButtonScroll;
      if (
        !window.location.pathname.includes("agency-view") &&
        !window.location.pathname.includes("sale") &&
        (!offerButtonScroll ||
          (this.props.history.location.pathname === "/" &&
            prevProps.location.pathname !== "/"))
      ) {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return null;
  }
}

export default withRouter(ScrollToTop);
