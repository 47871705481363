import _lodash, { unescape } from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AS from "../../../lib/ASLibrary";
import MetaDecorator from "../../../util/MetaDecorator";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import CardCarouselHelper from "../../helpers/cards/cardcarousel/cardcarousel";
import { ApiService } from "../../services/ApiService";
import styles from "./about.module.scss";
import { ABOUT_DEFAULT } from "./model";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const aboutSize = Array.from({ length: 50 }, (_, i) => i);

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        model: _lodash.cloneDeep(ABOUT_DEFAULT),
        aboutItem: undefined,
        photos: [],
        prizes: [],
      },
    };
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    if (window.location.href.includes("?")) {
      this.handlePreviewAbouts();
    } else {
      this.getAboutItem();
      this.getPhotos();
      this.getPrizes();
    }
    window.onresize = () => {
      if (window.location.href.includes("biz-kimiz")) {
        let aboutItem = document.getElementById("aboutItemContent");
        aboutItem.style.textAlign = this.handleTextAlign(
          this.state.datasource.aboutItem.content
        );
      }
    };
  }

  handlePreviewAbouts = () => {
    let location = window.location.href;
    let locationInfoList = location.split("?");
    ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
      let mainLocation = locationInfoList[0] + "?" + resp;
      let url = new URL(
        decodeURI(mainLocation.replace(/\+/g, "%20"))
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
      );
      let urlParams;
      let match,
        pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, " ")),
        query = url.href;
      urlParams = {};
      while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
      }
      let counter = 0;
      let aboutItem = {};
      let photos = [];
      let prizes = [];
      aboutSize.forEach(() => {
        let about = {};
        about.id = `abouts${counter}`;
        if (!urlParams[`abouts${counter}photourl`]) {
          return;
        }
        about.photoUrl = urlParams[`abouts${counter}photourl`];
        about.title = urlParams[`abouts${counter}title`];
        about.content = urlParams[`abouts${counter}content`];
        about.type = urlParams[`abouts${counter}type`];
        switch (about.type) {
          case "about":
            aboutItem = { ...about };
            break;
          case "photo":
            photos.push(about);
            break;
          case "prize":
            prizes.push(about);
            break;
          default:
            break;
        }
        counter = counter + 1;
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.aboutItem = { ...aboutItem };
        datasource.photos = [...photos];
        datasource.prizes = [...prizes];
        return { datasource };
      });
    });
  };

  getAboutItem = () => {
    ApiService.getAbout("about").subscribe((resp) => {
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        if (resp.content.length > 0) {
          datasource.aboutItem = { ...resp.content[0] };
        } else {
          datasource.aboutItem = undefined;
        }
        return { datasource };
      });
    });
  };

  getPhotos = () => {
    ApiService.getAbout("photo").subscribe((resp) => {
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.photos = [...resp.content];
        return { datasource };
      });
    });
  };

  getPrizes = () => {
    ApiService.getAbout("prize").subscribe((resp) => {
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.prizes = [...resp.content];
        return { datasource };
      });
    });
  };

  contentHandler = (item) => {
    let regexColor = new RegExp("rgb(56,56,56);", "g");
    let regexFontSize = new RegExp("font-size: 14px", "g");
    let regexFontFamily = new RegExp(
      "font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;",
      "g"
    );
    item.content =
      this.state.datasource.photos.length > 0 ||
      this.state.datasource.prizes.length > 0
        ? item.content.replace(regexColor, "white;")
        : item.content.replace(regexColor, "black;");
    item.content = item.content.replace(regexFontSize, "font-size: 16px");
    item.content = item.content.replace(
      regexFontFamily,
      "font-family: Roboto, sans-serif;"
    );
    let unescapedContent = unescape(item.content);
    return (
      <p
        id="aboutItemContent"
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: unescapedContent }}
        style={{
          color:
            this.state.datasource.photos.length > 0 ||
            this.state.datasource.prizes.length > 0
              ? "white"
              : "black",
          textAlign: this.handleTextAlign(unescapedContent),
        }}
      />
    );
  };

  handleTextAlign = (text) => {
    let textAlign = "left";
    if (window.innerWidth > 1200 && text.replace(/<[^>]*>/g, "").length < 140) {
      textAlign = "center";
    }
    if (window.innerWidth > 1000 && text.replace(/<[^>]*>/g, "").length < 100) {
      textAlign = "center";
    }
    if (window.innerWidth > 800 && text.replace(/<[^>]*>/g, "").length < 70) {
      textAlign = "center";
    }
    if (window.innerWidth > 400 && text.replace(/<[^>]*>/g, "").length < 40) {
      textAlign = "center";
    }
    return textAlign;
  };

  handleMoreContent = () => {
    document.getElementById("content").style.maxHeight = "none";
  };

  handleContactButtonHover = () => {
    const akyAgency = this.context.akyAgency;
    document.getElementById(
      "contact-button"
    ).innerHTML = `0 (${akyAgency.phoneInfoList[0].phoneCode}) ${akyAgency.phoneInfoList[0].phoneNumber}`;
  };

  handleContactButtonLeave = () => {
    document.getElementById("contact-button").innerHTML = this.props.t(
      "about_us.contact_us"
    );
  };

  handlePhoneCall = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  render() {
    const t = this.props.t;
    const akyAgency = this.context.akyAgency;
    return (
      <div className={styles.container}>
        <MetaDecorator
          title={`${akyAgency.addressInfo?.provinceName} ${akyAgency.agencyTitle} | Anadolu Sigorta`}
          description={`Türkiye'nin ilk ulusal sigorta şirketi Anadolu Sigorta'nın yetkili acentesi olan ${akyAgency.agencyTitle} hakkında bilgi almak için sayfamızı ziyaret edin.`}
        />
        {this.state.datasource.aboutItem &&
          this.state.datasource.aboutItem.photoUrl &&
          Object.keys(this.state.datasource.aboutItem).length !== 0 && (
            <div
              style={{
                backgroundImage: this.state.datasource.aboutItem
                  ? `url(${BASE_URL}${this.state.datasource.aboutItem.photoUrl})`
                  : "",
              }}
              className={styles.aboutMainContainer}
            >
              {this.state.datasource.aboutItem.photoUrl !== "undefined" && (
                <div className={styles.aboutContainer}></div>
              )}
            </div>
          )}

        {this.state.datasource.aboutItem &&
          Object.keys(this.state.datasource.aboutItem).length !== 0 && (
            <div
              className={styles.aboutContentContainer}
              style={{
                backgroundColor:
                  this.state.datasource.photos.length > 0 ||
                  this.state.datasource.prizes.length > 0
                    ? "#162a3b"
                    : "white",
              }}
            >
              <div
                className={styles.aboutSubTitleContainer}
                style={{
                  color:
                    this.state.datasource.photos.length > 0 ||
                    this.state.datasource.prizes.length > 0
                      ? "white"
                      : "black",
                }}
              >
                {this.state.datasource.aboutItem.title}
              </div>
              <div className={styles.dividerContainer}>
                <div className={styles.divider}></div>
              </div>
              {this.contentHandler(this.state.datasource.aboutItem)}
              <div className={styles.aboutButtonContainer}>
                {akyAgency.phoneInfoList && akyAgency.phoneInfoList[0] ? (
                  <AS.Button
                    className={styles.aboutButton}
                    onMouseOver={this.handleContactButtonHover}
                    onMouseLeave={this.handleContactButtonLeave}
                    onClick={() =>
                      this.handlePhoneCall(
                        `0${akyAgency.phoneInfoList[0].phoneCode}${akyAgency.phoneInfoList[0].phoneNumber}`
                      )
                    }
                  >
                    <span id="contact-button" className={styles.contactButton}>
                      {t("about_us.contact_us")}
                    </span>
                  </AS.Button>
                ) : null}
              </div>
            </div>
          )}

        {this.state.datasource.photos.length > 0 && (
          <div
            className={`${styles.photoContainer} 
             ${
               !this.state.datasource.aboutItem ||
               (this.state.datasource.aboutItem &&
                 Object.keys(this.state.datasource.aboutItem).length === 0)
                 ? styles.paddingTop
                 : ""
             }`}
          >
            <div className={styles.photoContentContainer}>
              <CardCarouselHelper items={this.state.datasource.photos} />
            </div>
          </div>
        )}
        {this.state.datasource.prizes.length > 0 && (
          <div
            className={`${styles.prizeContainer} ${
              this.state.datasource.photos.length === 0 ? styles.paddingTop : ""
            }`}
          >
            <div
              className={`${styles.prizeTitleContainer} 
               ${
                 this.state.datasource.photos.length > 0 ? styles.marginTop : ""
               }
               ${
                 this.state.datasource.photos.length > 0
                   ? styles.marginBottomv1
                   : styles.marginBottomv2
               }
               `}
            >
              <h2
                class={`${
                  this.state.datasource.photos.length > 0
                    ? "decorated"
                    : "decoratedNoSeparator"
                }`}
              >
                <span>{t("about_us.prizes")}</span>
              </h2>
            </div>
            <div className={styles.prizeContentContainer}>
              <CardCarouselHelper items={this.state.datasource.prizes} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(About);
