import { withStyles } from "@material-ui/core/styles";
import lodash from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AS from "../../../../../lib/ASLibrary";
import { AuthorizationContext } from "../../../../contexts/AuthorizationContext";
import { ApiService } from "../../../../services/ApiService";
import MessageDialogHelper from "../../../dialogs/message/message";
import { encryptForm } from "../saleformredirection";
import {
  handleArrowVisibility,
  handleFormLeftArrow,
  handleFormRightArrow,
} from "../scrollFormContent";
import { MYS_DEFAULT } from "./model";
import styles from "./mys.module.scss";

const { REACT_APP_OFFER_REDIRECTION_URL } = process.env;

const PACKET_TYPE = "mys";

const Tckn = withStyles((theme) => ({
  root: {
    color: "#162a3b !important",
    width: "388px",

    "& .MuiInputBase-root": {
      color: "#162a3b",
      height: "40px",
    },
  },
  input: {
    color: "#162a3b",
  },
}))(AS.TcknField);

class MysFormHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      showIdError: false,
      idError: "",
      model: lodash.cloneDeep(MYS_DEFAULT),
    };
  }

  componentDidMount() {
    this.setState({
      model: this.setDefaults(),
    });
  }

  setDefaults = () => {
    let model = lodash.cloneDeep(MYS_DEFAULT);
    model.messageDialogConfig.functions.closeDialog = this.closeMessageDialog;
    return model;
  };

  static contextType = AuthorizationContext;

  closeMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = false;
      return { datasource };
    });
  };

  openMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = true;
      return { datasource };
    });
  };

  idValidationChange = (state) => {
    let error =
      state === "Not Validated"
        ? "Lütfen TC Kimlik Numarasını kontrol ediniz."
        : "";
    this.setState({
      idError: error,
      showIdError: false,
    });
  };

  handleIdChange = (e) => {
    this.setState({
      id: e.target.value,
      showIdError: false,
    });
  };

  handleBackNavigation = () => {
    this.props.history.push("/satis");
  };

  handleValidation = () => {
    let isValid = true;
    let idError = this.state.idError;
    if (!this.state.id) {
      idError = "Lütfen TC Kimlik Numarası giriniz.";
      isValid = false;
    } else {
      if (idError.length !== 0) {
        isValid = false;
      }
    }
    this.setState({
      showIdError: true,
      idError: idError,
    });
    return isValid;
  };

  quickOffer = () => {
    const preview = this.context.preview;
    if (!this.handleValidation() || preview) {
      return;
    }
    const dapAgency = this.context.dapAgency;
    let formInputs = `&ac=${dapAgency.id}&product=${PACKET_TYPE}&id=${this.state.id}`;
    let encrpytedFormInputs = encryptForm(formInputs, this.context.akyAgency);
    ApiService.saveToRedis(encrpytedFormInputs).subscribe((resp) => {
      window.location.href = `${REACT_APP_OFFER_REDIRECTION_URL}?${resp}`;
    });
  };

  render() {
    const t = this.props.t;
    return (
      <div className="saleContainer">
        <div
          className="navigateIconContainer"
          id="productFormArrowLeftmys"
          onClick={() => handleFormLeftArrow("mys")}
        >
          <AS.NavigateBeforeIcon
            className="navigateIcon contentArrowIcon"
            id="formLeftArrowIconmys"
          />
        </div>
        <div className="saleMainFormInputContainer">
          <div
            className="saleFormContainer"
            onScroll={(e) => handleArrowVisibility(e.target.scrollLeft, "mys")}
          >
            <div className="saleInputContainer">
              <div className="saleLabel">{t("sale.id")}</div>
              <Tckn
                className={styles.textField}
                label=""
                value={this.state.id}
                onValidationStateChanged={this.idValidationChange}
                onChangeText={this.handleIdChange}
                warnMessage={undefined}
              />
              {this.state.showIdError && (
                <AS.FormHelperText
                  id="helper-text-id"
                  error={this.state.idError.length !== 0}
                >
                  {this.state.idError}
                </AS.FormHelperText>
              )}
            </div>
          </div>
        </div>
        <div
          className="navigateIconContainer"
          id="productFormArrowRightmys"
          onClick={() => handleFormRightArrow("mys")}
        >
          <AS.NavigateNextIcon
            className="navigateIcon contentArrowIcon"
            id="formRightArrowIconmys"
          />
        </div>
        <div className="saleButtonContainer">
          <AS.Button
            className="proposalButton"
            onClick={() => this.quickOffer()}
          >
            {t("sale.show_proposals")}
          </AS.Button>
        </div>
        <MessageDialogHelper config={this.state.model.messageDialogConfig} />
      </div>
    );
  }
}

export default withTranslation()(MysFormHelper);
