import React, { Component } from "react";
import AS from "../../../../lib/ASLibrary";
import { withStyles } from "@material-ui/core/styles";

const Select = withStyles((theme) => ({
  root: {
    color: "#162a3b",
    width: "250px",
  },
  icon: {
    color: "#162a3b",
  },
}))(AS.Select);

class CustomSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <Select MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "center"
      },
      getContentAnchorEl: null
    }} {...this.props}>{this.props.children}</Select>;
  }
}

export default CustomSelect;
