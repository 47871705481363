import { withStyles } from "@material-ui/core/styles";
import lodash from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AS from "../../../../../lib/ASLibrary";
import { AuthorizationContext } from "../../../../contexts/AuthorizationContext";
import { ApiService } from "../../../../services/ApiService";
import MessageDialogHelper from "../../../dialogs/message/message";
import CustomSelect from "../../../inputs/select/select";
import { encryptForm } from "../saleformredirection";
import {
  handleArrowVisibility,
  handleFormLeftArrow,
  handleFormRightArrow,
} from "../scrollFormContent";
import styles from "./housing.module.scss";
import { HOUSING_DEFAULT } from "./model";

const { REACT_APP_OFFER_REDIRECTION_URL } = process.env;

const selectItems = [
  { id: 0, name: "Poliçemi yenilemek istiyorum." },
  { id: 1, name: "Yeni poliçe oluşturmak istiyorum." },
];

const PACKET_TYPE = "konut";

const Tckn = withStyles((theme) => ({
  root: {
    color: "#162a3b !important",
    width: "388px",

    "& .MuiInputBase-root": {
      color: "#162a3b",
      height: "40px",
    },
  },
  input: {
    color: "#162a3b",
  },
}))(AS.TcknField);

const TextField = withStyles((theme) => ({
  root: {
    color: "#162a3b !important",
    width: "388px",
    "& .MuiInputBase-root": {
      color: "#162a3b",
      height: "40px",
    },
  },
  input: {
    color: "#162a3b",
  },
}))(AS.TextField);

class HousingFormHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionType: 0,
      id: undefined,
      policyNo: undefined,
      acreage: undefined,
      showIdError: false,
      idError: "",
      policyNoError: "",
      acreageError: "",

      model: lodash.cloneDeep(HOUSING_DEFAULT),
    };
  }

  componentDidMount() {
    this.setState({
      model: this.setDefaults(),
    });
  }

  setDefaults = () => {
    let model = lodash.cloneDeep(HOUSING_DEFAULT);
    model.messageDialogConfig.functions.closeDialog = this.closeMessageDialog;
    return model;
  };

  static contextType = AuthorizationContext;

  closeMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = false;
      return { datasource };
    });
  };

  openMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = true;
      return { datasource };
    });
  };

  handleSelectChange = (e) => {
    this.setState(
      {
        transactionType: e.target.value,
        policyNo: undefined,
        acreage: undefined,
      },
      () => {
        handleArrowVisibility(0, "konut");
      }
    );
  };

  idValidationChange = (state) => {
    let error =
      state === "Not Validated"
        ? "Lütfen TC Kimlik Numarasını kontrol ediniz."
        : "";
    this.setState({
      idError: error,
      showIdError: false,
    });
  };

  handleIdChange = (e) => {
    this.setState({
      id: e.target.value,
      showIdError: false,
    });
  };

  handlePolicyNoChange = (e) => {
    let onlyNumber = e.target.value.replace(/[^\d]+/g, "");
    let policyNoError = "";
    this.setState({
      policyNo: onlyNumber,
      policyNoError: policyNoError,
    });
  };

  handleAcreageChange = (e) => {
    let onlyNumber = e.target.value.replace(/[^\d]+/g, "");
    let acreageError = "";
    this.setState({
      acreage: onlyNumber,
      acreageError: acreageError,
    });
  };

  handleBackNavigation = () => {
    this.props.history.push("/satis");
  };

  handleValidation = () => {
    let isValid = true;
    let idError = this.state.idError;
    let policyNoError = this.state.policyNoError;
    let acreageError = this.state.acreageError;
    if (!this.state.id) {
      idError = "Lütfen TC Kimlik Numarası giriniz.";
      isValid = false;
    } else {
      if (idError.length !== 0) {
        isValid = false;
      }
    }
    if (this.state.transactionType === 0) {
      if (!this.state.policyNo) {
        policyNoError = "sale.policy_no_warn_message";
        isValid = false;
      } else {
        if (policyNoError.length !== 0) {
          isValid = false;
        }
      }
    } else {
      if (!this.state.acreage) {
        acreageError = "sale.acreage_warn_message";
        isValid = false;
      } else {
        if (acreageError.length !== 0) {
          isValid = false;
        }
      }
    }
    this.setState({
      showIdError: true,
      idError: idError,
      policyNoError: policyNoError,
      acreageError: acreageError,
    });
    return isValid;
  };

  quickOffer = () => {
    const preview = this.context.preview;
    if (!this.handleValidation() || preview) {
      return;
    }
    const dapAgency = this.context.dapAgency;
    let formInputs = `&ac=${dapAgency.id}&product=${PACKET_TYPE}&id=${this.state.id}&transactionType=${this.state.transactionType}&policyNo=${this.state.policyNo}&acreage=${this.state.acreage}`;
    let encrpytedFormInputs = encryptForm(formInputs, this.context.akyAgency);
    ApiService.saveToRedis(encrpytedFormInputs).subscribe((resp) => {
      window.location.href = `${REACT_APP_OFFER_REDIRECTION_URL}?${resp}`;
    });
  };

  render() {
    const t = this.props.t;
    return (
      <div className="saleContainer">
        <div
          className="navigateIconContainer"
          id="productFormArrowLeftkonut"
          onClick={() => handleFormLeftArrow("konut")}
        >
          <AS.NavigateBeforeIcon
            className="navigateIcon contentArrowIcon"
            id="formLeftArrowIconkonut"
          />
        </div>
        <div className="saleMainFormInputContainer">
          <div
            className="saleFormContainer"
            onScroll={(e) =>
              handleArrowVisibility(e.target.scrollLeft, "konut")
            }
          >
            <div className="saleInputContainer">
              <div className="saleLabel">{t("sale.transaction_type")}</div>
              <CustomSelect
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.transactionType}
                onChange={this.handleSelectChange}
                className="saleSelect"
              >
                {selectItems.map((item) => {
                  return (
                    <AS.MenuItem value={item.id} className={styles.menuItem}>
                      {item.name}
                    </AS.MenuItem>
                  );
                })}
              </CustomSelect>
            </div>
            <div className="saleInputContainer">
              <div className="saleLabel">{t("sale.id")}</div>
              <Tckn
                className={styles.textField}
                label=""
                value={this.state.id}
                onValidationStateChanged={this.idValidationChange}
                onChangeText={this.handleIdChange}
                warnMessage={undefined}
              />
              {this.state.showIdError && (
                <AS.FormHelperText
                  id="helper-text-id"
                  error={this.state.idError.length !== 0}
                >
                  {this.state.idError}
                </AS.FormHelperText>
              )}
            </div>
            {this.state.transactionType === 0 ? (
              <div className="saleInputContainer">
                <div className="saleLabel">{t("sale.policy_no")}</div>
                <TextField
                  className={styles.textField}
                  value={this.state.policyNo}
                  onChange={this.handlePolicyNoChange}
                  inputProps={{ minLength: 1 }}
                  error={this.state.policyNoError.length !== 0}
                  helperText={t(this.state.policyNoError)}
                />
              </div>
            ) : (
              <div className="saleInputContainer">
                <div className="saleLabel">{t("sale.acreage")}</div>
                <TextField
                  className={styles.textField}
                  value={this.state.acreage}
                  onChange={this.handleAcreageChange}
                  inputProps={{ minLength: 1, maxLength: 4 }}
                  error={this.state.acreageError.length !== 0}
                  helperText={t(this.state.acreageError)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className="navigateIconContainer"
          id="productFormArrowRightkonut"
          onClick={() => handleFormRightArrow("konut")}
        >
          <AS.NavigateNextIcon
            className="navigateIcon contentArrowIcon"
            id="formRightArrowIconkonut"
          />
        </div>
        <div className="saleButtonContainer">
          <AS.Button
            className="proposalButton"
            onClick={() => this.quickOffer()}
          >
            {t("sale.show_proposals")}
          </AS.Button>
        </div>
        <MessageDialogHelper config={this.state.model.messageDialogConfig} />
      </div>
    );
  }
}

export default withTranslation()(HousingFormHelper);
