import lodash from "lodash";
import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";
import AS from "../../../lib/ASLibrary";
import aslogo from "../../assets/images/anadolusigorta.png";
import bg from "../../assets/images/sale-redirection.jpg";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import MessageDialogHelper from "../../helpers/dialogs/message/message";
import { decryptForm } from "../../helpers/forms/sale/saleformredirection";
import { Validation } from "../../helpers/validations/validation";
import { ApiService } from "../../services/ApiService";
import { AuthorizationService } from "../../services/authorization";
import { MerchantOfferService } from "../../services/MerchantOfferService";
import { OfferService } from "../../services/OfferService";
import { SALEREDIRECTION_DEFAULT } from "./model";
import styles from "./saleredirection.module.scss";

const { REACT_APP_DAP_ENV, REACT_APP_BIREYSEL_URL, REACT_APP_BIREYSEL_URL_MOBILE } = process.env;

const PACKET_TYPE_COMPLEMENTARY_HEALTH = "tss";
const PACKET_TYPE_CYBER_RISK = "cyberrisk";
const PACKET_TYPE_DASK = "dask";
const PACKET_TYPE_FLY = "fly";
const PACKET_TYPE_GREEN_CARD = "greencard";
const PACKET_TYPE_HEALTH = "health";
const PACKET_TYPE_HOUSING = "housing";
const PACKET_TYPE_INSURANCE = "casco";
const PACKET_TYPE_MYS = "mys";
const PACKET_TYPE_SMILE_PACK = "smilepack";
const PACKET_TYPE_TRAFIC = "traffic";
const PACKET_TYPE_TRAVEL = "travel";
const CHANNEL_NAME = "DAP";

const mobileAndTabletCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|iPhone|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const mediaWidthCheck = () => {
  let check = false;
  if (window.matchMedia("(max-width: 1200px)").matches) {
    check = true;
  }
  return check;
};

const isMobileOrTablet = mobileAndTabletCheck() || mediaWidthCheck();

class SaleRedirection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      model: lodash.cloneDeep(SALEREDIRECTION_DEFAULT),
      reCaptcha: "",
      reCaptchaError: "",
      loading: false,
      urlParams: undefined,
      showCaptcha: true
    };
  }

  componentDidMount() {
    this.setState(
      {
        model: this.setDefaults(),
      },
      () => {
        if (window.location.href.includes("?")) {
          let locationInfoList = window.location.href.split("?");
          ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
            let urlParams = this.getUrlParams(locationInfoList, resp);
            if (urlParams.ac && urlParams.ac !== "undefined") {
              this.setState({ urlParams: urlParams }, () => {
                ApiService.changeAgent(urlParams.ac).subscribe((res) => {
                  this.quickOfferByByProduct(res, urlParams.ac);
                });
                if (REACT_APP_DAP_ENV !== "production") {
                  ApiService.getCaptchaConfig().subscribe(resp => {
                    this.setState({
                      showCaptcha: resp == '1'
                    })
                  });
                }
              });
            }
          });
        }
      }
    );
  }

  setDefaults = () => {
    let model = lodash.cloneDeep(SALEREDIRECTION_DEFAULT);
    model.messageDialogConfig.functions.closeDialog = this.closeMessageDialog;
    return model;
  };

  static contextType = AuthorizationContext;

  closeMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = false;
      return { datasource };
    });
  };

  openMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.messageDialogConfig.datasource.dialogShow = true;
      return { datasource };
    });
  };

  quickOfferByByProduct = (resp, domain) => {
    const updateAkyAgency = this.context.updateAkyAgency;
    const updateDapAgency = this.context.updateDapAgency;
    const updateLoading = this.context.updateLoading;
    AuthorizationService.setToken(resp);
    ApiService.channelEcommerceInformation().subscribe((akyAgency) => {
      ApiService.getDapAgent("general", domain).subscribe((dapAgency) => {
        updateAkyAgency(akyAgency);
        updateDapAgency(dapAgency);
        updateLoading(false);
      });
    });
  };

  handleQuickOffer = () => {
    if ((REACT_APP_DAP_ENV === "production" || this.state.showCaptcha) && !this.state.reCaptcha) {
      this.setState({
        reCaptchaError: this.props.t("sale.recaptcha_error"),
      });
      return;
    } else if ((REACT_APP_DAP_ENV === "production" || this.state.showCaptcha) && this.state.reCaptcha) {
      ApiService.verifyReCaptcha(this.state.reCaptcha).subscribe((resp) => {
        if (resp) {
          this.handleQuickOfferByProductType();
        }
      });
    } else {
      this.handleQuickOfferByProductType();
    }
  };

  handleQuickOfferByProductType = () => {
    let urlParams = { ...this.state.urlParams };
    if (urlParams.product) {
      this.setState(
        {
          loading: true,
        },
        () => {
          switch (urlParams.product) {
            case "tss":
              this.quickOfferComplementaryHealth(urlParams);
              break;
            case "cyberrisk":
              this.quickOfferCyberRisk(urlParams);
              break;
            case "dask":
              if (Validation.validateTckn(urlParams.id)) {
                this.quickOfferDask(urlParams);
              } else if (Validation.validateVkn(urlParams.id)) {
                this.quickOfferMerchantDask(urlParams);
              }
              break;
            case "fly":
              this.quickOfferFly(urlParams);
              break;
            case "greencard":
              this.quickOfferGreenCard(urlParams);
              break;
            case "health":
              this.quickOfferHealth(urlParams);
              break;
            case "konut":
              this.quickOfferHousing(urlParams);
              break;
            case "casco":
              if (Validation.validateTckn(urlParams.id)) {
                this.quickOfferInsurance(urlParams);
              } else if (Validation.validateVkn(urlParams.id)) {
                this.quickOfferMerchantInsurance(urlParams);
              }
              break;
            case "mys":
              this.quickOfferMys(urlParams);
              break;
            case "gulumseten":
              this.quickOfferSmilePack(urlParams);
              break;
            case "traffic":
              if (Validation.validateTckn(urlParams.id)) {
                this.quickOfferTrafic(urlParams);
              } else if (Validation.validateVkn(urlParams.id)) {
                this.quickOfferMerchantTrafic(urlParams);
              }
              break;
            case "travel":
              this.quickOfferTravel(urlParams);
              break;

            default:
              break;
          }
        }
      );
    }
  };

  quickOfferComplementaryHealth = (urlParams) => {
    const form = this.handleComplementaryHealthFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferCyberRisk = (urlParams) => {
    const form = this.handleCyberRiskFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferDask = (urlParams) => {
    const form = this.handleDaskFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form,
      btoa(urlParams.ac),
      urlParams.callBackUrl
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferMerchantDask = (urlParams) => {
    let form = this.handleMerchantDaskFormData(urlParams);
    MerchantOfferService.verifyQuickOfferUser(
      form,
      btoa(urlParams.ac),
      urlParams.callBackUrl
    )
      .subscribe(
        (resp) => {
          if (resp.message && resp.status && !resp.status.includes("error")) {
            window.location.href = resp.message;
          } else if (resp.status && resp.status.includes("error")) {
            this.quickOfferMessageHandler(resp.message, form);
          } else if (resp.status === "failed" || resp.status === "failure") {
            this.quickOfferMessageHandler(resp.message, form);
          } else if (resp.status === "ajaxError") {
            this.quickOfferMessageHandler(
              this.props.t("sale.ajax_error"),
              form
            );
          } else if (
            typeof resp === "string" &&
            resp.includes("<!DOCTYPE html>")
          ) {
            document.write(resp);
          }
        },
        (err) => {
          this.ajaxErrorHandler(err.status, form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferFly = (urlParams) => {
    const form = this.handleFlyFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferGreenCard = (urlParams) => {
    const form = this.handleGreenCardFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferHealth = (urlParams) => {
    const form = this.handleHealthFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferHousing = (urlParams) => {
    const form = this.handleHousingFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferInsurance = (urlParams) => {
    const form = this.handleInsuranceFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferMerchantInsurance = (urlParams) => {
    let form = this.handleMerchantInsuranceFormData(urlParams);
    MerchantOfferService.verifyQuickOfferUser(
      form,
      btoa(urlParams.ac),
      urlParams.callBackUrl
    )
      .subscribe(
        (resp) => {
          if (resp.message && resp.status && !resp.status.includes("error")) {
            window.location.href = resp.message;
          } else if (resp.status && resp.status.includes("error")) {
            this.quickOfferMessageHandler(resp.message, form);
          } else if (resp.status === "failed" || resp.status === "failure") {
            this.quickOfferMessageHandler(resp.message, form);
          } else if (resp.status === "ajaxError") {
            this.quickOfferMessageHandler(
              this.props.t("sale.ajax_error"),
              form
            );
          } else if (
            typeof resp === "string" &&
            resp.includes("<!DOCTYPE html>")
          ) {
            document.write(resp);
          }
        },
        (err) => {
          this.ajaxErrorHandler(err.status, form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferMys = (urlParams) => {
    const form = this.handleMysFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferSmilePack = (urlParams) => {
    const form = this.handleSmilePackFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferTrafic = (urlParams) => {
    const form = this.handleTraficFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferMerchantTrafic = (urlParams) => {
    let form = this.handleMerchantTraficFormData(urlParams);
    MerchantOfferService.verifyQuickOfferUser(
      form,
      btoa(urlParams.ac),
      urlParams.callBackUrl
    )
      .subscribe(
        (resp) => {
          if (resp.message && resp.status && !resp.status.includes("error")) {
            window.location.href = resp.message;
          } else if (resp.status && resp.status.includes("error")) {
            this.quickOfferMessageHandler(resp.message, form);
          } else if (resp.status === "failed" || resp.status === "failure") {
            this.quickOfferMessageHandler(resp.message, form);
          } else if (resp.status === "ajaxError") {
            this.quickOfferMessageHandler(
              this.props.t("sale.ajax_error"),
              form
            );
          } else if (
            typeof resp === "string" &&
            resp.includes("<!DOCTYPE html>")
          ) {
            document.write(resp);
          }
        },
        (err) => {
          this.ajaxErrorHandler(err.status, form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  quickOfferTravel = (urlParams) => {
    const form = this.handleTravelFormData(urlParams);
    OfferService.verifyQuickOfferUser(
      form
    )
      .subscribe(
        (resp) => {
          this.handleRedirectionToIndividualBranch(resp);
        },
        (err) => {
          this.errorHandler(form);
        }
      )
      .add(() => {
        this.notShowLoading();
        this.deleteFromRedis();
      });
  };

  handleRedirection = (redirect, params) => {
    if (redirect.includes("?")) {
      window.location.href = `${REACT_APP_BIREYSEL_URL}${redirect}&${params}`;
    } else {
      window.location.href = `${REACT_APP_BIREYSEL_URL}${redirect}?${params}`;
    }
  };

  handleRedirectionToIndividualBranch = (resp) => {
    const bireyselUrl = isMobileOrTablet ? REACT_APP_BIREYSEL_URL_MOBILE : REACT_APP_BIREYSEL_URL;
    if (resp.cacheId) {
      window.location.href = `${bireyselUrl}?flow=${resp.cacheId}`;
    }
  };

  quickOfferMessageHandler = (message, info) => {
    this.setState(
      (prevState) => {
        let datasource = { ...prevState };
        datasource.model.messageDialogConfig.datasource.message = message;
        return { datasource };
      },
      () => {
        this.openMessageDialog();
        info.message = message;
        ApiService.proposalErrorLog(JSON.stringify(info)).subscribe();
      }
    );
  };

  ajaxErrorHandler = (status, form) => {
    if (status === "ajaxError") {
      this.quickOfferMessageHandler(this.props.t("sale.ajax_error"), form);
    }
  };

  errorHandler = (form) => {
    this.quickOfferMessageHandler(this.props.t("sale.ajax_error"), form);
  };

  handleComplementaryHealthFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_COMPLEMENTARY_HEALTH;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleCyberRiskFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_CYBER_RISK;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleDaskFormData = (urlParams) => {
    const formData = {};
    formData.capchha = this.state.reCaptcha;
    formData.channelName = CHANNEL_NAME;
    formData.operationType = urlParams.transactionType;
    formData.identifier = urlParams.id;
    formData.agencyCode = urlParams.ac;
    formData.policyNumber =
    !urlParams.policyNo || urlParams.policyNo === "undefined"
      ? ""
      : urlParams.policyNo;
      formData.storeyArea =
      !urlParams.acreage || urlParams.acreage === "undefined"
        ? ""
        : urlParams.acreage;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    formData.packetType = PACKET_TYPE_DASK;
    return formData;
  };

  handleMerchantDaskFormData = (urlParams) => {
    const formData = {};
    formData.capcha = this.state.reCaptcha;
    formData.channelName = CHANNEL_NAME;
    formData.packetType = PACKET_TYPE_DASK;
    formData.renewal = urlParams.transactionType == 0;
    formData.vkn = urlParams.id;
    formData.policyNumber =
      !urlParams.policyNo || urlParams.policyNo === "undefined"
        ? ""
        : urlParams.policyNo;
    formData.storeyArea =
      !urlParams.acreage || urlParams.acreage === "undefined"
        ? ""
        : urlParams.acreage;
    return formData;
  };

  handleFlyFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_FLY;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleGreenCardFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_GREEN_CARD;
    formData.identifier = urlParams.id;
    formData.licenceNumber = urlParams.plateText;
    formData.licenceCode = urlParams.plateCode;
    formData.travelStartDate = urlParams.startDate;
    formData.travelEndDate = urlParams.endDate;
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleHealthFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_HEALTH;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleHousingFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_HOUSING;
    formData.identifier = urlParams.id;
    formData.operationType = urlParams.transactionType;
    formData.policyNumber =
    !urlParams.policyNo || urlParams.policyNo === "undefined"
      ? ""
      : urlParams.policyNo;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleInsuranceFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_INSURANCE;
    formData.identifier = urlParams.id;
    formData.licenceNumber = urlParams.plateText;
    formData.licenceCode = urlParams.plateCode;
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.operationType = urlParams.transactionType;
    formData.registrySerialNumber = urlParams.registrationNo;
    formData.registrySerialCode = urlParams.registrationCode;
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleMerchantInsuranceFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_INSURANCE;
    formData.vkn = urlParams.id;
    formData.licenceCode = urlParams.plateCode;
    formData.licenceNumber = urlParams.plateText;
    formData.trafficPolicyType = urlParams.transactionType;
    formData.capcha = this.state.reCaptcha;
    formData.channelName = CHANNEL_NAME;
    return formData;
  };

  handleMysFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_MYS;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleSmilePackFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_SMILE_PACK;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  handleTraficFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_TRAFIC;
    formData.identifier = urlParams.id;
    formData.licenceNumber = urlParams.plateText;
    formData.licenceCode = urlParams.plateCode;
    formData.travelStartDate = "";
    formData.travelEndDate = "";
    formData.travelDestination = "";
    formData.operationType = urlParams.transactionType;
    formData.registrySerialNumber = urlParams.registrationNo;
    formData.registrySerialCode = urlParams.registrationCode;
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };


  handleMerchantTraficFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_TRAFIC;
    formData.vkn = urlParams.id;
    formData.licenceCode = urlParams.plateCode;
    formData.licenceNumber = urlParams.plateText;
    formData.trafficPolicyType = urlParams.transactionType;
    if (urlParams.transactionType == "1") {
      formData.registrySerialCode = urlParams.registrationCode;
      formData.registrySerialNumber = urlParams.registrationNo;
    }
    formData.capcha = this.state.reCaptcha;
    formData.channelName = CHANNEL_NAME;
    return formData;
  };

  handleTravelFormData = (urlParams) => {
    const formData = {};
    formData.packetType = PACKET_TYPE_TRAVEL;
    formData.identifier = urlParams.id;
    formData.licenceNumber = "";
    formData.licenceCode = "";
    formData.travelStartDate = urlParams.startDate;
    formData.travelEndDate = urlParams.endDate;
    formData.travelDestination = "";
    formData.registrySerialNumber = "";
    formData.registrySerialCode = "";
    formData.enableCampaign = "";
    formData.storeyArea =
    !urlParams.acreage || urlParams.acreage === "undefined"
      ? ""
      : urlParams.acreage;
    formData.asistans = true;
    formData.channelName = CHANNEL_NAME;
    formData.agencyCode = urlParams.ac;
    formData.callBackUrl = urlParams.callBackUrl;
    return formData;
  };

  getUrlParams = (locationInfoList, form) => {
    let urlParams;
    let decryptedFormInputs = decryptForm(form);
    let decryptedFullLocation = `${locationInfoList[0]}?${decryptedFormInputs}`;
    let url = new URL(
      decodeURI(decryptedFullLocation.replace(/\+/g, "%20"))
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
    );

    let match,
      pl = /\+/g,
      search = /([^&=]+)=?([^&]*)/g,
      decode = (s) => decodeURIComponent(s.replace(pl, " ")),
      query = url.href;
    urlParams = {};
    while ((match = search.exec(query))) {
      urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
  };

  reCaptchaChange = (value) => {
    this.setState({
      reCaptcha: value,
      reCaptchaError: "",
    });
  };

  notShowLoading = () => {
    this.setState({
      loading: false,
    });
  };

  deleteFromRedis = () => {
    let locationInfoList = window.location.href.split("?");
    ApiService.deleteFromRedis(locationInfoList[1]).subscribe();
  };

  render() {
    const t = this.props.t;
    return (
      <div
        className={styles.container}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className={styles.secondContainer}>
          <div className={styles.logoContainer}>
            <div
              className={styles.logo}
              style={{ backgroundImage: `url(${aslogo})` }}
            />
          </div>
          <div className={styles.descriptionContainer}>
            {t("sale.recaptcha")}
          </div>
          {(REACT_APP_DAP_ENV === "production" || this.state.showCaptcha) && (
            <div className={styles.saleReCaptchaContainer}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={this.reCaptchaChange}
              />
              <AS.FormHelperText
                id="helper-text-sale-redirection"
                error={this.state.reCaptchaError.length !== 0}
                style={{ color: "red" }}
              >
                {t(this.state.reCaptchaError)}
              </AS.FormHelperText>
            </div>
                   )}
          <div className={styles.kvkkLinkContainer}>
            <a
              href="https://www.anadolusigorta.com.tr/tr/yasal-bilgilendirme/kvkk"
              className={styles.kvkkLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("sale.kvkk")}
            </a>
          </div>
          <div className={styles.operationsContainer}>
            {this.state.loading ? (
              <div className={styles.contentContainer}>
                <div className={styles.loader}></div>
                <div className={styles.description}>{t("sale.redirect")}</div>
              </div>
            ) : (
              <AS.Button
                className={styles.backButton}
                onClick={this.handleQuickOffer}
              >
                {t("sale.show_offer")}
              </AS.Button>
            )}
          </div>
        </div>

        <MessageDialogHelper config={this.state.model.messageDialogConfig} />
      </div>
    );
  }
}

export default withTranslation()(SaleRedirection);
