import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styles from "./parentproductcards.module.scss";
import AS from "../../../../lib/ASLibrary";
import { useTranslation } from "react-i18next";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const ParentProductsCardHelper = (props) => {
  let { url } = useRouteMatch();

  const { t } = useTranslation();

  const [active, setActive] = useState(-1);

  const handleOtherCard = () => {
    let card = active === "other" ? -1 : "other";
    setActive(card);
  };

  const resetActiveCard = () => {
    setActive(-1);
  };

  const handleLink = (name) => {
    return name.toLowerCase()
        .replace(/[()-/,]/g, '')
        .replace(/\s+/g, '-')
        .replace(/ö/g, 'o')
        .replace(/ç/g, 'c')
        .replace(/ş/g, 's')
        .replace(/ı/g, 'i')
        .replace(/ğ/g, 'g')
        .replace(/ü/g, 'u');
  }

  const handleOtherLink = (link) => {
    return link.split('/');
  }

  return (
    <div className={styles.container}>
      {!window.location.href.includes("?") && props.config.datasource.parentProducts.map((item) => {
        return (
          <Link 
          to={{
            pathname: `${url}/${handleLink(item.name)}${props.params}`,
            state: { id: item.id, otherProducts: false }
          }}
          className={styles.link}>
            <div className={styles.productContainer}>
              <div
                style={{
                  backgroundImage: `url(${BASE_URL}${item.photoUrl})`,
                }}
                className={styles.productImgContainer}
              />
              <div className={styles.productNameContainer}>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.infoContainer}>
                  {t("home.product_navigation")}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
      {window.location.href.includes("?") && props.config.datasource.parentProducts.map((item) => {
        return (
          <Link to={`${url}/${item.id}${props.params}`} className={styles.link}>
            <div className={styles.productContainer}>
              <div
                style={{
                  backgroundImage: `url(${BASE_URL}${item.photoUrl})`,
                }}
                className={styles.productImgContainer}
              />
              <div className={styles.productNameContainer}>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.infoContainer}>
                  {t("home.product_navigation")}
                </div>
              </div>
            </div>
          </Link>
        );
      })}
      <div
        className={styles.otherContainer}
        aria-expanded={active === "other"}
        onClick={() => handleOtherCard()}
      >
        <div className={styles.otherMainContainer}>
          <div className={styles.otherLogoContainer}>
            <AS.VerifiedUserOutlinedIcon className={styles.otherLogo} />
          </div>
          <div className={styles.otherNameContainer}>{t("parent_products.other")}</div>
        </div>
        <div className={styles.hoverContent}>
          <div
            className={styles.backCardTitleContainer}
            onClick={resetActiveCard}
          >
            <AS.KeyboardBackspaceIcon className={styles.backCardIcon} />
            <p>{t("parent_products.other")}</p>
          </div>
          <div className={styles.otherProductsNameContainer}>
            {!window.location.href.includes("?") && props.config.datasource.otherProducts.map((item) => {
              return (
                <Link
                  to={{
                    pathname: `${url}/${handleOtherLink(item.otherProductPath)[0]}/${handleOtherLink(item.otherProductPath)[1]}${props.params}`,
                    state: { id: item.id, otherProducts: true }
                  }}
                  className={styles.otherLink}
                >
                  <div className={styles.otherAccordionNameContainer}>
                    {item.name}
                  </div>
                </Link>
              );
            })}
            {window.location.href.includes("?") && props.config.datasource.otherProducts.map((item) => {
              return (
                <Link
                  to={`${url}/${item.id}${props.params}`}
                  className={styles.otherLink}
                >
                  <div className={styles.otherAccordionNameContainer}>
                    {item.name}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentProductsCardHelper;
