import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationTR from "../src/locales/tr.locale.json";
import translationEN from "../src/locales/en.locale.json";

const resources = {
  TR: {
    translation: translationTR,
  },
  EN: {
    translation: translationEN,
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "TR",
  fallbackLng: "TR",
  keySeparator: ".",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});
