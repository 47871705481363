export const handleProductMetaTitle = (akyAgency, url) => {
  let title = "Tüm Ürünler";
  if (akyAgency) {
    let productRoute = "/urunler" + url.split("/urunler")[1];
    switch (productRoute) {
      case "/urunler/arac-sigortasi/trafik-sigortasi":
        title = `Zorunlu Trafik Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/tam-bakim-trafik-sigortasi":
        title = `Tam Bakım Trafik Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/kasko-sigortasi":
        title = `Kasko Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/hesapli-kasko-sigortasi":
        title = `Hesaplı Kasko Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/plaza-kasko-sigortasi":
        title = `Plaza Kasko Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/pert-kasko-sigortasi":
        title = `Pert Kasko Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/volkswagen-kasko-sigortasi":
        title = `Volkswagen Kasko Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/arac-sigortasi/diger-arac-sigortalari":
        title = `Diğer Araç Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/ozel-saglik-sigortasi/tamamlayici-saglik-sigortasi":
        title = `Tamamlayıcı Sağlık Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/ozel-saglik-sigortasi/bireysel-saglik-sigortasi":
        title = `Bireysel Sağlık Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/ozel-saglik-sigortasi/nefes-saglik-sigortasi":
        title = `Nefes Sağlık Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/ozel-saglik-sigortasi/diger-saglik-sigortalari":
        title = `Diğer Sağlık Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/konut-sigortasi/konut-paket-sigortasi":
        title = `Konut Paket Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/konut-sigortasi/dask":
        title = `DASK - Deprem Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/seyahat-ve-ferdi-kaza/yurt-disi-seyahat-sigortasi":
        title = `Yurt Dışı Seyahat Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/seyahat-ve-ferdi-kaza/ferdi-kaza-sigortasi":
        title = `Ferdi Kaza Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/seyahat-ve-ferdi-kaza/guvenli-gelecek-sigortasi":
        title = `Güvenli Gelecek Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/is-yeri-sigortalari/is-yeri-paket-sigortasi":
        title = `İş Yeri Paket Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/is-yeri-sigortalari/kobi-paket-sigortasi":
        title = `KOBİ Paket Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/tekne-ve-nakliyat-sigortalari/nakliyat-sigortasi":
        title = `Nakliyat Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/tekne-ve-nakliyat-sigortalari/tekne-sigortasi":
        title = `Tekne Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/tekne-ve-nakliyat-sigortalari/diger-tekne-ve-nakliyat-sigortalari":
        title = `Diğer Tekne ve Nakliyat Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/muhendislik-sigortalari/insaat-all-risks-sigortasi":
        title = `İnşaat All Risks Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/muhendislik-sigortalari/montaj-all-risks-sigortasi":
        title = `Montaj All Risks Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/muhendislik-sigortalari/makine-kirilmasi-sigortasi":
        title = `Makine Kırılması Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/muhendislik-sigortalari/elektronik-cihaz-sigortasi":
        title = `Elektronik Cihaz Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/bireysel-siber-guvenlik-sigortasi":
        title = `Bireysel Siber Güvenlik Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/anadolu-sigorta-ticari-siber-guvenlik-paket-policesi":
        title = `Ticari Siber Güvenlik Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/yurtici-kredi-sigortasi":
        title = `Yurtiçi Kredi Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/i̇hracat-kredi-sigortasi":
        title = `İhracat Kredi Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/kefalet-sigortasi":
        title = `Kefalet Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/i̇sveren-sorumluluk-sigortasi":
        title = `İşveren Sorumluluk Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/ucuncu-kisilere-karsi-sorumluluk-sigortasi":
        title = `Üçüncü Kişilere Karşı Sorumluluk Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/i̇sletme-ve-urun-sorumluluk-sigortasi":
        title = `İşletme ve Ürün Sorumluluk Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/asansor-kazalarinda-ucuncu-kisilere-karsi-sorumluluk-sigortasi":
        title = `Asansör Kazalarında Sorumluluk Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/hava-tasitlari-tekne-govde-sigortalari":
        title = `Hava Taşıtları Gövde Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/hava-tasitlari-ucuncu-kisilere-karsi-sorumluluk-sigortalari":
        title = `Hava Taşıtları Sorumluluk Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/yolcu-sorumluluk-sigortalari":
        title = `Yolcu Sorumluluk Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/havalimani-i̇sleticisi-sorumluluk-sigortalari":
        title = `Havalimanı Sorumluluk Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/yolcu-ve-ucus-ekibi-koltuk-ferdi-kaza-sigortasi":
        title = `Yolcu ve Uçuş Ekibi Ferdi Kaza Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/cesitli/devlet-destekli-tarim-sigortalari":
        title = `Devlet Destekli Tarım Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/cesitli/hukuksal-koruma-sigortalari":
        title = `Hukuksal Koruma Sigortaları | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;
      case "/urunler/diger-urunler/cesitli/mehmetcik-yasam-sigortasi":
        title = `Mehmetçik Yaşam Sigortası | ${akyAgency.agencyTitle} Anadolu Sigorta`;
        break;

      default:
        break;
    }
  }
  return title;
};

export const handleProductMetaDesc = (akyAgency, url) => {
  let desc = "Tüm Ürünler";
  if (akyAgency) {
    let productRoute = "/urunler" + url.split("/urunler")[1];
    switch (productRoute) {
      case "/urunler/arac-sigortasi/trafik-sigortasi":
        desc = `Trafiğe çıkan her araç için zorunlu olan trafik sigortası poliçemiz hakkında detaylı bilgi edinmek ve size özel teklif almak için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/arac-sigortasi/tam-bakim-trafik-sigortasi":
        desc = `Zorunlu trafik sigortasına ek olarak sunulan ve aracınızda oluşabilecek hasarları ücretsiz giderebilen Tam Bakım Trafik Sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/arac-sigortasi/kasko-sigortasi":
        desc = `Aracınızda oluşan hasarlarda, hırsızlık veya kaza durumlarında hasara yönelik tazminat sunan Kasko Sigortası poliçesi hakkında bilgi almak için tıklayın.`;
        break;
      case "/urunler/arac-sigortasi/hesapli-kasko-sigortasi":
        desc = `Genişletilmiş kasko sigortası altında ihtiyacınız olan sigorta teminatlarını birlikte sunan Hesaplı Kasko Sigortası teklifi almak için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/arac-sigortasi/plaza-kasko-sigortasi":
        desc = `Tek bir poliçe olarak aracınız için ihtiyacınız olan tüm poliçeleri sunan Plaza Kasko Sigortası hakkında bilgi ve teklif almak için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/arac-sigortasi/pert-kasko-sigortasi":
        desc = `Aracınızın tam hasara uğraması ya da çalınması durumuna karşı teminat sunan Pert Kasko Sigortası hakkında detaylı bilgi edinmek ve teklif almak için tıklayın.`;
        break;
      case "/urunler/arac-sigortasi/volkswagen-kasko-sigortasi":
        desc = `Tek bir poliçe altında aracınız için ihtiyaç duyduğunuz tüm teminatları sunan Volkswagen Kasko Sigortası hakkında bilgi edinmek ve teklif almak için tıklayın.`;
        break;
      case "/urunler/arac-sigortasi/diger-arac-sigortalari":
        desc = `Anadolu Sigorta tarafından sağlanan diğer araç sigortaları ve teminatlar hakkında detaylı bilgi edinmek ve size özel fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/ozel-saglik-sigortasi/tamamlayici-saglik-sigortasi":
        desc = `Anlaşmalı özel hastanelerde muayene, tetkik ve tedavi için fark ücreti ödemeyin. Size özel Tamamlayıcı Sağlık Sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/ozel-saglik-sigortasi/bireysel-saglik-sigortasi":
        desc = `Dilediğiniz doktor ve sağlık kurumunu seçme özgürlüğü sunan Bireysel Sağlık Sigortası hakkında detaylı bilgi ve fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/ozel-saglik-sigortasi/nefes-saglik-sigortasi":
        desc = `Ani ve beklenmedik acil durumlara karşı güvence sağlayan Nefes Sağlık Sigortası hakkında detaylı bilgi ve size özel fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/ozel-saglik-sigortasi/diger-saglik-sigortalari":
        desc = `Anadolu Sigorta tarafından sunulan diğer sağlık sigortaları ve teminatları hakkında detaylı bilgi edinmek ve size özel fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/konut-sigortasi/konut-paket-sigortasi":
        desc = `Evinizi ve içindeki eşyalarınızı hırsızlık, deprem ve baskın gibi risklere karşı koruma altına alan Konut Paket Sigortası teklifi almak için hemen tıklayın.`;
        break;
      case "/urunler/konut-sigortasi/dask":
        desc = `DASK tarafından sunulan Zorunlu Deprem Sigortası ile deprem sonucu oluşabilecek maddi zararlar karşılanabilir. Size özel teklif almak için hemen tıklayın.`;
        break;
      case "/urunler/seyahat-ve-ferdi-kaza/yurt-disi-seyahat-sigortasi":
        desc = `Vize başvuruları sırasında zorunlu talep edilen Yurt Dışı Seyahat Sigortasını Anadolu Sigorta ayrıcalıklarıyla edinmek için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/seyahat-ve-ferdi-kaza/ferdi-kaza-sigortasi":
        desc = `Başınıza gelebilecek ani ve harici durumlarda uğrayabileceğiniz bedensel zararlara karşı teminat sunan Ferdi Kaza Sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/seyahat-ve-ferdi-kaza/guvenli-gelecek-sigortasi":
        desc = `Çocukların eğitiminin devamlılığı için güvence sağlayan Güvenli Gelecek Sigortasını Anadolu Sigorta ayrıcalıklarıyla edinmek için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/is-yeri-sigortalari/is-yeri-paket-sigortasi":
        desc = `Siz iş yerinizde güvenle ve huzurla çalışırken, iş yerinizi ve diğer tüm muhteviyatınızı koruyan İş Yeri Paket Sigortası fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/is-yeri-sigortalari/kobi-paket-sigortasi":
        desc = `İşletmenizin ihtiyaçlarına özel hazırlanan, son derece geniş teminatlar sunan KOBİ Paket Sigortası hakkında detaylı bilgi almak için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/tekne-ve-nakliyat-sigortalari/nakliyat-sigortasi":
        desc = `Mallarınızın bir taşıma aracıyla taşınması sırasında görebileceği fiziki zararlara karşı teminat sunan Nakliyat Sigortası hakkında bilgi almak için tıklayın.`;
        break;
      case "/urunler/tekne-ve-nakliyat-sigortalari/tekne-sigortasi":
        desc = `Teknenizi, makinesini, botlarını ve diğer donanımlarını güvence altına alan Gezinti Teknesi Sigortası hakkında detaylı bilgi için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/tekne-ve-nakliyat-sigortalari/diger-tekne-ve-nakliyat-sigortalari":
        desc = `Anadolu Sigorta ayrıcalıkları ile sunulan diğer Tekne ve Nakliyat Sigortaları hakkında bilgi ve size özel fiyat teklifi almak için sayfamızı ziyaret edin.`;
        break;
      case "/urunler/muhendislik-sigortalari/insaat-all-risks-sigortasi":
        desc = `İnşaat faaliyeti sürecinde ani ve beklenmedik sebeplerle ortaya çıkabilen risklere karşı güvence sunan İnşaat All Risks sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/muhendislik-sigortalari/montaj-all-risks-sigortasi":
        desc = `Montaj faaliyeti sürecinde ani ve beklenmedik sebeplerle ortaya çıkacak risklere karşı güvence sunan Montaj All Risks sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/muhendislik-sigortalari/makine-kirilmasi-sigortasi":
        desc = `Makinelerinizi güvence altında alan Makine Kırılması Sigortası'nı Anadolu Sigorta ayrıcalıkları ile edinmek ve size özel fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/muhendislik-sigortalari/elektronik-cihaz-sigortasi":
        desc = `İş yerinizde bulunan elektronik cihazları risklere karşı güvence altına alan Elektronik Cihaz Sigortası hakkında bilgi ve fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/bireysel-siber-guvenlik-sigortasi":
        desc = `Bireysel siber güvenliğinizi ihlal eden durumlar karşısında destek sağlayan Bireysel Siber Güvenlik Sigortası hakkında detaylı bilgi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/anadolu-sigorta-ticari-siber-guvenlik-paket-policesi":
        desc = `Ticari işletmeleri ve KOBİ'leri siber risklere karşı koruyan Ticari Siber Güvenlik Sigortası hakkında bilgi ve size özel fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/yurtici-kredi-sigortasi":
        desc = `Mal mukabili yapılan vadeli satış işlemlerinde satıcının uğrayacağı maddi zararlara teminat sunan Yurtiçi Kredi Sigortası fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/i̇hracat-kredi-sigortasi":
        desc = `İhracatta vadeli satış işlemlerinde satıcının uğrayacağı maddi zararları teminat altına alan İhracat Kredi Sigortası fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/ozel-finansal-riskler/kefalet-sigortasi":
        desc = `Borçlunun yükümlülüğünü yerine getirmemesi riskine karşı kefalet senedi bedeline kadar kefil olunan Kefalet Sigortası hakkında bilgi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/i̇sveren-sorumluluk-sigortasi":
        desc = `İş yerinde meydana gelebilecek iş kazaları sonucu SSK dışındaki tazminatları teminat altına alan İşveren Sorumluluk Sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/ucuncu-kisilere-karsi-sorumluluk-sigortasi":
        desc = `Günlük yaşamınızda üçüncü kişilere vereceğiniz zararlardan doğan sorumlulukları teminat altına alan Sorumluluk Sigortası fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/i̇sletme-ve-urun-sorumluluk-sigortasi":
        desc = `Sigortalı ürünün üçüncü şahıslar tarafından kullanılmasından doğan durumlara karşı sigortalıyı koruyan Sorumluluk Sigortası fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/sorumluluk-sigortalari/asansor-kazalarinda-ucuncu-kisilere-karsi-sorumluluk-sigortasi":
        desc = `Asansörlerde meydana gelebilecek kaza sonucu üçüncü kişilerin tazminat taleplerine karşı teminat sunan Sorumluluk Sigortası teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/hava-tasitlari-tekne-govde-sigortalari":
        desc = `Hava taşıtında herhangi bir nedenle oluşan zararları teminat altına alan Hava Taşıtları Tekne (Gövde) Sigortaları hakkında detaylı bilgi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/hava-tasitlari-ucuncu-kisilere-karsi-sorumluluk-sigortalari":
        desc = `Hava taşıtının işletilmesinde üçüncü kişilere gelen zararlara teminat sunan Hava Taşıtları Üçüncü Kişilere Karşı Sorumluluk Sigortaları hakkında bilgi alın.`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/yolcu-sorumluluk-sigortalari":
        desc = `Uçakta bulunan biletli yolcuların bedensel zararları için sigortalıyı teminat altına alan Yolcu Sorumluluk Sigortaları hakkında bilgi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/havalimani-i̇sleticisi-sorumluluk-sigortalari":
        desc = `Havalimanı işleticilerine, havalimanının işletilmesinden doğan risklere karşı teminat sunan Havalimanı İşleticisi Sorumluluk Sigortaları hakkında bilgi alın.`;
        break;
      case "/urunler/diger-urunler/havacilik-sigortalari/yolcu-ve-ucus-ekibi-koltuk-ferdi-kaza-sigortasi":
        desc = `Yolcular ve uçuş ekibinin bir kaza nedeniyle gördüğü bedensel zararlara teminat sunan Yolcu ve Uçuş Ekibi Ferdi Kaza Sigortası hakkında detaylı bilgi alın.`;
        break;
      case "/urunler/diger-urunler/cesitli/devlet-destekli-tarim-sigortalari":
        desc = `Bitkisel ürün, sera, büyükbaş hayvan, su ürünleri ve arıcılık gibi Devlet Destekli Tarım Sigortaları hakkında bilgi ve fiyat teklifi almak için tıklayın.`;
        break;
      case "/urunler/diger-urunler/cesitli/hukuksal-koruma-sigortalari":
        desc = `Uyuşmazlık durumunda hakkınızı aramak veya savunmak için ihtiyacınız olan tüm yasal masraflara teminat sunan Hukuksal Koruma Sigortaları hakkında bilgi alın.`;
        break;
      case "/urunler/diger-urunler/cesitli/mehmetcik-yasam-sigortasi":
        desc = `TSK Mehmetçik Vakfı ile işbirliğinde sunulan ve vakfın ekonomik desteğine ek olarak tazminat ödemesi yapılan Mehmetçik Yaşam Sigortası hakkında bilgi alın.`;
        break;

      default:
        break;
    }
  }
  return desc;
};
