export const validatePlateText = (e, plate) => {
  let numberCount = 0;
  let letterCount = 0;
  if (
    (e.keyCode > 35 && e.keyCode < 41) ||
    e.key === "Delete" ||
    e.key === "Backspace"
  ) {
    return;
  }
  if (plate) {
    if (plate.length < 6) {
      for (let i = 0; i < plate.length; i++) {
        if (/^\d+$/.test(plate.charAt(i))) {
          numberCount = numberCount + 1;
        }
        if (/[a-zA-Z]/.test(plate.charAt(i))) {
          letterCount = letterCount + 1;
        }
      }

      //IF KEY IS LETTER
      if (/[a-zA-Z]/.test(e.key)) {
        if (letterCount >= 3) {
          e.preventDefault();
        }
      }

      //IF KEY IS NUMBER
      if (
        (e.keyCode > 47 && e.keyCode < 58) ||
        (e.keyCode > 95 && e.keyCode < 106)
      ) {
        if (numberCount >= 5) {
          e.preventDefault();
        }
      }
    } else {
      e.preventDefault();
    }
  } else {
    if (
      (e.keyCode > 47 && e.keyCode < 58) ||
      (e.keyCode > 95 && e.keyCode < 106)
    ) {
      e.preventDefault();
    }
  }
};
