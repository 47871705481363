import _lodash from "lodash";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MetaDecorator from "../../../../util/MetaDecorator";
import { SALE_DEFAULT } from "../model";
import Sale from "../sale";

class SaleMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        helmetConfig: _lodash.cloneDeep(SALE_DEFAULT.helmetConfig),
      },
    };
  }
  render() {
    return (
      <>
        <MetaDecorator
          title={this.state.datasource.helmetConfig.title}
          description={this.state.datasource.helmetConfig.description}
        />
        <Sale />
      </>
    );
  }
}

export default withRouter(SaleMain);
