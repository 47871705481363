import _lodash from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import AS from "../../../lib/ASLibrary";
import MetaDecorator from "../../../util/MetaDecorator";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import CarouselHelper from "../../helpers/carousel/carousel";
import MessageWithCloseBtn from "../../helpers/dialogs/messagewithclosebtn/messagewithclosebtn";
import SaleTabHelper from "../../helpers/tabs/sale-tab/saletab";
import { ApiService } from "../../services/ApiService";
import { SALE_DEFAULT } from "./model";
import styles from "./sale.module.scss";

const bannerSize = Array.from({ length: 4 }, (_, i) => i);

class Sale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        model: _lodash.cloneDeep(SALE_DEFAULT),
      },
      carouselItems: [],
    };
    this.saleProductsRef = React.createRef();
  }

  static contextType = AuthorizationContext;

  componentDidUpdate() {
    const offerButtonScroll = this.context.offerButtonScroll;
    const updateOfferButtonScroll = this.context.updateOfferButtonScroll;
    if (offerButtonScroll === "sale") {
      setTimeout(() => {
        this.saleProductsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 50);
      setTimeout(() => {
        updateOfferButtonScroll("");
      }, 500);
    }
  }

  componentDidMount() {
    const akyAgency = this.context.akyAgency;
    if (window.location.href.includes("?")) {
      if (window.location.href.includes("satis-sonuc")) {
        this.setState(
          (prevState) => {
            let datasource = { ...prevState.datasource };
            datasource = this.setDefaults();
            return { datasource };
          },
          () => {
            return this.handleSaleResultUrl();
          }
        );
      }
      this.handlePreviewBanners();
      this.getSaleableProducts();
    } else {
      if (akyAgency.ecommerceInfo?.salePermissionExist) {
        this.setState(
          (prevState) => {
            let datasource = { ...prevState.datasource };
            datasource = this.setDefaults();
            return { datasource };
          },
          () => {
            this.getSaleableProducts();
            this.getSaleBanners();
          }
        );
      } else {
        this.props.history.push("/");
      }
    }
  }

  closeMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState.datasource };
      datasource.model.messageWithCloseBtnDialogConfig.datasource.dialogShow = false;
      return { datasource };
    });
  };

  handleSaleResultUrl = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let message = urlParams.get("message");
    if (urlParams.get("status") == "success") {
      message = `${urlParams.get(
        "message"
      )}' nolu poliçeniz başarıyla oluşturulmuştur.`;
    }
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.messageWithCloseBtnDialogConfig.datasource.message =
          message;
        datasource.model.messageWithCloseBtnDialogConfig.datasource.dialogShow = true;
        return { datasource };
      },
      () => {
        this.getSaleableProducts();
        this.getSaleBanners();
      }
    );
  };

  getUrlParams = (previewUrl) => {
    let url = new URL(
      decodeURI(previewUrl.replace(/\+/g, "%20"))
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
    );
    let urlParams;
    let match,
      pl = /\+/g,
      search = /([^&=]+)=?([^&]*)/g,
      decode = (s) => decodeURIComponent(s.replace(pl, " ")),
      query = url.href;
    urlParams = {};
    while ((match = search.exec(query))) {
      urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
  };

  handlePreviewBanners = () => {
    let location = window.location.href;
    let locationInfoList = location.split("?");
    ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
      let url = locationInfoList[0] + resp;
      let urlParams = this.getUrlParams(url);
      if (!urlParams[`banners0photourl`]) {
        return this.getDefaultBanners();
      }
      let counter = 0;
      let previewBanners = [];
      bannerSize.forEach(() => {
        let banner = {};
        banner.id = `banner${counter}`;
        if (!urlParams[`banners${counter}photourl`]) {
          return;
        }
        banner.photoUrl = urlParams[`banners${counter}photourl`];
        banner.title = urlParams[`banners${counter}title`];
        banner.content = urlParams[`banners${counter}content`];
        previewBanners.push(banner);
        counter = counter + 1;
      });
      this.setState({
        carouselItems: [...previewBanners],
      });
    });
  };

  getDefaultBanners = () => {
    ApiService.getSelectedGlobalBanners("sale").subscribe((resp) => {
      this.setState({
        carouselItems: [...resp],
      });
    });
  };

  getSaleableProducts = () => {
    ApiService.getDapSaleableProducts().subscribe((resp) => {
      this.setSaleableProduct(resp);
    });
  };

  getSaleBanners = () => {
    ApiService.getSelectedBannersByType("sale").subscribe((res) => {
      this.setState({
        carouselItems: [...res],
      });
    });
  };

  setDefaults = () => {
    let datasource = _lodash.cloneDeep(this.state.datasource);
    datasource.model = _lodash.cloneDeep(SALE_DEFAULT);
    datasource.model.flipableCardsConfig.functions.handleSaleProductNavigation =
      this.handleSaleProductNavigation;
    datasource.model.messageWithCloseBtnDialogConfig.functions.closeDialog =
      this.closeMessageDialog;
    return datasource;
  };

  handleSaleProductNavigation = (item) => {
    this.props.history.push(`/satis/${item.productReference}`);
  };

  setSaleableProduct = (items) => {
    let aracList = [];
    let konutList = [];
    let saglikList = [];
    let digerList = [];
    items.forEach((item) => {
      switch (item.category) {
        case "arac":
          aracList.push(item);
          break;
        case "konut":
          konutList.push(item);
          break;
        case "saglik":
          saglikList.push(item);
          break;
        case "diger":
          digerList.push(item);
          break;
        default:
          break;
      }
    });
    let arac = {
      id: "arac",
      name: "Araç",
      list: [...aracList],
      icon: <AS.EmojiTransportationIcon className={styles.cardImg} />,
    };
    let konut = {
      id: "konut",
      name: "Konut",
      list: [...konutList],
      icon: <AS.HouseIcon className={styles.cardImg} />,
    };
    let saglik = {
      id: "saglik",
      name: "Sağlık",
      list: [...saglikList],
      icon: <AS.FavoriteBorderIcon className={styles.cardImg} />,
    };
    let diger = {
      id: "diger",
      name: "Diğer",
      list: [...digerList],
      icon: <AS.VerifiedUserIcon className={styles.cardImg} />,
    };
    let products = [];
    products.push(arac);
    products.push(konut);
    products.push(saglik);
    products.push(diger);
    this.setState((prevState) => {
      let datasource = { ...prevState.datasource };
      datasource.model.flipableCardsConfig.datasource.items = [...products];
      return { datasource };
    });
  };

  render() {
    const akyAgency = this.context.akyAgency;
    const preview = this.context.preview;
    return (
      <div className={styles.container}>
        <MetaDecorator
          title={`Satış | ${akyAgency.agencyTitle} Anadolu Sigorta`}
          description={this.state.datasource.model.helmetConfig.description}
        />
        <div className={styles.mainContainer}>
          <div className={styles.saleCarouselContainer}>
            <CarouselHelper items={this.state.carouselItems} />
            <div class="custom-shape-divider-bottom-1618394262">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                  class={
                    akyAgency.ecommerceInfo?.salePermissionExist
                      ? "shape-fill-sale"
                      : "shape-fill"
                  }
                ></path>
              </svg>
            </div>
          </div>
          {akyAgency.ecommerceInfo?.salePermissionExist && (
            <div
              className={styles.saleMainContainer}
              id="saleTabHelper"
              ref={this.saleProductsRef}
              aria-expanded={preview}
            >
              <SaleTabHelper />
            </div>
          )}
        </div>
        <MessageWithCloseBtn
          config={this.state.datasource.model.messageWithCloseBtnDialogConfig}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(Sale));
