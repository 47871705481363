import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import HealthInstitutions from "../health/healthinstitutions";
import Institutions from "../institutions";
import ContractedServices from "../services/contractedservices";
import _lodash from "lodash";
import { INSTITUTIONS_DEFAULT } from "./model";
import MetaDecorator from "../../../../util/MetaDecorator";
import NotFound from "../../notfound/notfound";
import { AuthorizationContext } from "../../../contexts/AuthorizationContext";

class InstitutionsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        model: _lodash.cloneDeep(INSTITUTIONS_DEFAULT),
      },
    };
  }

  static contextType = AuthorizationContext;

  render() {
    const akyAgency = this.context.akyAgency;
    return (
      <>
        <MetaDecorator
          title={`Anlaşmalı Kurumlar | ${akyAgency.agencyTitle} Anadolu Sigorta`}
          description={this.state.datasource.model.helmetConfig.description}
        />
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}`}
            component={Institutions}
          />
          <Route
            exact
            path={`${this.props.match.path}/anlasmali-servisler`}
            component={ContractedServices}
          />
          <Route
            exact
            path={`${this.props.match.path}/saglik-kurumlari`}
            component={HealthInstitutions}
          />
          <Route path="not-found" component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      </>
    );
  }
}

export default withRouter(InstitutionsMain);
