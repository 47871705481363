import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import AS from "../../lib/ASLibrary";
import { AuthorizationContext } from "../contexts/AuthorizationContext";
import { ApiService } from "../services/ApiService";
import styles from "./header.module.scss";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const drawerWidth = 280;

const useStyles = AS.makeStyles((theme) => ({
  listItemText: {
    fontSize: "18px !important",
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#f4f7f6",
  },
}));

const Header = (props) => {
  const t = props.t;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const { dapAgency } = useContext(AuthorizationContext);
  const { akyAgency } = useContext(AuthorizationContext);
  const { permission } = useContext(AuthorizationContext);
  const { updateOfferButtonScroll } = useContext(AuthorizationContext);
  const { preview } = useContext(AuthorizationContext);
  const [saleRedirect, setSaleRedirect] = useState(false);
  const [isAboutItem, setAboutItem] = useState(false);
  const [isPhoto, setIsPhoto] = useState(false);
  const [isPrize, setIsPrize] = useState(false);

  useEffect(() => {
    getSaleableProducts();
    getAboutItem();
    getPhotos();
    getPrizes();
  }, []);

  const getSaleableProducts = () => {
    ApiService.getDapSaleableProducts().subscribe((resp) => {
      let isEmpty = resp.length === 0;
      setSaleRedirect(isEmpty);
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleOffer = () => {
    if (saleRedirect) {
      window.open("https://www.anadolusigorta.com.tr", "_blank");
      return;
    }
    if (!permission) {
      updateOfferButtonScroll("sale");
      props.history.push("/satis");
    } else {
      updateOfferButtonScroll("home");
      props.history.push("/");
    }
  };

  const getAboutItem = () => {
    ApiService.getAbout("about").subscribe((resp) => {
      setAboutItem(resp.content.length !== 0);
    });
  };

  const getPhotos = () => {
    ApiService.getAbout("photo").subscribe((resp) => {
      setIsPhoto(resp.content.length !== 0);
    });
  };

  const getPrizes = () => {
    ApiService.getAbout("prize").subscribe((resp) => {
      setIsPrize(resp.content.length !== 0);
    });
  };

  const handleSidebarList = () => {
    let list = [];
    let permission = true;
    if (!akyAgency.ecommerceInfo?.webPermissionExist) {
      permission = false;
    }
    if (window.location.href.includes("?")) {
      permission = true;
    }
    if (permission && (isAboutItem || isPhoto || isPrize)) {
      list.push({ name: t("sidebar.about"), path: "/biz-kimiz" });
    }
    if (permission) {
      list.push({ name: t("sidebar.products"), path: "/urunler" });
      list.push({ name: t("sidebar.institutions"), path: "/kurumlar" });
      list.push({ name: t("sidebar.contact"), path: "/iletisim" });
    } else {
      list.push({ name: t("sidebar.contact"), path: "/iletisim" });
    }
    return list;
  };

  const drawer = (
    <div className={styles.sidebarContainer}>
      <div className={classes.toolbar} />
      <AS.Divider className={styles.divider} />
      <div className={styles.contentContainer}>
        <AS.List disablePadding>
          {handleSidebarList().map((link, index) => (
            <div className={styles.content}>
              <AS.ListItem
                className={styles.listItem}
                key={index}
                disableGutters
              >
                <Link
                  className={styles.drawerLink}
                  to={link.path}
                  onClick={() => handleDrawerToggle()}
                >
                  <AS.ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={link.name}
                  />
                </Link>
              </AS.ListItem>
              <AS.Divider className={styles.divider} />
            </div>
          ))}
        </AS.List>
        <AS.List>
          <div className={styles.socialsContainer}>
            {dapAgency.instagram && (
              <a
                href={dapAgency.instagram}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
              >
                <div className={styles.socialIconContainer}>
                  <AS.InstagramIcon className={styles.socialIcon} />
                </div>
              </a>
            )}
            {dapAgency.facebook && (
              <a
                href={dapAgency.facebook}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
              >
                <div className={styles.socialIconContainer}>
                  <AS.FacebookIcon className={styles.socialIcon} />
                </div>
              </a>
            )}
            {dapAgency.twitter && (
              <a
                href={dapAgency.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
              >
                <div className={styles.socialIconContainer}>
                  <AS.TwitterIcon className={styles.socialIcon} />
                </div>
              </a>
            )}
            {dapAgency.linkedin && (
              <a
                href={dapAgency.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.socialLink}
              >
                <div className={styles.socialIconContainer}>
                  <AS.LinkedInIcon className={styles.socialIcon} />
                </div>
              </a>
            )}
          </div>
        </AS.List>
      </div>
    </div>
  );

  const theme = AS.createMuiTheme({
    breakpoints: {
      keys: ["xs", "sm", "md", "lg", "xl"],
      values: {
        xs: 0,
        sm: 599,
        md: 1040,
        lg: 1439,
        xl: 1919,
      },
    },
  });

  const handleTitleRedirection = () => {
    if (
      window.location.href.includes("?") &&
      !window.location.href.includes("/agency-view")
    ) {
      return;
    } else if (permission) {
      props.history.push("/");
    } else {
      props.history.push("/satis");
    }
  };

  return (
    <AS.MuiThemeProvider theme={theme}>
      <header className={styles.siteheader}>
        <div className={`${styles.wrapper} ${styles.siteheader__wrapper}`}>
          <div className={styles.siteheader__start}>
            <div
              className={styles.nav__item}
              style={{ cursor: "pointer" }}
              onClick={() => handleTitleRedirection()}
            >
              {dapAgency.logoUrl ? (
                <div className={styles.logoChild}>
                  <img
                    className={styles.logo}
                    src={`${BASE_URL}${dapAgency.logoUrl}`}
                    alt="logo"
                  />
                </div>
              ) : (
                <div className={styles.nav__item}>{dapAgency.name}</div>
              )}
            </div>
          </div>
          <AS.Hidden smDown>
            <div className={styles.siteheader__middle}>
              <nav className={styles.nav}>
                <ul className={styles.nav__wrapper}>
                  {permission && (isAboutItem || isPhoto || isPrize) && (
                    <li>
                      <Link className={styles.nav__item__link} to="/biz-kimiz">
                        {t("sidebar.about")}
                      </Link>
                    </li>
                  )}
                  {permission && (
                    <li>
                      <Link className={styles.nav__item__link} to="/urunler">
                        {t("sidebar.products")}
                      </Link>
                    </li>
                  )}
                  {permission && (
                    <li>
                      <Link className={styles.nav__item__link} to="/kurumlar">
                        {t("sidebar.institutions")}
                      </Link>
                    </li>
                  )}
                  {!window.location.href.includes("?") ||
                  window.location.href.includes("/agency-view") ? (
                    <li>
                      <Link className={styles.nav__item__link} to="/iletisim">
                        {t("sidebar.contact")}
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </nav>
            </div>
          </AS.Hidden>
          {!window.location.href.includes("?") ||
          window.location.href.includes("/agency-view") ? (
            <div className={styles.siteheader__end}>
              <div className={styles.proposalButtonContainer}>
                {akyAgency.ecommerceInfo?.salePermissionExist && !preview && (
                  <AS.Button
                    className={styles.proposalButton}
                    onClick={() => handleOffer()}
                  >
                    {t("header.proposal_button")}
                  </AS.Button>
                )}
              </div>
            </div>
          ) : null}
          <AS.Hidden mdUp>
            <div className={styles.menuButtonContainer}>
              <AS.IconButton onClick={() => handleDrawerToggle()}>
                <AS.MenuIcon className={styles.menuIcon} />
              </AS.IconButton>
            </div>
          </AS.Hidden>
        </div>
        <nav>
          <AS.Hidden smUp implementation="css">
            <AS.Drawer
              container={props.container}
              variant="temporary"
              anchor={"right"}
              open={mobileOpen}
              onClose={() => handleDrawerToggle()}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {drawer}
            </AS.Drawer>
          </AS.Hidden>
        </nav>
      </header>
    </AS.MuiThemeProvider>
  );
};

export default withTranslation()(withRouter(Header));
