import React, { Component } from "react";
import styles from "./contactform.module.scss";
import AS from "../../../../lib/ASLibrary";
import { withTranslation } from "react-i18next";
import { ApiService } from "../../../services/ApiService";
import AlertHelper from "../../alert/alert";
import lodash from "lodash";
import { CONTACTFORM_DEFAULT } from "./model";
import { AuthorizationContext } from "../../../contexts/AuthorizationContext";

const pattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);

const subjectList = [
  { id: 0, name: "Ürün Bilgi Talebi" },
  { id: 1, name: "Memnuniyet" },
  { id: 2, name: "Öneri" },
  { id: 3, name: "Şikayet" },
  { id: 4, name: "Diğer" },
];

class ContactFormHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      phone: undefined,
      email: undefined,
      subject: undefined,
      webProduct: undefined,
      detail: undefined,
      kvkkConfirmation: false,
      buttonDisabled: false,
      nameError: "",
      emailError: "",
      subjectError: "",
      detailError: "",
      webProducts: [],
      captchaImage: undefined,
      captchaId: undefined,
      captcha: undefined,
      captchaError: "",
      model: lodash.cloneDeep(CONTACTFORM_DEFAULT),
    };
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    this.setState(
      {
        model: this.setDefaults(),
      },
      () => {
        ApiService.getWebProducts().subscribe((resp) => {
          this.setWebProducts(resp.content);
          this.getCaptchaImage();
        });
      }
    );
  }

  getCaptchaImage = () => {
    let id = this.uuidv4();
    ApiService.createCaptchaImage(id).subscribe((resp) => {
      this.setState({ captchaImage: resp, captchaId: id });
    });
  };

  uuidv4 = () => {
    return "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  setDefaults = () => {
    let model = lodash.cloneDeep(CONTACTFORM_DEFAULT);
    model.alertConfig.functions.closeAlert = this.closeAlertDialog;
    return model;
  };

  closeAlertDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.alertConfig.datasource.alertShow = false;
      return { datasource };
    });
  };

  setWebProducts = (webProducts) => {
    let parentProducts = webProducts.filter((webProduct) => {
      return webProduct.parentProductId === 0;
    });
    parentProducts.forEach((parentProduct) => {
      let subProducts = [];
      webProducts.forEach((webProduct) => {
        if (parentProduct.id === webProduct.parentProductId) {
          subProducts.push(webProduct);
        }
      });
      parentProduct.subProducts = [...subProducts];
    });
    this.setState({
      webProducts: [...parentProducts],
    });
  };

  handleNameSurnameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handlePhoneChange = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  handleEmailChange = (e) => {
    let email = e.target.value;
    let error = "";
    if (email && !pattern.test(email)) {
      error = this.props.t("profile.validation.email");
    }
    this.setState({
      email: email,
      emailError: error,
    });
  };

  handleSubjectChange = (e) => {
    this.setState({
      subject: e.target.value,
      webProduct: undefined,
    });
  };

  handleWebProductChange = (e) => {
    this.setState({
      webProduct: e.target.value,
    });
  };

  handleDetailChange = (e) => {
    let detail = e.target.value;
    let error = "";
    if (!detail) {
      error = "Zorunlu alandır.";
    }
    this.setState({
      detail: e.target.value,
      detailError: error,
    });
  };

  handleKVKKChange = (e) => {
    this.setState({
      kvkkConfirmation: e.target.checked,
    });
  };

  phoneValidationChange = (state) => {
    let buttonDisabled = state === "Not Validated";
    this.setState({
      buttonDisabled: buttonDisabled,
    });
  };

  nameValidationChange = (state) => {
    let buttonDisabled = state === "Not Validated";
    this.setState({
      buttonDisabled: buttonDisabled,
    });
  };

  handleSendForm = () => {
    if (!this.state.captcha) {
      this.setState({
        captchaError: this.props.t("contact_form.captcha_error"),
      });
      return;
    }
    let contactForm = {};
    contactForm.name = this.state.name;
    contactForm.phone = this.state.phone;
    contactForm.email = this.state.email;
    contactForm.subject = this.state.subject.name;
    if (this.state.subject.id === 0) {
      contactForm.webProductPrefix = this.state.webProduct.prefix;
    }
    contactForm.detail = this.state.detail;
    contactForm.captcha = this.state.captcha;
    contactForm.captchaId = this.state.captchaId;
    contactForm.kvkkConfirmation = this.state.kvkkConfirmation;
    ApiService.sendContactForm(contactForm)
      .subscribe(
        (resp) => {
          if (resp) {
            this.handleAlertMessage("contact_form.success", "success");
          } else {
            this.handleAlertMessage("contact_form.failed", "error");
          }
        },
        () => {
          this.handleAlertMessage("contact_form.failed", "error");
        }
      )
      .add(() => {
        this.getCaptchaImage();
      });
  };

  handleAlertMessage = (message, severity) => {
    this.setState((prevState) => {
      let datasource = { ...prevState };
      datasource.model.alertConfig.datasource.alertShow = true;
      datasource.model.alertConfig.datasource.severity = severity;
      datasource.model.alertConfig.datasource.message = message;
      return { datasource };
    });
  };

  handleDisableButton = () => {
    let disabled = false;
    if (this.state.buttonDisabled) {
      disabled = true;
      return disabled;
    }
    if (!this.state.email || this.state.emailError !== "") {
      disabled = true;
      return disabled;
    }
    if (
      (this.state.subject !== 0 && !this.state.subject) ||
      this.state.subjectError !== ""
    ) {
      disabled = true;
      return disabled;
    }
    if (!this.state.detail || this.state.detailError !== "") {
      disabled = true;
      return disabled;
    }
    if (!this.state.kvkkConfirmation) {
      disabled = true;
      return disabled;
    }
    return disabled;
  };

  handleWebProduct = (webProduct) => {
    if (webProduct.subProducts.length > 0) {
      const items = webProduct.subProducts.map((subProduct) => {
        return (
          <AS.MenuItem
            key={subProduct.id}
            value={subProduct}
            className={styles.menuItem}
          >
            {`- ${subProduct.name}`}
          </AS.MenuItem>
        );
      });
      return [<AS.ListSubheader>{webProduct.name}</AS.ListSubheader>, items];
    } else {
      return (
        <AS.MenuItem
          key={webProduct.id}
          value={webProduct}
          className={styles.menuItem}
        >
          {`- ${webProduct.name}`}
        </AS.MenuItem>
      );
    }
  };

  handleCaptchaChange = (event) => {
    this.setState({
      captcha: event.target.value,
      captchaError: "",
    });
  };

  render() {
    const t = this.props.t;
    return (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <p className={`${styles.title} mobile-title-font-size`}>
            {t("contact_form.title")}
          </p>
          <p className={`${styles.content} mobile-content-font-size`}>
            {t("contact_form.content")}
          </p>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.inputContainer}>
            <AS.ASTextField
              value={this.state.name}
              onChangeText={this.handleNameSurnameChange}
              onValidationStateChanged={this.nameValidationChange}
              mandatory
              warnMessage="Zorunlu alandır."
              label={t("contact_form.name_surname")}
            />
          </div>
          <div className={styles.inputContainer}>
            <AS.PhoneField
              size="medium"
              mandatory
              label={t("contact_form.phone")}
              onChangeText={this.handlePhoneChange}
              onValidationStateChanged={this.phoneValidationChange}
            />
          </div>

          <div className={styles.inputContainer}>
            <AS.TextField
              id="outlined-basic"
              fullWidth
              value={this.state.email}
              onChange={this.handleEmailChange}
              label={t("contact_form.e-mail")}
              style={{ marginTop: "1.1rem" }}
              className={styles.mailInput}
            />
            <AS.FormHelperText
              id="helper-text"
              error={this.state.emailError.length !== 0}
              className={styles.formHelper}
            >
              {this.state.emailError}
            </AS.FormHelperText>
          </div>
          <div className={styles.inputContainer}>
            <AS.FormControl>
              <AS.InputLabel
                id="demo-simple-select-label"
                className={styles.selectLabel}
              >
                {t("contact_form.subject")}
              </AS.InputLabel>
              <AS.Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.subject}
                onChange={this.handleSubjectChange}
                className={styles.select}
                MenuProps={{
                  disableScrollLock: false,
                  classes: {
                    paper: {
                      maxHeight: 500,
                    },
                  },
                }}
              >
                {subjectList.map((item) => {
                  return (
                    <AS.MenuItem value={item} className={styles.menuItem}>
                      {item.name}
                    </AS.MenuItem>
                  );
                })}
              </AS.Select>
              <AS.FormHelperText
                id="helper-text"
                error={this.state.subjectError.length !== 0}
              >
                {this.state.subjectError}
              </AS.FormHelperText>
            </AS.FormControl>
          </div>
          {this.state.subject?.id === 0 && (
            <div className={styles.inputContainer} style={{ width: "100%" }}>
              <AS.FormControl>
                <AS.InputLabel
                  id="demo-simple-select-label-product"
                  className={styles.selectLabel}
                >
                  {t("contact_form.product")}
                </AS.InputLabel>
                <AS.Select
                  labelId="demo-simple-select-label-product"
                  id="demo-simple-select-product"
                  value={this.state.webProduct}
                  onChange={this.handleWebProductChange}
                  className={styles.select}
                  MenuProps={{
                    disableScrollLock: false,
                    classes: {
                      paper: {
                        maxHeight: 500,
                      },
                    },
                  }}
                >
                  {this.state.webProducts.map((webProduct) =>
                    this.handleWebProduct(webProduct)
                  )}
                </AS.Select>
                <AS.FormHelperText
                  id="helper-text"
                  error={this.state.subjectError.length !== 0}
                >
                  {this.state.subjectError}
                </AS.FormHelperText>
              </AS.FormControl>
            </div>
          )}
          <div className={styles.inputContainer}>
            <AS.TextField
              id="standard-multiline-flexible"
              multiline
              rowsMax={4}
              value={this.state.detail}
              onChange={this.handleDetailChange}
              error={this.state.detailError.length !== 0}
              helperText={this.state.detailError}
              className={styles.detail}
              label={t("contact_form.detail")}
            />
          </div>
          {this.state.captchaImage && (
            <div className={styles.captchaContainer}>
              <div className={styles.captchaImageContainer}>
                <img
                  src={`data:image/png;base64, ${this.state.captchaImage}`}
                  alt="Captcha"
                />
                <AS.AutorenewIcon
                  className={styles.renewIcon}
                  onClick={() => this.getCaptchaImage()}
                />
              </div>
              <AS.ASTextField
                className={styles.captchaTextInput}
                value={this.state.captcha}
                onChangeText={this.handleCaptchaChange}
                mandatory
                warnMessage="Zorunlu alandır."
                label={t("contact_form.captcha_error")}
              />
            </div>
          )}
          <div className={styles.kvkkContainer}>
            <a
              href="https://www.anadolusigorta.com.tr/tr/yasal-bilgilendirme/kvkk"
              className={styles.kvkkLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("contact_form.kvkk")}
            </a>
          </div>
          <div className={styles.commercialContainer}>
            <AS.FormControlLabel
              control={
                <AS.Checkbox
                  checked={this.state.kvkkConfirmation}
                  onChange={this.handleKVKKChange}
                  style={{
                    transform: "scale(1.5)",
                    marginRight: ".5rem",
                  }}
                  className={styles.commercialFormControl}
                />
              }
              label={
                <span className={styles.commercialContentContainer}>
                  {t("contact_form.commercial_content")}
                </span>
              }
            />
          </div>
          <div className={styles.sendBtnContainer}>
            <AS.Button
              variant="contained"
              disabled={this.handleDisableButton()}
              className={styles.sendBtn}
              onClick={() => this.handleSendForm()}
            >
              {t("contact_form.send_button")}
            </AS.Button>
          </div>
        </div>
        <AlertHelper config={this.state.model.alertConfig} />
      </div>
    );
  }
}

export default withTranslation()(ContactFormHelper);
