export const CONTRACTED_SERVICES_DEFAULT = {
  helmetConfig: {
    description:
      "Oto servisleri, sağlık kuruluşları ve mini onarım hizmetlerini Anadolu Sigorta ayrıcalığıyla anlaşmalı yetkili servisten almak için sayfamızı ziyaret edin.",
    title: "Anlaşmalı Yetkili Servisler",
  },
  serviceTypesConfig: {
    datasource: {
      items: [],
      selectedItem: undefined,
      label: "institutions.contracted_services.service_type",
    },
    functions: {
      handleChange: undefined,
    },
  },
  citiesConfig: {
    datasource: {
      items: [],
      selectedItem: undefined,
      label: "institutions.contracted_services.city",
    },
    functions: {
      handleChange: undefined,
    },
  },
  countiesConfig: {
    datasource: {
      items: [],
      selectedItem: undefined,
      label: "institutions.contracted_services.county",
    },
    functions: {
      handleChange: undefined,
    },
  },
  brandsConfig: {
    datasource: {
      items: [],
      selectedItem: undefined,
      label: "institutions.contracted_services.brand",
    },
    functions: {
      handleChange: undefined,
    },
  },
  miniRepair: {
    citiesConfig: {
      datasource: {
        items: [],
        selectedItem: undefined,
        label: "institutions.contracted_services.city",
      },
      functions: {
        handleChange: undefined,
      },
    },
    countiesConfig: {
      datasource: {
        items: [],
        selectedItem: undefined,
        label: "institutions.contracted_services.county",
      },
      functions: {
        handleChange: undefined,
      },
    },
  },
};
