import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AS from "../../../../lib/ASLibrary";
import styles from "./pulse.module.scss";

const upStyles = {
  root: {
    position: "fixed",
    bottom: "60px",
    right: "60px",
    backgroundColor: "aliceblue",
    boxShadow: "0 0 0 0 rgba(13, 178, 255, .5)",
    animation: "$pulse 2s infinite",
    zIndex: "9999",
    opacity: "0",
    transform: "translateY(100px)",
    transition: "all .5s ease",
    "&:hover": {
      backgroundColor: "aliceblue",
    },
    "@media (max-width: 768px)": {
      display: "none",
    }
  },
  "@keyframes pulse": {
    "70%": {
      boxShadow: "0 0 0 20px rgba(13, 178, 255, 0)",
    },
    "100%": {
      boxShadow: "0 0 0 0 rgba(13, 178, 255, 0)",
    },
  },
};

class PulseButtonHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let upButton = document.getElementById("upButton");
    window.onscroll = () => {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        upButton.style.opacity = "1";
        upButton.style.transform = "translateY(0)";
      } else {
        upButton.style.opacity = "0";
        upButton.style.transform = "translateY(100px)";
      }
    };
  }

  handleUp = () => {
    window.scroll({
      left: 0,
      top: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <AS.IconButton
        className={this.props.classes.root}
        onClick={() => this.handleUp()}
        id="upButton"
      >
        <AS.ExpandLessIcon className={styles.upIcon} />
      </AS.IconButton>
    );
  }
}

export default withStyles(upStyles)(PulseButtonHelper);
