export const SALE_DEFAULT = {
  helmetConfig: {
    description:
      "İhtiyacınız olan poliçeyi seçerek Anadolu Sigorta güvencesiyle online poliçe almak ve size özel fiyat tekliflerini görmek için sayfamızı ziyaret edin.",
    title: "Online Satış",
  },
  flipableCardsConfig: {
    datasource: {
      items: [],
    },
    functions: {
      handleSaleProductNavigation: undefined,
    },
  },
  messageWithCloseBtnDialogConfig: {
    datasource: {
      dialogShow: false,
      message: undefined,
    },
    functions: {
      closeDialog: undefined,
    },
  },
};
