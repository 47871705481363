import axiosInstance from "../../api/axios-instance";
import { Observable } from "rxjs";

const webui_path = "/webui";
const updateimage_path = "/updateimage";
const insecure_path = "/insecure";
const third_party = "/thirdparty";

const changeAgent = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/authenticate-agent/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
      });
  });
};

const changeAgentByDomain = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/authenticate-agent-domain/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
      });
  });
};

const getAuthorizedAreas = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + "/authorized-areas")
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getBannersByType = (type, currentPage) => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/dap-banners/type/${type}?page=${currentPage}&size=10`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getSelectedBannersByType = (type) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-banners-selected/type/${type}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getGalleryPhotos = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/dap-photos?page=0&size=200`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveBanner = (form) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-banners", form)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const searchDapBanners = (type, value) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        webui_path +
          `/_search/dap-banners/type/${type}?query=${value}&page=0&size=200`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const deleteBanner = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .delete(webui_path + `/dap-banners/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const publishBanners = (type, banners) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + `/publish-dap-banners/type/${type}`, banners)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getBanner = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/dap-banners/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getDapAgent = (type, id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-agents/${type}/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
      });
  });
};

const getDapAgents = (currentPage) => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/dap-agents?page=${currentPage}&size=10`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const searchAgents = (currentPage, value) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        webui_path +
          `/_search/dap-agents?page=${currentPage}&size=10&query=${value}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getAllBanners = (currentPage) => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/dap-allbanners?page=${currentPage}&size=10`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const searchAllBanners = (type, isGlobal, value, currentPage) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        webui_path +
          `/_search/dap-all-banners/type/${type}/${isGlobal}?query=${value}&page=${currentPage}&size=10`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getAbout = (type) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-abouts/type/${type}?page=0&size=40`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getOne = (endpoint) => {
  return new Observable((observer) => {
    axiosInstance
      .get(endpoint)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const uploadImage = (name, formData) => {
  return new Observable((observer) => {
    axiosInstance
      .post(updateimage_path + `/upload/${name}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const publishAbouts = (abouts) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/publish-dap-abouts", abouts)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveGalleryPhoto = (form) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-photos", form)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const deleteGalleryPhoto = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/delete-dap-photo", id)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const updateImage = (formData) => {
  return new Observable((observer) => {
    axiosInstance
      .post(updateimage_path + "/update-image", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getGalleryPhotoInfo = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-photo-info", id)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveProfile = (config) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-agents", config)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getWebProducts = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + "/dap-web-products?page=0&size=200")
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveWebProduct = (webProduct) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-web-products", webProduct)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const publishWebProducts = (webProducts) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/publish-dap-web-products", webProducts)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getSaleProducts = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + "/dap-products")
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getSaleProductsReferences = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + "/dap-products-references")
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getSaleProduct = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/dap-products/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveSaleProduct = (saleProduct) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-products", saleProduct)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveUser = (user) => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/dap-users", user)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const accsessToken = () => {
  return new Observable((observer) => {
    axiosInstance
      .post(webui_path + "/access-token")
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getUser = (user) => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + "/dap-users/" + user)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const channelEcommerceInformation = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/channelEcommerceInformation`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const pageableChannelEcommerceInformation = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(webui_path + `/pageableChannelEcommerceInformation`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getDapWebProductsByParentProduct = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-web-products/parent/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getDapWebProduct = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-web-products/${id}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getDapSaleableProducts = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-saleable-products`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getServiceTypes = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path + third_party + `/rh-contracted-services/service-types`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getCities = (serviceType) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/rh-contracted-services/cities?serviceType=${serviceType}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getCounties = (serviceType, city) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/rh-contracted-services/counties?serviceType=${serviceType}&city=${city}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getBrands = (serviceType, city, county) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/rh-contracted-services/brands?serviceType=${serviceType}&city=${city}&county=${county}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const searchByServiceTypeAndCountyAndBrand = (serviceType, county, brand) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/rh-contracted-services/contracted-services-by-brand-and-county-and-service?serviceType=${serviceType}&county=${county}&brand=${brand}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const searchByServiceTypeAndCityAndCounty = (serviceType, city, county) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/rh-contracted-services/contracted-services-by-county-and-city-and-service?serviceType=${serviceType}&city=${city}&county=${county}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const searchByServiceTypeAndCity = (serviceType, city) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/rh-contracted-services/contracted-services-by-city-and-service?serviceType=${serviceType}&city=${city}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getMiniServicesCities = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + third_party + `/mini-repair/cities`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getMiniServicesCounties = (city) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + third_party + `/mini-repair/counties?city=${city}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const searchMiniServices = (city, county) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/mini-repair/services?city=${city}&county=${county}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getArticle = (path, pathSelector, exclusionPathSelector) => {
  return new Observable((observer) => {
    axiosInstance
      .get(
        insecure_path +
          third_party +
          `/articles-zones/article?path=${path}&pathSelector=${pathSelector}&exclusionPathSelector=${exclusionPathSelector}`
      )
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const sendContactForm = (form) => {
  return new Observable((observer) => {
    axiosInstance
      .post(insecure_path + third_party + "/messaging/contact-form", form)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

const getSelectedGlobalBanners = (type) => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + `/dap-banners/global/${type}`)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const proposalErrorLog = (info) => {
  return new Observable((observer) => {
    axiosInstance
      .post(insecure_path + "/proposal-error-log", info)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const saveToRedis = (form) => {
  return new Observable((observer) => {
    axiosInstance
      .post(insecure_path + "/temp-info", form)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getRedisInfo = (key) => {
  return new Observable((observer) => {
    axiosInstance
      .post(insecure_path + "/read-temp-info", key)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const deleteFromRedis = (key) => {
  return new Observable((observer) => {
    axiosInstance
      .delete(insecure_path + "/temp-info", { data: key })
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const createCaptchaImage = (id) => {
  return new Observable((observer) => {
    axiosInstance
      .post(insecure_path + third_party + "/captcha/simple-captcha", id)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const verifyReCaptcha = (reCaptchaToken) => {
  return new Observable((observer) => {
    axiosInstance
      .post(insecure_path + third_party + "/captcha/recaptcha", reCaptchaToken)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

const getCaptchaConfig = () => {
  return new Observable((observer) => {
    axiosInstance
      .get(insecure_path + "/dap-captcha-config")
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const ApiService = {
  publishBanners,
  getGalleryPhotos,
  saveBanner,
  deleteBanner,
  searchDapBanners,
  getOne,
  uploadImage,
  publishAbouts,
  saveGalleryPhoto,
  deleteGalleryPhoto,
  getGalleryPhotoInfo,
  saveProfile,
  getWebProducts,
  saveWebProduct,
  publishWebProducts,
  getSaleProducts,
  getSaleProduct,
  saveSaleProduct,
  saveUser,
  getAuthorizedAreas,
  changeAgent,
  changeAgentByDomain,
  getBannersByType,
  getSelectedBannersByType,
  getBanner,
  getDapAgent,
  getDapAgents,
  searchAgents,
  getAllBanners,
  searchAllBanners,
  getAbout,
  getSaleProductsReferences,
  accsessToken,
  updateImage,
  getUser,
  channelEcommerceInformation,
  pageableChannelEcommerceInformation,
  getDapWebProductsByParentProduct,
  getDapWebProduct,
  getDapSaleableProducts,
  getServiceTypes,
  getCities,
  getCounties,
  getBrands,
  searchByServiceTypeAndCountyAndBrand,
  searchByServiceTypeAndCityAndCounty,
  searchByServiceTypeAndCity,
  getMiniServicesCities,
  getMiniServicesCounties,
  searchMiniServices,
  getArticle,
  sendContactForm,
  getSelectedGlobalBanners,
  proposalErrorLog,
  saveToRedis,
  getRedisInfo,
  deleteFromRedis,
  createCaptchaImage,
  verifyReCaptcha,
  getCaptchaConfig
};
