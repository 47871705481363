import CryptoJS from "crypto-js";

const { REACT_APP_SALE_FORMS_SECRET_KEY } = process.env;

export const encryptForm = (form, akyAgency) => {
  form = form + `&callBackUrl=${handleCallBackUrl(akyAgency)}`;
  return CryptoJS.AES.encrypt(form, REACT_APP_SALE_FORMS_SECRET_KEY).toString();
};

export const decryptForm = (secret) => {
  return CryptoJS.AES.decrypt(secret, REACT_APP_SALE_FORMS_SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
};

const handleCallBackUrl = (akyAgency) => {
  let agencyCode = sessionStorage.getItem("agencyCode");
  if (!isNumber(agencyCode) && akyAgency.ecommerceInfo.domainAddress) {
    let callBackHttps = akyAgency.ecommerceInfo.domainAddress.startsWith("https://") ? "" : "https://";
    let satis = akyAgency.ecommerceInfo.domainAddress.endsWith("/")
      ? "satis-sonuc"
      : "/satis-sonuc";
    return callBackHttps + akyAgency.ecommerceInfo.domainAddress + satis;
  }
  let errorUrl = process.env.REACT_APP_AGENCY_VIEW_BASE_URL + agencyCode;
  return errorUrl + "%26satis-sonuc";
};

const isNumber = (n) => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
};

export const reformatDate = (dateStr) => {
  let dArr = dateStr.split("-");
  return dArr[2] + "-" + dArr[1] + "-" + dArr[0].substring(0, 4);
};
