import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import { AuthorizationContext } from "./components/contexts/AuthorizationContext";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import About from "./components/pages/about/about";
import Contact from "./components/pages/contact/contact";
import Home from "./components/pages/home/home";
import InstitutionsMain from "./components/pages/institutions/main/institutionsmain";
import NotFound from "./components/pages/notfound/notfound";
import Preview from "./components/pages/preview/preview";
import ProductsMain from "./components/pages/products/main/productsmain";
import SaleMain from "./components/pages/sale/main/salemain";
import SaleRedirection from "./components/pages/saleredirection/saleredirection";
import ScrollToTop from "./components/scrolltotop/scrolltotop";
import { ApiService } from "./components/services/ApiService";
import { AuthorizationService } from "./components/services/authorization";

const INACTIVE_STATUS_CODES = [28, 30];

function App(props) {
  const {
    updateDapAgency,
    updateAkyAgency,
    akyAgency,
    loading,
    permission,
    updatePermission,
    updateLoading,
    updatePreview,
  } = useContext(AuthorizationContext);

  const customTheme = createMuiTheme({
    overrides: {
      MuiInput: {
        input: {
          "&::placeholder": {
            fontSize: "1.8rem",
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: "#9F9F9F !important",
          fontSize: "1.8rem",
          paddingLeft: ".6rem",
        },
      },
    },
  });

  useEffect(() => {
    let url = window.location.href;
    let domain = window.location.hostname;
    props.location.state = undefined;
    if (!url.includes("preview?")) {
      if (url.indexOf("/agency-view") > 1) {
        if (url.indexOf("&") > 1) {
          domain = url.substring(
            url.indexOf("/agency-view") + 13,
            url.indexOf("&") - 1
          );
          if (url.includes("&satis-sonuc")) {
            domain = url.substring(
              url.indexOf("/agency-view") + 13,
              url.indexOf("&satis-sonuc")
            );
          }
        } else {
          domain = url.substring(url.indexOf("/agency-view") + 13);
        }
        sessionStorage.setItem("agencyCode", domain);
      } else if (domain === "localhost" || domain.includes("anadolusigorta")) {
        let ac = sessionStorage.getItem("agencyCode");
        domain = ac ? ac : "0";
      }
      if (isNumber(domain)) {
        ApiService.changeAgent(domain).subscribe(
          (resp) => {
            changeAgencyValues(resp, domain);
          },
          (err) => {
            updatePermission(false);
          }
        );
      } else {
        ApiService.changeAgentByDomain(domain).subscribe(
          (resp) => {
            changeAgencyValues(resp, domain);
          },
          (err) => {
            updatePermission(false);
          }
        );
      }
    }
    let preview = sessionStorage.getItem("preview");
    if (preview != null) {
      updatePreview(preview);
    }
  }, []);

  const isNumber = (n) => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  };

  const changeAgencyValues = (resp, domain) => {
    AuthorizationService.setToken(resp);
    ApiService.channelEcommerceInformation().subscribe((akyAgency) => {
      updateAkyAgency(akyAgency);
      ApiService.getDapAgent("general", akyAgency.agencyCode)
        .subscribe(
          (dapAgency) => {
            updateDapAgency(dapAgency);
            updateLoading(false);
          },
          (err) => {
            updatePermission(false);
          }
        )
        .add(() => {
          if (!window.location.href.includes("/teklif-gor")) {
            handleNavigationByPermission(akyAgency);
          }
          handlePermission(akyAgency);
        });
    });
  };

  const handleNavigationByPermission = (akyAgency) => {
    if (INACTIVE_STATUS_CODES.includes(akyAgency.statusCode)) {
      window.location.replace("/not-found");
    }
    if (akyAgency.ecommerceInfo) {
      if (
        !akyAgency.ecommerceInfo.webPermissionExist &&
        akyAgency.ecommerceInfo.salePermissionExist
      ) {
        if (window.location.href.includes("satis-sonuc")) {
          let location = window.location.href.split("satis-sonuc&");
          props.history.push("/satis/satis-sonuc?" + location[1]);
        } else {
          props.history.push("/satis");
        }
      } else if (
        !akyAgency.ecommerceInfo.webPermissionExist &&
        !akyAgency.ecommerceInfo.salePermissionExist
      ) {
        props.history.push("/not-found");
      }
    }
  };

  const handlePermission = (akyAgency) => {
    if (!akyAgency.ecommerceInfo?.webPermissionExist) {
      updatePermission(false);
    } else if (
      window.location.href.includes("?") &&
      !window.location.href.includes("/agency-view")
    ) {
      updatePermission(true);
    } else {
      updatePermission(true);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <ScrollToTop />
      {!window.location.href.includes("/not-found") &&
        !window.location.href.includes("/teklif-gor") &&
        !loading && <Header />}
      <div className="main-content">
        <Switch>
          {(permission || window.location.href.includes("?")) && (
            <Route
              exact
              path={[
                "/",
                "/agency-view",
                "/agency-view?:id&satis-sonuc",
                "/satis-sonuc",
              ]}
              component={Home}
            />
          )}
          {(permission || window.location.href.includes("?")) && (
            <Route path="/biz-kimiz" component={About} />
          )}
          {(permission || window.location.href.includes("?")) && (
            <Route path="/urunler" component={ProductsMain} />
          )}
          {akyAgency.ecommerceInfo?.webPermissionExist && (
            <Route path="/kurumlar" component={InstitutionsMain} />
          )}
          <Route path="/iletisim" component={Contact} />
          <Route path={["/satis", "/satis/satis-sonuc"]} component={SaleMain} />
          <Route path="/preview" component={Preview} />
          <Route path="/teklif-gor" component={SaleRedirection} />
          {permission !== undefined && (
            <Route path="/not-found" component={NotFound} />
          )}
          {permission !== undefined && <Redirect to="/not-found" />}
        </Switch>
      </div>
      {!window.location.href.includes("/not-found") &&
        !window.location.href.includes("/teklif-gor") &&
        !loading && <Footer />}
    </ThemeProvider>
  );
}

export default withRouter(App);
