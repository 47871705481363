import React, { useContext } from "react";
import AS from "../../../lib/ASLibrary";
import { MessageContext } from "../../contexts/MessageContext";
import styles from "./toast.module.scss";

function Alert(props) {
  return <AS.Alert elevation={6} variant="filled" {...props} />;
}

const ToastHelper = () => {
  const { messages, deleteToast } = useContext(MessageContext);

  const dateHandler = (timeStamp) => {
    let date = new Date(timeStamp);
    return `${date.toLocaleDateString("tr-TR")} ${date.toLocaleTimeString(
      "tr-TR"
    )}`;
  };

  return (
    <div className={styles.alertContainer}>
      {messages.map((toast, index) => {
        return (
          <AS.Snackbar
            key={index}
            open={true}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            className={styles.snackbar}
          >
            <Alert
              key={index}
              onClose={() => deleteToast(index)}
              severity={toast.level}
              style={{ width: "100%" }}
            >
              {toast.isApiMessage ? (
                <>
                  <div>{toast.message}</div>
                  <div className={styles.divider}></div>
                  <div>{`${toast.api}(${toast.module}) - ${
                    toast.code
                  } - ${dateHandler(toast.timeStamp)}`}</div>
                  <div>{toast.requestId}</div>
                </>
              ) : (
                <div>{toast.message}</div>
              )}
            </Alert>
          </AS.Snackbar>
        );
      })}
    </div>
  );
};

export default ToastHelper;
