export const HOME_DEFAULT = {
  helmetConfig: {
    description:
      "Anadolu Sigorta güvencesiyle sunulan poliçeler hakkında bilgi almak, acentemiz ile iletişime geçmek ve online poliçe satın almak için sayfamızı ziyaret edin.",
    title: "Ana Sayfa",
  },
  webProductsConfig: {
    datasource: {
      parentProducts: [],
      otherProducts: [],
      childProducts: [],
      selectedItem: undefined,
    },
    functions: {
      createHandler: undefined,
      editHandler: undefined,
      navigationHandler: undefined,
    },
  },
  messageWithCloseBtnDialogConfig: {
    datasource: {
      dialogShow: false,
      message: undefined,
    },
    functions: {
      closeDialog: undefined,
    },
  },
};
