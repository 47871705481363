import axios from "axios";
import { Observable } from "rxjs";

const { REACT_APP_BIREYSEL_API_URL } = process.env;

const offerAxiosInstance = axios.create({
  baseURL: REACT_APP_BIREYSEL_API_URL,
});

const quick_offer_path = "/quickOffer/createOffer";

const verifyQuickOfferUser = (data) => {
  return new Observable((observer) => {
    offerAxiosInstance
      .post(quick_offer_path, data)
      .then((resp) => {
        observer.next(resp.data);
        observer.complete();
      })
      .catch((err) => {
        console.log(err);
        observer.error(err);
        observer.complete();
      });
  });
};

export const OfferService = {
  verifyQuickOfferUser,
};
