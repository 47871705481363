import React, { Component } from "react";
import styles from "./institutions.module.scss";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import ContractedServicesImage from "../../assets/images/contracted-services.jpg";
import HealthInstutionsImage from "../../assets/images/health-instutions.jpg";

class Institutions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const t = this.props.t;
    return (
      <div className={styles.container}>
        <div
          className={`${styles.contractedMainTitleContainer} mobile-title-font-size`}
        >
          {t("institutions.contracted_title")}
        </div>
        <div className={styles.mainContainer}>
          <Link
            to={`${this.props.match.path}/anlasmali-servisler`}
            className={styles.contractedServicesContainer}
          >
            <div
              className={styles.contractedLogoContainer}
              style={{
                backgroundImage: `url(${ContractedServicesImage})`,
              }}
            >
              <div className={styles.contractedTitleContainer}>
                {t("home.contracted_services_title")}
              </div>
            </div>
          </Link>
          {/* <Link
            to={`${this.props.match.path}/saglik-kurumlari`}
            className={styles.healthInstitutionsContainer}
          >
            <div
              className={styles.contractedLogoContainer}
              style={{
                backgroundImage: `url(${HealthInstutionsImage})`,
              }}
            >
              <div className={styles.contractedTitleContainer}>
                {t("home.health_institutions_title")}
              </div>
            </div>
          </Link> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Institutions));
