import axios from "axios";
import { AuthorizationService } from "../components/services/authorization";

const { REACT_APP_BASE_URL } = process.env;

const axiosInstance = axios.create({
  baseURL: REACT_APP_BASE_URL,
});

export const AxiosInstance = () => {
  axiosInstance.interceptors.request.use(
    (req) => {
      req.headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "Dap-Token": AuthorizationService.getToken(),
        RequestId: Math.random().toString(36).substring(4),
        "Accept-Language": "tr-TR",
      };
      return req;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    (error) => {
      console.log(error);
    }
  );

  return null;
};

export default axiosInstance;
