import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import AS from "../../../../lib/ASLibrary";
import { AuthorizationContext } from "../../../contexts/AuthorizationContext";
import CustomSelect from "../../../helpers/inputs/select/select";
import { ApiService } from "../../../services/ApiService";
import styles from "./childproducts.module.scss";
import _lodash from "lodash";
import MetaDecorator from "../../../../util/MetaDecorator";
import {
  handleProductMetaDesc,
  handleProductMetaTitle,
} from "../product-meta-info";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

const webProductSize = Array.from({ length: 100 }, (_, i) => i);

class ChildProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parent: undefined,
      items: [],
      expanded: false,
      value: 0,
      selectedItem: undefined,
      isContinueProcess: true,
      noContent: false,
      style: {
        transform: "translate(0px, 15%)",
        opacity: 0,
      },
    };
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    if (!this.props.location.state && !window.location.href.includes("?")) {
      ApiService.getDapWebProductsByParentProduct("parent").subscribe(
        (resp) => {
          let parentProducts = [];
          let otherProducts = [];
          resp.forEach((item) => {
            if (parentProducts.length < 7) {
              item.name = this.handleLink(item.name);
              parentProducts.push(item);
            } else {
              item.otherProductPath = `diger-urunler/${this.handleLink(
                item.name
              )}`;
              otherProducts.push(item);
            }
          });

          if (this.props.match.params.parentProductId) {
            let isParentProduct = false;
            let otherProduct = true;
            parentProducts.forEach((parentProduct) => {
              if (
                parentProduct.name === this.props.match.params.parentProductId
              ) {
                this.setState({ isParentProduct: true });
                isParentProduct = true;
                otherProduct = false;
                this.props.location.state = { otherProducts: false };
                this.getWebProduct(parentProduct.id);
              }
            });
            if (otherProduct) {
              otherProducts.forEach((parentProduct) => {
                if (
                  parentProduct.otherProductPath ===
                  `${this.props.match.params.parentProductId}/${this.props.match.params.childProductId}`
                ) {
                  isParentProduct = true;
                  this.setState({ isParentProduct: true });
                  this.props.location.state = { otherProducts: true };
                  this.getWebProduct(parentProduct.id, true);
                }
              });
            }
            if (!isParentProduct) {
              this.props.history.push("/not-found");
            }
          }
        }
      );
    } else if (window.location.href.includes("?")) {
      this.handlePreviewChildWebProducts();
    } else {
      this.getWebProduct();
    }
    this.setState({
      style: {
        transform: "translate(0px, 0px)",
        opacity: 1,
        transition: "transform .3s, opacity .3s",
      },
    });
  }

  handlePreviewChildWebProducts = () => {
    let location = window.location.href;
    let locationInfoList = location.split("?");
    ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
      let mainLocation = locationInfoList[0] + "?" + resp;
      let url = new URL(
        decodeURI(mainLocation.replace(/\+/g, "%20"))
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
      );
      let urlParams;
      let match,
        pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, " ")),
        query = url.href;
      urlParams = {};
      while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
      }
      let previewChildWebProducts = [];
      for (let counter of webProductSize) {
        let webProduct = {};
        if (
          urlParams[`wp${counter}id`] == this.props.match.params.parentProductId
        ) {
          this.handleParentPreview(urlParams, counter);
        }
        if (urlParams[`wp${counter}ppid`] == 0) {
          continue;
        }
        if (
          urlParams[`wp${counter}ppid`] !=
          this.props.match.params.parentProductId
        ) {
          continue;
        }
        webProduct.id = urlParams[`wp${counter}id`];
        webProduct.photoUrl = urlParams[`wp${counter}photo`];
        webProduct.name = urlParams[`wp${counter}name`];
        webProduct.enterpriseAppPath = urlParams[`wp${counter}eap`];
        webProduct.pathSelector = urlParams[`wp${counter}ps`];
        webProduct.exclusionPathSelector = urlParams[`wp${counter}eps`];
        previewChildWebProducts.push(webProduct);
      }
      let firstItem =
        previewChildWebProducts.length > 0
          ? previewChildWebProducts[0]
          : undefined;
      this.setState(
        {
          items: [...previewChildWebProducts],
          selectedItem: firstItem,
          noContent: previewChildWebProducts.length === 0,
        },
        () => {
          this.getArticles();
        }
      );
    });
  };

  handleParentPreview = (urlParams, counter) => {
    let webProduct = {};
    webProduct.id = urlParams[`wp${counter}id`];
    webProduct.photoUrl = urlParams[`wp${counter}photo`];
    webProduct.name = urlParams[`wp${counter}name`];
    webProduct.enterpriseAppPath = urlParams[`wp${counter}eap`];
    webProduct.pathSelector = urlParams[`wp${counter}ps`];
    webProduct.exclusionPathSelector = urlParams[`wp${counter}eps`];
    this.setState({
      parent: webProduct,
    });
  };

  getWebProductsByParentWebProduct = (
    inputId = undefined,
    isOtherProduct = undefined
  ) => {
    let id;
    if (inputId) {
      id = inputId;
    } else {
      id = this.props.location.state.id;
    }
    ApiService.getDapWebProductsByParentProduct(id)
      .subscribe((resp) => {
        let item;
        if (
          this.props.match.params.childProductId &&
          !isOtherProduct &&
          resp.length > 0
        ) {
          resp.forEach((childProduct) => {
            if (
              this.handleLink(childProduct.name) ===
              this.props.match.params.childProductId
            ) {
              item = childProduct;
            }
          });
          if (!item) {
            this.props.history.push("/not-found");
            return;
          }
        } else if (
          this.props.match.params.otherProduct &&
          isOtherProduct &&
          resp.length > 0
        ) {
          resp.forEach((otherProduct) => {
            if (
              this.handleLink(otherProduct.name) ===
              this.props.match.params.otherProduct
            ) {
              item = otherProduct;
            }
          });
          if (!item) {
            this.props.history.push("/not-found");
            return;
          }
        } else {
          item = resp.length > 0 ? resp[0] : undefined;
        }
        this.setState(
          {
            items: [...resp],
            selectedItem: item,
          },
          () => {
            if (!isOtherProduct && resp.length > 0) {
              this.props.history.push({
                pathname: `/urunler/${
                  this.props.match.params.parentProductId
                }/${this.handleLink(item.name)}`,
                state: { otherProducts: false },
              });
              this.setState({ isContinueProcess: false });
            } else if (resp.length > 0) {
              this.props.history.push({
                pathname: `/urunler/${
                  this.props.match.params.parentProductId
                }/${this.props.match.params.childProductId}/${this.handleLink(
                  item.name
                )}`,
                state: { otherProducts: true },
              });
            }
            this.getArticles();
          }
        );
      })
      .add(() => {
        if (this.state.items.length === 0) {
          this.getParentProductArticle(isOtherProduct);
        }
      });
  };

  getParentProductArticle = (isOtherProduct) => {
    let parent = { ...this.state.parent };
    ApiService.getArticle(
      parent.enterpriseAppPath,
      parent.pathSelector,
      parent.exclusionPathSelector
    ).subscribe(
      (resp) => {
        this.handleParentArticle(resp);
      },
      (err) => {
        if (this.props.match.params.childProductId && !isOtherProduct) {
          this.props.history.push("/not-found");
          return;
        } else if (this.props.match.params.otherProduct) {
          this.props.history.push("/not-found");
          return;
        }
        this.setState({ noContent: true });
      },
      () => {
        parent.article = "İçerik Bulunamadı.";
        this.setState({
          parent: { ...parent },
        });
      }
    );
  };

  handleParentArticle = (innerHtml) => {
    let items = [];
    let item;
    items = this.handleHeaders(innerHtml, items);
    items = this.handleImages(innerHtml, items);
    items = this.handleContents(innerHtml, items);
    if (this.props.match.params.otherProduct && items.length > 0) {
      items.forEach((otherProduct) => {
        if (
          this.handleLink(otherProduct.name) ===
          this.props.match.params.otherProduct
        ) {
          item = otherProduct;
        }
      });
      if (!item) {
        this.props.history.push("/not-found");
        return;
      }
    } else if (
      !this.props.location.state.otherProducts &&
      this.props.match.params.childProductId &&
      !this.props.match.params.otherProduct &&
      items.length > 0
    ) {
      items.forEach((otherProduct) => {
        if (
          this.handleLink(otherProduct.name) ===
          this.props.match.params.childProductId
        ) {
          item = otherProduct;
        }
      });
      if (!item) {
        this.props.history.push("/not-found");
        return;
      }
    } else {
      item = items.length > 0 ? items[0] : undefined;
    }
    this.setState({
      items: [...items],
      selectedItem: item,
    });
    if (!this.props.location.state.otherProducts && items.length > 0) {
      this.props.history.push({
        pathname: `/urunler/${
          this.props.match.params.parentProductId
        }/${this.handleLink(item.name)}`,
        state: { otherProducts: false },
      });
    } else if (items.length > 0) {
      this.props.history.push({
        pathname: `/urunler/${this.props.match.params.parentProductId}/${
          this.props.match.params.childProductId
        }/${this.handleLink(item.name)}`,
        state: { otherProducts: true },
      });
    }
    this.setState({ isContinueProcess: false });
  };

  handleHeaders = (innerHtml, items) => {
    let headers = innerHtml.match(/<h2>(.*?)<\/h2>/g) || [];
    for (let i = 0; i < headers.length; i++) {
      headers[i] = headers[i].slice(4, headers[i].length - 5);
    }
    headers.forEach((header, index) => {
      let item = {};
      item.id = index + 1;
      item.name = header;
      item.enterpriseAppPath = this.state.parent.enterpriseAppPath;
      items.push(item);
    });
    return items;
  };

  handleImages = (innerHtml, items) => {
    let images = innerHtml.match(/<img src="(.*?)">/g) || [];
    for (let i = 0; i < images.length; i++) {
      images[i] = images[i].slice(10, images[i].length - 2);
    }
    images.forEach((image, index) => {
      items[index].image = image;
    });
    return items;
  };

  handleContents = (innerHtml, items) => {
    let contents = innerHtml.match(/<p>(.*?)<\/p>/g) || [];
    contents.forEach((content, index) => {
      items[index].article = content;
    });
    return items;
  };

  getArticles = () => {
    this.state.items.forEach((item) => {
      ApiService.getArticle(
        item.enterpriseAppPath,
        item.pathSelector,
        item.exclusionPathSelector
      ).subscribe(
        (resp) => {
          item.article = resp;
          if (resp === "") {
            item.article = "İçerik Yok.";
          }
          if (resp.includes('<img src="i')) {
            item.article = resp.replace(
              /<img src="i/g,
              '<img src="https://www.anadolusigorta.com.tr/i'
            );
          }
          let index = this.state.items.findIndex(
            (childProduct) => childProduct.id === item.id
          );
          let updatedItems = [...this.state.items];
          updatedItems[index] = { ...item };
          this.setState({
            items: [...updatedItems],
          });
        },
        () => {
          item.article = "İçerik Bulunamadı.";
          let index = this.state.items.findIndex(
            (childProduct) => childProduct.id === item.id
          );
          let updatedItems = [...this.state.items];
          updatedItems[index] = { ...item };
          this.setState({
            items: [...updatedItems],
          });
        }
      );
    });
  };

  getWebProduct = (inputId = undefined, isOtherProduct = undefined) => {
    let id;
    if (inputId) {
      id = inputId;
    } else {
      id = this.props.location.state.id;
      isOtherProduct = this.props.location.state.otherProducts;
    }
    ApiService.getDapWebProduct(id).subscribe(
      (resp) => {
        this.setState(
          {
            parent: resp,
          },
          () => {
            if (isOtherProduct) {
              this.getWebProductsByParentWebProduct(id, isOtherProduct);
            } else {
              this.getWebProductsByParentWebProduct(id);
            }
          }
        );
      },
      (err) => {
        this.props.history.push("/not-found");
      }
    );
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  handleInfoButtonNavigation = () => {
    this.props.history.push("/satis");
  };

  handleValueChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  handleParentProductNavigation = () => {
    this.props.history.push("/urunler");
  };

  handleLink = (name) => {
    return name
      .toLowerCase()
      .replace(/[()-/,]/g, "")
      .replace(/\s+/g, "-")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u");
  };

  selectItem = (item) => {
    let self = this;
    this.setState(
      {
        selectedItem: item,
        style: {
          transform: "translate(0px, 15%)",
          opacity: 0,
        },
      },
      () =>
        setTimeout(
          () =>
            self.setState({
              style: {
                transform: "translate(0px, 0px)",
                opacity: 1,
                transition: "transform .3s, opacity .3s",
              },
            }),
          100
        )
    );
  };

  handleMetaTitle = () => {
    return handleProductMetaTitle(this.context.akyAgency, window.location.href);
  };

  handleMetaDesc = () => {
    return handleProductMetaDesc(this.context.akyAgency, window.location.href);
  };

  render() {
    const t = this.props.t;

    return (
      <>
        <MetaDecorator
          title={this.handleMetaTitle()}
          description={this.handleMetaDesc()}
        />
        <div className={styles.container}>
          {/* {!this.state.isContinueProcess && ( */}
          <div className={styles.childProductsWrapper}>
            <div className={styles.titleContainer}>
              <div
                className={`${styles.productTitleLink} ${styles.marginRight}`}
                onClick={() => this.handleParentProductNavigation()}
              >
                <div className={styles.productTitle}>
                  {t("child_products.main_path")} {" >"}
                </div>
              </div>
              <div className={styles.margin}>
                {this.state.parent ? `${this.state.parent.name} >` : null}
              </div>
              <div className={styles.margin}>
                {this.state.selectedItem ? this.state.selectedItem.name : null}
              </div>
            </div>
            <div class="only-on-mobile">
              {!window.location.href.includes("?") &&
              this.props.location.state &&
              !this.props.location.state.otherProducts &&
              this.state.selectedItem
                ? this.state.selectedItem.id && (
                    <>
                      <CustomSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedItem?.id}
                      >
                        {this.state.items.map((item) => {
                          return (
                            <AS.MenuItem
                              component={Link}
                              value={item.id}
                              className={styles.menuItem}
                              onClick={() => this.selectItem(item)}
                              to={{
                                pathname: `/urunler/${
                                  this.props.match.params.parentProductId
                                }/${this.handleLink(item.name)}`,
                                state: { otherProducts: false },
                              }}
                              style={{
                                textDecoration: "none",
                                color: "#3b3b3b",
                              }}
                            >
                              {item.name}
                            </AS.MenuItem>
                          );
                        })}
                      </CustomSelect>
                    </>
                  )
                : null}
              {!window.location.href.includes("?") &&
              this.props.location.state &&
              this.props.location.state.otherProducts &&
              this.state.selectedItem
                ? this.state.selectedItem.id && (
                    <>
                      <CustomSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedItem?.id}
                      >
                        {this.state.items.map((item) => {
                          return (
                            <AS.MenuItem
                              component={Link}
                              value={item.id}
                              className={styles.menuItem}
                              onClick={() => this.selectItem(item)}
                              to={{
                                pathname: `/urunler/${
                                  this.props.match.params.parentProductId
                                }/${
                                  this.props.match.params.childProductId
                                }/${this.handleLink(item.name)}`,
                                state: { otherProducts: true },
                              }}
                              style={{
                                textDecoration: "none",
                                color: "#3b3b3b",
                              }}
                            >
                              {item.name}
                            </AS.MenuItem>
                          );
                        })}
                      </CustomSelect>
                    </>
                  )
                : null}
              {window.location.href.includes("?") && this.state.selectedItem
                ? this.state.selectedItem.id && (
                    <>
                      <CustomSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedItem?.id}
                      >
                        {this.state.items.map((item) => {
                          return (
                            <AS.MenuItem
                              value={item.id}
                              className={styles.menuItem}
                              onClick={() => this.selectItem(item)}
                            >
                              {item.name}
                            </AS.MenuItem>
                          );
                        })}
                      </CustomSelect>
                    </>
                  )
                : null}
              {this.state.noContent && (
                <h1
                  style={{
                    color: "#3b3b3b",
                    textAlign: "left",
                    direction: "ltr",
                  }}
                >
                  {t("child_products.not_found_child_product")}
                </h1>
              )}
            </div>
            <div
              className={`${styles.childProductsContainer} ${
                !this.state.selectedItem?.photoUrl &&
                !this.state.selectedItem?.image
                  ? styles.spaceNormal
                  : null
              }`}
            >
              <div className={`${styles.childProducts} only-not-small-screen`}>
                {!window.location.href.includes("?") &&
                  this.state.items.length > 0 &&
                  this.props.location.state &&
                  !this.props.location.state.otherProducts &&
                  this.state.items.map((item) => {
                    return (
                      <Link
                        className={styles.childProductsTitle}
                        onClick={() => this.selectItem(item)}
                        to={{
                          pathname: `/urunler/${
                            this.props.match.params.parentProductId
                          }/${this.handleLink(item.name)}`,
                          state: { otherProducts: false },
                        }}
                        style={{
                          textDecoration: "none",
                          color:
                            item.name === this.state.selectedItem.name
                              ? "#0088e2"
                              : "#3b3b3b",
                        }}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                {!window.location.href.includes("?") &&
                  this.state.items.length > 0 &&
                  this.props.location.state &&
                  this.props.location.state.otherProducts &&
                  this.state.items.map((item) => {
                    return (
                      <Link
                        className={styles.childProductsTitle}
                        onClick={() => this.selectItem(item)}
                        to={{
                          pathname: `/urunler/${
                            this.props.match.params.parentProductId
                          }/${
                            this.props.match.params.childProductId
                          }/${this.handleLink(item.name)}`,
                          state: { otherProducts: true },
                        }}
                        style={{
                          textDecoration: "none",
                          color:
                            item.name === this.state.selectedItem.name
                              ? "#0088e2"
                              : "#3b3b3b",
                        }}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                {window.location.href.includes("?") &&
                  this.state.items.length > 0 &&
                  this.state.items.map((item) => {
                    return (
                      <div
                        className={styles.childProductsTitle}
                        onClick={() => this.selectItem(item)}
                      >
                        <p
                          style={{
                            color:
                              item.name === this.state.selectedItem.name
                                ? "#0088e2"
                                : "",
                          }}
                        >
                          {item.name}
                        </p>
                      </div>
                    );
                  })}
                {this.state.noContent && (
                  <h1
                    style={{
                      color: "#3b3b3b",
                      textAlign: "left",
                      direction: "ltr",
                    }}
                  >
                    {t("child_products.not_found_child_product")}
                  </h1>
                )}
              </div>
              <div
                className={`${styles.childProductsContentD} only-on-desktop`}
              >
                {this.state.selectedItem
                  ? this.state.selectedItem.article && (
                      <>
                        {!this.state.selectedItem.article.includes("<h2>") && (
                          <div
                            className={styles.contentTitle}
                            style={this.state.style}
                          >
                            <h2>{this.state.selectedItem.name}</h2>
                          </div>
                        )}
                        {this.state.selectedItem.article !== "İçerik Yok." ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.selectedItem.article,
                            }}
                            className={styles.articleContainer}
                            id={`article${this.state.selectedItem.id}`}
                            style={this.state.style}
                          />
                        ) : (
                          <div
                            className={styles.articleNotFoundContainer}
                            id={`article${this.state.selectedItem.id}`}
                            style={this.state.style}
                          />
                        )}
                        <div className={styles.detailsButtonsContainer}>
                          <div className={styles.linkContainer}>
                            <a
                              href={`https://www.anadolusigorta.com.tr/${this.state.selectedItem.enterpriseAppPath}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.detailedInfoLink}
                            >
                              {t("child_products.detail")}
                            </a>
                            <AS.ArrowRightAltIcon className={styles.linkIcon} />
                          </div>
                        </div>
                      </>
                    )
                  : null}
              </div>
              {this.state.selectedItem ? (
                this.state.selectedItem.photoUrl ? (
                  <>
                    <div className={styles.imageWrapper}>
                      <div className={styles.imgContainer}>
                        <img
                          className={styles.img}
                          style={this.state.style}
                          src={`${BASE_URL}${this.state.selectedItem.photoUrl}`}
                        />
                      </div>
                    </div>
                  </>
                ) : this.state.selectedItem.image ? (
                  <>
                    <div className={styles.imageWrapper}>
                      <div className={styles.imgContainer}>
                        <img
                          className={styles.img}
                          style={this.state.style}
                          src={`https://www.anadolusigorta.com.tr/${this.state.selectedItem.image}`}
                        />
                      </div>
                    </div>
                  </>
                ) : null
              ) : null}
            </div>
            <div class="only-not-large-screen">
              <div className={styles.childProductsContentM}>
                {this.state.selectedItem
                  ? this.state.selectedItem.article && (
                      <>
                        {!this.state.selectedItem.article.includes("<h2>") && (
                          <div
                            className={`${styles.contentTitle} ${
                              this.state.selectedItem?.photoUrl
                                ? styles.borderTop
                                : ""
                            }`}
                            style={this.state.style}
                          >
                            <h2>{this.state.selectedItem.name}</h2>
                          </div>
                        )}
                        {this.state.selectedItem.article !== "İçerik Yok." ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.selectedItem.article,
                            }}
                            className={styles.articleContainer}
                            id={`article${this.state.selectedItem.id}`}
                            style={this.state.style}
                          />
                        ) : (
                          <div
                            className={styles.articleNotFoundContainer}
                            id={`article${this.state.selectedItem.id}`}
                            style={this.state.style}
                          />
                        )}
                        <div className={styles.detailsButtonsContainer}>
                          <div className={styles.linkContainer}>
                            <a
                              href={`https://www.anadolusigorta.com.tr/${this.state.selectedItem.enterpriseAppPath}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={styles.detailedInfoLink}
                            >
                              {t("child_products.detail")}
                            </a>
                            <AS.ArrowRightAltIcon className={styles.linkIcon} />
                          </div>
                        </div>
                      </>
                    )
                  : null}
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(ChildProducts));
