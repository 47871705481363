import _lodash from "lodash";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import AS from "../../../lib/ASLibrary";
import MetaDecorator from "../../../util/MetaDecorator";
import ContractedServicesImage from "../../assets/images/contracted-services.jpg";
import HealthInstutionsImage from "../../assets/images/health-instutions.jpg";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import ProductCards from "../../helpers/cards/productcards/productcards";
import CarouselHelper from "../../helpers/carousel/carousel";
import MessageWithCloseBtn from "../../helpers/dialogs/messagewithclosebtn/messagewithclosebtn";
import SaleTabHelper from "../../helpers/tabs/sale-tab/saletab";
import { ApiService } from "../../services/ApiService";
import { AuthorizationService } from "../../services/authorization";
import styles from "./home.module.scss";
import { HOME_DEFAULT } from "./model";

const bannerSize = Array.from({ length: 4 }, (_, i) => i);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselItems: [],
      multipleCardsItems: [],
      datasource: {
        model: _lodash.cloneDeep(HOME_DEFAULT),
      },
    };
    this.productsRef = React.createRef();
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    AuthorizationService.token.subscribe((token) => {
      if (token) {
        if (
          !window.location.href.includes("?") ||
          window.location.href.includes("/agency-view")
        ) {
          this.getWebsiteBanners();
          this.getWebProducts();
        }
      }
    });
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource = this.setDefaults();
        return { datasource };
      },
      () => {
        if (window.location.href.includes("?")) {
          if (window.location.href.includes("satis-sonuc")) {
            return this.handleSaleResultUrl();
          }
          if (!window.location.href.includes("/agency-view")) {
            this.handlePreviewBanners();
            this.getWebProducts();
          }
        }
      }
    );
  }
  componentDidUpdate() {
    const offerButtonScroll = this.context.offerButtonScroll;
    const updateOfferButtonScroll = this.context.updateOfferButtonScroll;
    if (offerButtonScroll === "home") {
      setTimeout(() => {
        this.productsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 50);
      setTimeout(() => {
        updateOfferButtonScroll("");
      }, 500);
    }
  }

  setDefaults = () => {
    let datasource = _lodash.cloneDeep(this.state.datasource);
    datasource.model = _lodash.cloneDeep(HOME_DEFAULT);
    datasource.model.webProductsConfig.functions.navigationHandler =
      this.navigationHandler;
    datasource.model.messageWithCloseBtnDialogConfig.functions.closeDialog =
      this.closeMessageDialog;
    return datasource;
  };

  closeMessageDialog = () => {
    this.setState((prevState) => {
      let datasource = { ...prevState.datasource };
      datasource.model.messageWithCloseBtnDialogConfig.datasource.dialogShow = false;
      return { datasource };
    });
  };

  handleSaleResultUrl = () => {
    let urlParams = new URLSearchParams(window.location.search);
    let message = urlParams.get("message");
    if (urlParams.get("status") == "success") {
      message = `${urlParams.get(
        "message"
      )}' nolu poliçeniz başarıyla oluşturulmuştur.`;
    } else if (urlParams.get("status") == null) {
      let status = window.location.href.substring(
        window.location.href.indexOf("status=") + 7,
        window.location.href.indexOf("&message") - 1
      );
      if (status) {
        message = `${urlParams.get(
          "message"
        )} nolu poliçeniz başarıyla oluşturulmuştur.`;
      }
    }
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.messageWithCloseBtnDialogConfig.datasource.message =
          message;
        datasource.model.messageWithCloseBtnDialogConfig.datasource.dialogShow = true;
        return { datasource };
      },
      () => {
        this.getWebsiteBanners();
        this.getWebProducts();
      }
    );
  };

  handlePreviewBanners = () => {
    let location = window.location.href;
    let locationInfoList = location.split("?");
    ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
      let mainLocation = locationInfoList[0] + "?" + resp;
      let url = new URL(
        decodeURI(mainLocation.replace(/\+/g, "%20"))
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#039;/g, "'")
      );
      let urlParams;
      let match,
        pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, " ")),
        query = url.href;
      urlParams = {};
      while ((match = search.exec(query))) {
        urlParams[decode(match[1])] = decode(match[2]);
      }
      if (!urlParams[`banners0photourl`]) {
        return this.getDefaultBanners();
      }
      let counter = 0;
      let previewBanners = [];
      bannerSize.forEach(() => {
        let banner = {};
        banner.id = `banner${counter}`;
        if (!urlParams[`banners${counter}photourl`]) {
          return;
        }
        banner.photoUrl = urlParams[`banners${counter}photourl`];
        banner.title = urlParams[`banners${counter}title`];
        banner.content = urlParams[`banners${counter}content`];
        previewBanners.push(banner);
        counter = counter + 1;
      });
      this.setState({
        carouselItems: [...previewBanners],
      });
    });
  };

  getDefaultBanners = () => {
    ApiService.getSelectedGlobalBanners("web").subscribe((resp) => {
      this.setState({
        carouselItems: [...resp],
      });
    });
  };

  getWebsiteBanners = () => {
    ApiService.getSelectedBannersByType("web").subscribe((res) => {
      this.setState({
        carouselItems: [...res],
      });
    });
  };

  getWebProducts = () => {
    ApiService.getWebProducts().subscribe((resp) => {
      let parentProducts = [];
      let otherProducts = [];
      let childProducts = [];
      resp.content.forEach((item) => {
        if (item.parentProductId === 0) {
          if (parentProducts.length < 4) {
            parentProducts.push(item);
          } else {
            otherProducts.push(item);
          }
        } else {
          childProducts.push(item);
        }
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.webProductsConfig.datasource.parentProducts = [
          ...parentProducts,
        ];
        datasource.model.webProductsConfig.datasource.otherProducts = [
          ...otherProducts,
        ];
        datasource.model.webProductsConfig.datasource.childProducts = [
          ...childProducts,
        ];
        return { datasource };
      });
    });
  };

  handleProductButton = () => {
    this.props.history.push("/urunler");
  };

  handleContractedInstitutionsButton = () => {
    this.props.history.push("/kurumlar");
  };

  handleContractedServices = () => {
    this.props.history.push("/kurumlar/anlasmali-servisler");
  };

  handleHealthInstitutions = () => {
    this.props.history.push("/kurumlar/saglik-kurumlari");
  };

  navigationHandler = (id) => {
    this.props.history.push(`/urunler/${id}`);
  };

  render() {
    const t = this.props.t;
    const akyAgency = this.context.akyAgency;
    const preview = this.context.preview;
    return (
      <div className={styles.container}>
        <MetaDecorator
          title={`${akyAgency.addressInfo?.provinceName} ${akyAgency.agencyTitle} | Anadolu Sigorta`}
          description={this.state.datasource.model.helmetConfig.description}
        />
        <div className={styles.carouselMainContainer}>
          <div className={styles.carouselContainer1}>
            <CarouselHelper items={this.state.carouselItems} />
            <div class="custom-shape-divider-bottom-1618394262">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                  class={
                    akyAgency.ecommerceInfo?.salePermissionExist
                      ? "shape-fill-sale"
                      : "shape-fill"
                  }
                ></path>
              </svg>
            </div>
          </div>
        </div>
        {akyAgency.ecommerceInfo?.salePermissionExist && (
          <div
            className={styles.saleMainContainer}
            id="homeTabHelper"
            ref={this.productsRef}
            aria-expanded={preview}
          >
            <SaleTabHelper />
          </div>
        )}
        <div className={styles.productCardsMainContainer}>
          <div className={styles.productCardsContentArea}>
            <div className={styles.productCardsContainer}>
              <ProductCards
                config={this.state.datasource.model.webProductsConfig}
              />
            </div>
            <div className={styles.productCardsInfoContainer}>
              <div
                className={`${styles.productCardsInfoTitle} mobile-title-font-size`}
              >
                {t("home.product_title")}
              </div>
              <div
                className={`${styles.productCardsInfoContent} mobile-content-font-size`}
              >
                {t("home.product_content")}
              </div>
              <div className={styles.productCardsInfoButtonContainer}>
                <div
                  className={styles.mainButtonContainer}
                  onClick={() => this.handleProductButton()}
                >
                  <div className={styles.buttonTextContainer}>
                    {t("home.product_button")}
                  </div>
                  <div className={styles.buttonIconContainer}>
                    <AS.ArrowRightAltIcon className={styles.buttonIcon} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.damageAssistanceContainer}>
          <div
            className={`${styles.damagaeAssistanceTitleContainer} mobile-title-font-size`}
          >
            {t("home.damage_assistance_title")}
          </div>
          <div
            className={`${styles.damagaeAssistanceContentContainer} mobile-content-font-size`}
          >
            {t("home.damage_assistance_content")}
          </div>
          <div className={styles.damageAssistanceGuideButtonContainer}>
            <a
              href="https://www.anadolusigorta.com.tr/tr/hasar-yardim"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.damageLink}
            >
              <AS.Button className={styles.guideButton}>
                {t("home.damage_assistance_button")}
              </AS.Button>
            </a>
          </div>
        </div>
        <div className={styles.contractedInstitutionsMainContainer}>
          <div className={styles.contractedInstitutionsContainer}>
            <div className={styles.contractedInstitutionsInfoContainer}>
              <div
                className={`${styles.contractedInstitutionsInfoTitle} mobile-title-font-size`}
              >
                {t("home.contracted_institutions_info_title")}
              </div>
              <div
                className={`${styles.contractedInstitutionsInfoContent} mobile-content-font-size`}
              >
                {t("home.contracted_institutions_info_content")}
              </div>
              <div className={styles.contractedInstitutionsButtonContainer}>
                <div
                  className={`${styles.mainButtonContainer} ${styles.contractedInstButtonContainer}`}
                  onClick={() => this.handleContractedInstitutionsButton()}
                >
                  <div className={styles.buttonTextContainer}>
                    {t("home.product_button")}
                  </div>
                  <div className={styles.buttonIconContainer}>
                    <AS.ArrowRightAltIcon className={styles.buttonIcon} />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.servicesContainer}>
              <div
                className={styles.contractedServicesContainer}
                onClick={() => this.handleContractedServices()}
              >
                <div
                  className={styles.contractedLogoContainer}
                  style={{
                    backgroundImage: `url(${ContractedServicesImage})`,
                  }}
                >
                  <div className={styles.contractedTitleContainer}>
                    {t("home.contracted_services_title")}
                  </div>
                </div>
              </div>
              {/* <div
                className={styles.healthInstitutionsContainer}
                onClick={() => this.handleHealthInstitutions()}
              >
                <div
                  className={styles.contractedLogoContainer}
                  style={{
                    backgroundImage: `url(${HealthInstutionsImage})`,
                  }}
                >
                  <div className={styles.contractedTitleContainer}>
                    {t("home.health_institutions_title")}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <MessageWithCloseBtn
          config={this.state.datasource.model.messageWithCloseBtnDialogConfig}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(Home));
