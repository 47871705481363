import React from "react";
import { withTranslation } from "react-i18next";
import styles from "./notfound.module.scss";
import { useTranslation } from "react-i18next";
import AS from "../../../lib/ASLibrary";

const NotFound = () => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.container}>
      <div className={styles.containerBackground}></div>
      <div className={styles.messageContainer}>
        <div className={styles.title}>{t("not_found.title")}</div>
        <div className={`${styles.contentContainer} ${!window.location.href.includes("anadolusigorta") ? styles.margin : ''}`}>
          <div className={`${styles.content} mobile-content-font-size`}>{t("not_found.content")}</div>
           {!window.location.href.includes("anadolusigorta") && (
            <div className={styles.buttonWrapper}>
              <a
                className={styles.mainButtonContainer}
                href="/"
              >
                <div className={`${styles.buttonTextContainer} mobile-content-font-size`}>
                  {t("not_found.return_home")}
                </div>
                <div className={styles.buttonIconContainer}>
                  <AS.ArrowRightAltIcon className={styles.buttonIcon} />
                </div>
              </a>
            </div>
           )}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(NotFound);
