import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import MetaDecorator from "../../../../util/MetaDecorator";
import _lodash from "lodash";
import { PRODUCT_DEFAULT } from "../model";
import Products from "../products";
import ChildProducts from "../childproducts/childproducts";
import { AuthorizationContext } from "../../../contexts/AuthorizationContext";

class ProductsMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        helmetConfig: _lodash.cloneDeep(PRODUCT_DEFAULT.helmetConfig),
      },
    };
  }

  static contextType = AuthorizationContext;

  render() {
    const akyAgency = this.context.akyAgency;
    return (
      <>
        <MetaDecorator
          title={`${akyAgency.agencyTitle} Tüm Ürünler | Anadolu Sigorta`}
          description={this.state.datasource.helmetConfig.description}
        />
        <Switch>
          <Route exact path={`${this.props.match.path}`} component={Products} />
          <Route
            exact
            path={`${this.props.match.path}/:parentProductId?/:childProductId?/:otherProduct?`}
            component={ChildProducts}
          />
        </Switch>
      </>
    );
  }
}

export default withRouter(ProductsMain);
