import React, { Component } from "react";
import styles from "./footer.module.scss";
import { AuthorizationContext } from "../contexts/AuthorizationContext";
import logo from "../assets/images/as-logo.png";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import Contact from "../pages/contact/contact";
import PulseButtonHelper from "../helpers/buttons/pulse/pulse";
import { ApiService } from "../services/ApiService";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAboutItem: undefined,
      isPhoto: undefined,
      isPrize: undefined,
    };
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    this.getAboutItem();
    this.getPhotos();
    this.getPrizes();
  }

  getAboutItem = () => {
    ApiService.getAbout("about").subscribe((resp) => {
      this.setState({
        isAboutItem: resp.content.length !== 0,
      });
    });
  };

  getPhotos = () => {
    ApiService.getAbout("photo").subscribe((resp) => {
      this.setState({
        isPhoto: resp.content.length !== 0,
      });
    });
  };

  getPrizes = () => {
    ApiService.getAbout("prize").subscribe((resp) => {
      this.setState({
        isPrize: resp.content.length !== 0,
      });
    });
  };

  handleOpenMail = (mail) => {
    window.open(`mailto:${mail}`);
  };

  handlePhoneCall = (phone) => {
    window.open(`tel:${phone}`);
  };

  handleAddressInfo = (addressInfo) => {
    let quarterName = addressInfo.quarterName
      ? `${addressInfo.quarterName} Mah. `
      : null;
    let streetName = addressInfo.streetName
      ? `${addressInfo.streetName} Cad. `
      : null;
    let number = addressInfo.buildingNumber
      ? `No: ${addressInfo.buildingNumber} `
      : null;
    return `${quarterName}${streetName}${number}${addressInfo.townshipVillageName} ${addressInfo.districtName} ${addressInfo.provinceName}`;
  };

  handlePermission = () => {
    const akyAgency = this.context.akyAgency;
    let permission = true;
    if (!akyAgency.ecommerceInfo?.webPermissionExist) {
      permission = false;
    }
    if (
      window.location.href.includes("?") &&
      !window.location.href.includes("/agency-view")
    ) {
      permission = false;
    }
    return permission;
  };

  handleNavigation = () => {
    window.open("https://www.anadolusigorta.com.tr", "_blank");
  };

  handleAgencyLogoNavigation = () => {
    if (
      window.location.href.includes("?") &&
      !window.location.href.includes("/agency-view")
    ) {
      return;
    }
    if (this.context.permission) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/satis");
    }
  };

  render() {
    const dapAgency = this.context.dapAgency;
    const t = this.props.t;
    return (
      <div className={styles.footer}>
        {this.props.location.pathname !== "/iletisim" && <Contact />}
        <div className={styles.secondMainContainer}>
          <PulseButtonHelper />
          <div className={styles.secondContainer}>
            <div className={styles.sitemapContainer}>
              {this.handlePermission() && (
                <div className={styles.sitemapMainContainer}>
                  <div className={styles.sitemapItemTitleContainer}>
                    {t("footer.sitemap_corparate")}
                  </div>
                  {(this.state.isAboutItem ||
                    this.state.isPhoto ||
                    this.state.isPrize) && (
                    <div className={styles.sitemapItem}>
                      <Link to="/biz-kimiz" className={styles.sitemapLink}>
                        {t("footer.sitemap_about")}
                      </Link>
                    </div>
                  )}
                  <div className={styles.sitemapItem}>
                    <Link to="/urunler" className={styles.sitemapLink}>
                      {t("footer.sitemap_product")}
                    </Link>
                  </div>
                  <div className={styles.sitemapItem}>
                    <a
                      href="http://www.anadolusigorta.com.tr/tr/anadolu-hizmet"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.sitemapLink}
                    >
                      {t("footer.sitemap_anadolu_service")}
                    </a>
                  </div>
                </div>
              )}
              {this.handlePermission() && (
                <div className={styles.sitemapMainContainer}>
                  <div className={styles.sitemapItemTitleContainer}>
                    <Link to="/kurumlar" className={styles.sitemapTitleLink}>
                      {t("footer.sitemap_contracted_institutions")}
                    </Link>
                  </div>
                  <div className={styles.sitemapItem}>
                    <Link
                      to="/kurumlar/anlasmali-servisler"
                      className={styles.sitemapLink}
                    >
                      {t("footer.sitemap_auto_services")}
                    </Link>
                  </div>
                  {/* <div className={styles.sitemapItem}>
                    <Link
                      to="/kurumlar/saglik-kurumlari"
                      className={styles.sitemapLink}
                    >
                      {t("footer.sitemap_health_institutions")}
                    </Link>
                  </div> */}
                  <div className={styles.sitemapItem}>
                    <Link
                      to="/kurumlar/anlasmali-servisler"
                      className={styles.sitemapLink}
                    >
                      {t("footer.sitemap_glass_services")}
                    </Link>
                  </div>
                  <div className={styles.sitemapItem}>
                    <a
                      href="https://www.anadolusigorta.com.tr/tr/hasar-yardim"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.sitemapLink}
                    >
                      {t("footer.sitemap_damage")}
                    </a>
                  </div>
                  <div className={styles.sitemapItem}>
                    <Link
                      to="/kurumlar/anlasmali-servisler"
                      className={styles.sitemapLink}
                    >
                      {t("footer.sitemap_repair")}
                    </Link>
                  </div>
                </div>
              )}

              {!window.location.href.includes("?") ||
              window.location.href.includes("/agency-view") ? (
                <div className={styles.sitemapMainContainer}>
                  <div className={styles.sitemapItemTitleContainer}>
                    <Link to="/iletisim" className={styles.sitemapTitleLink}>
                      {t("footer.sitemap_contact")}
                    </Link>
                  </div>
                  <div className={styles.sitemapItem}>
                    <Link to="/iletisim" className={styles.sitemapLink}>
                      {t("footer.sitemap_contact_us")}
                    </Link>
                  </div>
                  <div className={styles.sitemapItem}>
                    <Link to="/iletisim" className={styles.sitemapLink}>
                      {t("footer.sitemap_contact_form")}
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.logosContainer}>
                <div className={styles.logoChild}>
                  <img
                    className={styles.logo}
                    src={logo}
                    alt="logo"
                    onClick={() => this.handleNavigation()}
                  />
                </div>
                {dapAgency.logoUrl && (
                  <>
                    <div className={styles.verticalLine}></div>
                    <div className={`${styles.logoChild} ${styles.agencyLogo}`}>
                      <img
                        className={styles.logo}
                        src={`${BASE_URL}${dapAgency.logoUrl}`}
                        alt="logo"
                        onClick={() => this.handleAgencyLogoNavigation()}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={styles.infoContainerAgencyNameContainer}>
                {dapAgency.name}
              </div>
              <div className={styles.infoContainerContent}>
                {t("footer.info_content")}
              </div>
            </div>
          </div>
          <div className={styles.rightsMainContainer}>
            <div className={styles.rightsContainer}>
              <div className={styles.rightItem}>
                <a
                  href="https://www.anadolusigorta.com.tr/tr/web-sitesi-kullanim-kosullari"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.rightItemLink}
                >
                  {t("footer.confidentiality_agreement")}
                </a>
              </div>
              <div className={styles.rightItem}>
                {`${dapAgency.name} `}
                &copy;
                {` ${new Date().getFullYear()} ${t(
                  "footer.all_rights_reserved"
                )}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Footer));
