import React, { Component } from "react";
import styles from "./contractedservices.module.scss";
import AS from "../../../../lib/ASLibrary";
import { withTranslation } from "react-i18next";
import { ApiService } from "../../../services/ApiService";
import _lodash from "lodash";
import { CONTRACTED_SERVICES_DEFAULT } from "./model";
import AutoServicesImage from "../../../assets/images/auto-services.jpg";
import MiniRepairServicesImage from "../../../assets/images/mini-repair-services.jpg";
import MetaDecorator from "../../../../util/MetaDecorator";
import { AuthorizationContext } from "../../../contexts/AuthorizationContext";

class ContractedServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: undefined,
      datasource: {
        model: _lodash.cloneDeep(CONTRACTED_SERVICES_DEFAULT),
      },
      services: [],
      visibleServices: [],
    };
    this.resultRef = React.createRef();
  }

  componentDidMount() {
    this.setState((prevState) => {
      let datasource = { ...prevState.datasource };
      datasource = this.setDefaults();
      return { datasource };
    });
    // this.getServiceTypes();
    // this.getMiniServicesCities();
  }

  static contextType = AuthorizationContext;

  setDefaults = () => {
    let datasource = _lodash.cloneDeep(this.state.datasource);
    datasource.model = _lodash.cloneDeep(CONTRACTED_SERVICES_DEFAULT);
    datasource.model.serviceTypesConfig.functions.handleChange =
      this.handleServiceTypeChange;
    datasource.model.citiesConfig.functions.handleChange =
      this.handleCityChange;
    datasource.model.countiesConfig.functions.handleChange =
      this.handleCountyChange;
    datasource.model.brandsConfig.functions.handleChange =
      this.handleBrandChange;
    datasource.model.miniRepair.citiesConfig.functions.handleChange =
      this.handleMiniRepairCityChange;
    datasource.model.miniRepair.countiesConfig.functions.handleChange =
      this.handleMiniRepairCountyChange;
    return datasource;
  };

  handleAccordion = (index) => {
    if (index === this.state.active) {
      index = -1;
    }
    this.setState({
      active: index,
      services: [],
      visibleServices: [],
    });
  };

  getMiniServicesCities = () => {
    ApiService.getMiniServicesCities().subscribe((resp) => {
      let cityList = [];
      resp.forEach((item) => {
        cityList.push({ id: item, name: item });
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.miniRepair.citiesConfig.datasource.items = [
          ...cityList,
        ];
        return { datasource };
      });
    });
  };

  getMiniRepairCounties = () => {
    ApiService.getMiniServicesCounties(
      this.state.datasource.model.miniRepair.citiesConfig.datasource
        .selectedItem
    ).subscribe((resp) => {
      let countyList = [];
      resp.forEach((item) => {
        countyList.push({ id: item, name: item });
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.miniRepair.countiesConfig.datasource.items = [
          ...countyList,
        ];
        return { datasource };
      });
    });
  };

  getServiceTypes = () => {
    ApiService.getServiceTypes().subscribe((resp) => {
      let typeList = [];
      resp.forEach((item) => {
        switch (item.serviceType) {
          case "CS":
            typeList.push({
              id: item.serviceType,
              name: "Anlaşmalı Oto Servis",
            });
            break;
          case "TOSI":
            typeList.push({
              id: item.serviceType,
              name: "Trafik Anlaşmalı Oto Servis",
            });
            break;
          case "SPS":
            typeList.push({
              id: item.serviceType,
              name: item.description,
            });
            break;

          default:
            break;
        }
        this.setState((prevState) => {
          let datasource = { ...prevState.datasource };
          datasource.model.serviceTypesConfig.datasource.items = [...typeList];
          return { datasource };
        });
      });
    });
  };

  getCities = () => {
    ApiService.getCities(
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem
    ).subscribe((resp) => {
      let cityList = [];
      resp.forEach((item) => {
        cityList.push({ id: item, name: item });
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.citiesConfig.datasource.items = [...cityList];
        return { datasource };
      });
    });
  };

  getCounties = () => {
    ApiService.getCounties(
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem,
      this.state.datasource.model.citiesConfig.datasource.selectedItem
    ).subscribe((resp) => {
      let countyList = [];
      resp.forEach((item) => {
        countyList.push({ id: item, name: item });
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.countiesConfig.datasource.items = [...countyList];
        return { datasource };
      });
    });
  };

  getBrands = () => {
    ApiService.getBrands(
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem,
      this.state.datasource.model.citiesConfig.datasource.selectedItem,
      this.state.datasource.model.countiesConfig.datasource.selectedItem
    ).subscribe((resp) => {
      let brandList = [];
      resp.forEach((item) => {
        brandList.push({ id: item, name: item });
      });
      this.setState((prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.brandsConfig.datasource.items = [...brandList];
        return { datasource };
      });
    });
  };

  handleServiceTypeChange = (e) => {
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.serviceTypesConfig.datasource.selectedItem =
          e.target.value;
        return { datasource };
      },
      () => {
        this.getCities();
      }
    );
  };

  handleCityChange = (e) => {
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.citiesConfig.datasource.selectedItem = e.target.value;
        return { datasource };
      },
      () => {
        this.getCounties();
      }
    );
  };

  handleMiniRepairCityChange = (e) => {
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.miniRepair.citiesConfig.datasource.selectedItem =
          e.target.value;
        return { datasource };
      },
      () => {
        this.getMiniRepairCounties();
      }
    );
  };

  handleCountyChange = (e) => {
    this.setState(
      (prevState) => {
        let datasource = { ...prevState.datasource };
        datasource.model.countiesConfig.datasource.selectedItem =
          e.target.value;
        return { datasource };
      },
      () => {
        this.getBrands();
      }
    );
  };

  handleMiniRepairCountyChange = (e) => {
    this.setState((prevState) => {
      let datasource = { ...prevState.datasource };
      datasource.model.miniRepair.countiesConfig.datasource.selectedItem =
        e.target.value;
      return { datasource };
    });
  };

  handleBrandChange = (e) => {
    this.setState((prevState) => {
      let datasource = { ...prevState.datasource };
      datasource.model.brandsConfig.datasource.selectedItem = e.target.value;
      return { datasource };
    });
  };

  disableSearchButton = () => {
    if (
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem &&
      this.state.datasource.model.citiesConfig.datasource.selectedItem
    ) {
      return false;
    }
    return true;
  };

  disableRepairSearchButton = () => {
    if (
      this.state.datasource.model.miniRepair.citiesConfig.datasource
        .selectedItem &&
      this.state.datasource.model.miniRepair.countiesConfig.datasource
        .selectedItem
    ) {
      return false;
    }
    return true;
  };

  handleSearch = () => {
    this.resultRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    this.setState(
      {
        services: [],
      },
      () => {
        if (
          this.state.datasource.model.serviceTypesConfig.datasource
            .selectedItem &&
          this.state.datasource.model.citiesConfig.datasource.selectedItem &&
          this.state.datasource.model.countiesConfig.datasource.selectedItem &&
          this.state.datasource.model.brandsConfig.datasource.selectedItem
        ) {
          return this.searchByServiceTypeAndCountyAndBrand();
        }
        if (
          this.state.datasource.model.serviceTypesConfig.datasource
            .selectedItem &&
          this.state.datasource.model.citiesConfig.datasource.selectedItem &&
          this.state.datasource.model.countiesConfig.datasource.selectedItem
        ) {
          return this.searchByServiceTypeAndCityAndCounty();
        }
        if (
          this.state.datasource.model.serviceTypesConfig.datasource
            .selectedItem &&
          this.state.datasource.model.citiesConfig.datasource.selectedItem
        ) {
          return this.searchByServiceTypeAndCity();
        }
      }
    );
  };

  searchByServiceTypeAndCountyAndBrand = () => {
    ApiService.searchByServiceTypeAndCountyAndBrand(
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem,
      this.state.datasource.model.countiesConfig.datasource.selectedItem,
      this.state.datasource.model.brandsConfig.datasource.selectedItem
    ).subscribe((resp) => {
      let visibleItems = [];
      resp.forEach((item) => {
        if (visibleItems.length === 10) {
          return;
        }
        visibleItems.push(item);
      });
      this.setState({
        services: [...resp],
        visibleServices: [...visibleItems],
      });
    });
  };

  searchByServiceTypeAndCityAndCounty = () => {
    ApiService.searchByServiceTypeAndCityAndCounty(
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem,
      this.state.datasource.model.citiesConfig.datasource.selectedItem,
      this.state.datasource.model.countiesConfig.datasource.selectedItem
    ).subscribe((resp) => {
      let visibleItems = [];
      resp.forEach((item) => {
        if (visibleItems.length === 10) {
          return;
        }
        visibleItems.push(item);
      });
      this.setState({
        services: [...resp],
        visibleServices: [...visibleItems],
      });
    });
  };

  searchByServiceTypeAndCity = () => {
    ApiService.searchByServiceTypeAndCity(
      this.state.datasource.model.serviceTypesConfig.datasource.selectedItem,
      this.state.datasource.model.citiesConfig.datasource.selectedItem
    ).subscribe((resp) => {
      let visibleItems = [];
      resp.forEach((item) => {
        if (visibleItems.length === 10) {
          return;
        }
        visibleItems.push(item);
      });
      this.setState({
        services: [...resp],
        visibleServices: [...visibleItems],
      });
    });
  };

  searchMiniRepairByCityAndCounty = () => {
    this.resultRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    ApiService.searchMiniServices(
      this.state.datasource.model.miniRepair.citiesConfig.datasource
        .selectedItem,
      this.state.datasource.model.miniRepair.countiesConfig.datasource
        .selectedItem
    ).subscribe((resp) => {
      let visibleItems = [];
      resp.forEach((item) => {
        if (visibleItems.length === 10) {
          return;
        }
        visibleItems.push(item);
      });
      this.setState({
        services: [...resp],
        visibleServices: [...visibleItems],
      });
    });
  };

  handleExpandMore = () => {
    let newvisibleServices = this.state.services.slice(
      this.state.visibleServices.length,
      this.state.visibleServices.length + 10
    );
    let visibleItems = [...this.state.visibleServices];
    visibleItems.push(...newvisibleServices);
    this.setState({
      visibleServices: [...visibleItems],
    });
  };

  handleAuto = () => {
    window.open(
      "https://www.anadolusigorta.com.tr/tr/iletisim/bize-ulasin/anlasmali-oto-servis-istasyonu-bul",
      "_blank"
    );
  };

  handleMiniRepair = () => {
    window.open(
      "https://www.anadolusigorta.com.tr/tr/iletisim/bize-ulasin/anlasmali-mini-onarim-servis-istasyonu-bul",
      "_blank"
    );
  };

  handleInstitutionsNavigation = () => {
    this.props.history.push("/kurumlar");
  };

  render() {
    const t = this.props.t;
    const akyAgency = this.context.akyAgency;
    return (
      <>
        <MetaDecorator
          title={`Anlaşmalı Yetkili Servisler | ${akyAgency.agencyTitle} Anadolu Sigorta `}
          description={this.state.datasource.model.helmetConfig.description}
        />
        <div className={styles.container}>
          <div className={styles.mainContainer}>
            <div className={styles.titleContainer}>
              <div
                className={styles.institutionsTitleLink}
                onClick={() => this.handleInstitutionsNavigation()}
              >
                {t("institutions.contracted_services.main_path")}
              </div>
              {" > "}
              {t("institutions.contracted_services.sub_path")}
            </div>
            <div className={styles.servicesContainer}>
              <div className={styles.contactedServicesMainContainer}>
                <div className={styles.contractedServicesContainer}>
                  <div
                    className={styles.accordionMainContentContainer}
                    style={{
                      backgroundImage: `url(${AutoServicesImage})`,
                    }}
                  >
                    <div className={styles.contractedLogoContainer}></div>
                    <div className={styles.contractedTitleContainer}>
                      {t("institutions.contracted_services.auto_title")}
                    </div>
                  </div>
                </div>
                <div className={styles.contractedServicesBack}>
                  <AS.Button
                    className={styles.serviceButton}
                    onClick={() => this.handleAuto()}
                    endIcon={<AS.ExitToAppIcon />}
                  >
                    {t("institutions.contracted_services.all_institution")}
                  </AS.Button>
                </div>
              </div>
              <div className={styles.contactedServicesMainContainer}>
                <div className={styles.contractedServicesContainer}>
                  <div
                    className={styles.accordionMainContentContainer}
                    style={{
                      backgroundImage: `url(${MiniRepairServicesImage})`,
                    }}
                  >
                    <div className={styles.contractedLogoContainer}></div>
                    <div className={styles.contractedTitleContainer}>
                      {t("institutions.contracted_services.repair_title")}
                    </div>
                  </div>
                </div>
                <div className={styles.contractedServicesBack}>
                  <AS.Button
                    className={styles.serviceButton}
                    onClick={() => this.handleMiniRepair()}
                    endIcon={<AS.ExitToAppIcon />}
                  >
                    {t("institutions.contracted_services.all_institution")}
                  </AS.Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ContractedServices);
