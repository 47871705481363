import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./productcards.module.scss";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

class ProductCards extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLink = (name) => {
    return name
      .toLowerCase()
      .replace(/[()-/,]/g, "")
      .replace(/\s+/g, "-")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u");
  };

  handleOtherLink = (link) => {
    return link.split("/");
  };

  render() {
    const t = this.props.t;
    return (
      <div className={styles.container}>
        {this.props.config.datasource.parentProducts.map((item) => {
          return (
            <Link
              to={{
                pathname: `/urunler/${this.handleLink(item.name)}`,
                state: { id: item.id, otherProducts: false },
              }}
              className={styles.productLinkContainer}
            >
              <div className={styles.productContainer}>
                <div
                  style={{
                    backgroundImage: `url(${BASE_URL}${item.photoUrl})`,
                  }}
                  className={styles.productImgContainer}
                />
                <div className={styles.productNameContainer}>
                  <div className={styles.name}>{item.name}</div>
                  <div className={styles.infoContainer}>
                    {t("home.product_navigation")}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }
}

export default withTranslation()(ProductCards);
