import React from "react";
import styles from "./messagewithclosebtn.module.scss";
import AS from "../../../../lib/ASLibrary";

const MessageWithCloseBtn = (props) => {
  return (
    <AS.Dialog
      open={props.config.datasource.dialogShow}
      disableBackdropClick={true}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      PaperProps={{
        style: {
          minWidth: "300px",
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <AS.DialogTitle id="form-dialog-title" style={{ paddingBottom: "0" }}>
        <AS.CloseIcon
          className="actCloseIcon"
          onClick={props.config.functions.closeDialog}
        />
      </AS.DialogTitle>
      <AS.DialogContent style={{ paddingTop: "0" }}>
        <div className={styles.contentContainer}>
          {props.config.datasource.message}
        </div>
      </AS.DialogContent>
    </AS.Dialog>
  );
};

export default MessageWithCloseBtn;
