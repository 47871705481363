import React, { createContext, Component } from "react";
export const MessageContext = createContext();

class MessageContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  updateMessages = (toasts) => {
    this.setState((prevState) => {
      let messages = [...prevState.messages];
      messages.unshift(...toasts);
      if (messages.length === 11) {
        messages.splice(10, 1);
      }
      return { messages };
    });
  };

  deleteToast = (index) => {
    this.setState((prevState) => {
      let messages = [...prevState.messages];
      messages.splice(index, 1);
      return { messages };
    });
  };

  render() {
    return (
      <MessageContext.Provider
        value={{
          ...this.state,
          updateMessages: this.updateMessages,
          deleteToast: this.deleteToast,
        }}
      >
        {this.props.children}
      </MessageContext.Provider>
    );
  }
}

export default MessageContextProvider;
