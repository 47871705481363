import React from "react";
import styles from "./registryno.module.scss";
import AS from "../../../../lib/ASLibrary";
import RuhsatImage from "../../../assets/images/ruhsat.jpg";

const RegistryNoDialogHelper = (props) => {
  return (
    <AS.Dialog
      open={props.config.datasource.dialogShow}
      disableBackdropClick={true}
      aria-labelledby="form-dialog-title"
      maxWidth={"none"}
    >
      <AS.DialogTitle id="form-dialog-title" style={{ paddingBottom: "0" }}>
        <AS.CloseIcon
          className="actCloseIcon"
          onClick={props.config.functions.closeDialog}
        />
      </AS.DialogTitle>
      <AS.DialogContent style={{ paddingTop: "0" }}>
        <div className={styles.imageContainer}>
          <img
            src={RuhsatImage}
            alt="Ruhsat"
            className={styles.ruhsatImg}
          ></img>
        </div>
      </AS.DialogContent>
    </AS.Dialog>
  );
};

export default RegistryNoDialogHelper;
