import React, { Component } from "react";
import AS from "../../../../lib/ASLibrary";
import { withStyles } from "@material-ui/core/styles";

const Select = withStyles((theme) => ({
  root: {
    color: "#162a3b",
    width: "85px",
  },
  icon: {
    color: "#162a3b",
  },
}))(AS.Select);

class CustomPlateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <Select {...this.props}>{this.props.children}</Select>;
  }
}

export default CustomPlateSelect;
