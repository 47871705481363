import { debounce } from "lodash";

export const handleArrowVisibility = debounce((scrollLeft, product) => {
  let index = findIndex();
  let formContainerElemet =
    document.getElementsByClassName("saleFormContainer")[index];
  let width = formContainerElemet?.offsetWidth;
  let childrenCount = formContainerElemet?.childElementCount;
  let arrowLeft = document.getElementById("productFormArrowLeft" + product);
  let arrowRight = document.getElementById("productFormArrowRight" + product);
  let itemsWidth = childrenCount * 215;
  if (window.matchMedia("(max-width: 870px)").matches) {
    arrowLeft.style.display = "none";
    arrowRight.style.display = "none";
    return;
  } else {
    arrowLeft.style.display = "";
    arrowRight.style.display = "";
  }
  if (width >= itemsWidth) {
    arrowLeft.style.display = "none";
    arrowRight.style.display = "none";
    arrowLeft.style.visibility = "hidden";
    arrowRight.style.visibility = "hidden";
  } else {
    arrowLeft.style.display = "";
    arrowRight.style.display = "";
    arrowLeft.style.visibility = "visible";
    arrowRight.style.visibility = "visible";
    if (scrollLeft <= 0) {
      let leftArrowIcon = document.getElementById(
        "formLeftArrowIcon" + product
      );
      leftArrowIcon.classList.remove("navigationAnimation");
      arrowLeft.style.pointerEvents = "none";
      leftArrowIcon.classList.add("disabledNavigationAnimation");
      arrowLeft.style.cursor = "initial";
    } else {
      let leftArrowIcon = document.getElementById(
        "formLeftArrowIcon" + product
      );
      leftArrowIcon.classList.add("navigationAnimation");
      leftArrowIcon.classList.remove("disabledNavigationAnimation");
      arrowLeft.style.pointerEvents = "";
      arrowLeft.style.cursor = "pointer";
    }
    if (scrollLeft + formContainerElemet.offsetWidth >= itemsWidth) {
      let rightArrowIcon = document.getElementById(
        "formRightArrowIcon" + product
      );
      rightArrowIcon.classList.remove("navigationAnimation");
      rightArrowIcon.classList.add("disabledNavigationAnimation");
      arrowRight.style.pointerEvents = "none";
      arrowRight.style.cursor = "initial";
    } else {
      let rightArrowIcon = document.getElementById(
        "formRightArrowIcon" + product
      );
      rightArrowIcon.classList.add("navigationAnimation");
      rightArrowIcon.classList.remove("disabledNavigationAnimation");
      rightArrowIcon.style.background = "";
      arrowRight.style.pointerEvents = "";
      arrowRight.style.cursor = "pointer";
    }
  }
}, 50);

export const handleFormLeftArrow = (product) => {
  let index = findIndex();
  let formContainerElemet =
    document.getElementsByClassName("saleFormContainer")[index];
  let width = formContainerElemet.offsetWidth;
  let productSize = Math.floor(width / 215);
  let scrollLeft = formContainerElemet.scrollLeft - productSize * 215;
  formContainerElemet.scrollLeft = scrollLeft;
  handleArrowVisibility(scrollLeft, product);
};

export const handleFormRightArrow = (product) => {
  let index = findIndex();
  let formContainerElemet =
    document.getElementsByClassName("saleFormContainer")[index];
  let width = formContainerElemet.offsetWidth;
  let productSize = Math.floor(width / 215);
  let scrollLeft = formContainerElemet.scrollLeft + productSize * 215;
  formContainerElemet.scrollLeft = scrollLeft;
  handleArrowVisibility(scrollLeft, product);
};

const findIndex = () => {
  let elementIndex;
  let saleContentContainerElemet = document.getElementById(
    "saleContentContainer"
  );
  saleContentContainerElemet.childNodes.forEach((item, index) => {
    if (item.getAttribute("aria-expanded") == "true") {
      elementIndex = index;
    }
  });
  return elementIndex;
};
