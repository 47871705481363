import React, { createContext, useState } from "react";

export const AuthorizationContext = createContext();

const generalAreas = ["/home", "/agreement"];

const AuthorizationContextProvider = (props) => {
  const [areas, setAreas] = useState([]);

  const [akyAgency, setAkyAgency] = useState(undefined);

  const [dapAgency, setDapAgency] = useState(undefined);

  const [permission, setPermission] = useState(undefined);

  const [loading, setLoading] = useState(true);

  const [offerButtonScroll, setOfferButtonScroll] = useState("");

  const [preview, setPreview] = useState(false);

  const updatePreview = (preview) => {
    setPreview(preview);
  };

  const updateOfferButtonScroll = (page) => {
    setOfferButtonScroll(page);
  };

  const updateLoading = (isloading) => {
    setLoading(isloading);
  };

  const updateAreas = (authorizedAreas) => {
    setAreas(authorizedAreas);
  };

  const updateAkyAgency = (agencyInfo) => {
    setAkyAgency(agencyInfo);
  };

  const updateDapAgency = (agencyInfo) => {
    setDapAgency(agencyInfo);
  };

  const updatePermission = (permission) => {
    setPermission(permission);
  };

  const isAuthorizedArea = (pathArea) => {
    let isAuthorized = false;
    if (!generalAreas.includes(pathArea)) {
      this.state.areas.forEach((area, index) => {
        if (area.menuList.length === 0 && pathArea === area.mainPath) {
          isAuthorized = true;
          return;
        }
        area.menuList.forEach((item, itemIndex) => {
          if (pathArea === `${area.mainPath}${item.path}`) {
            isAuthorized = true;
            return;
          }
        });
      });
      if (isAuthorized) {
        return;
      }
    }
  };

  return (
    <div>
      <AuthorizationContext.Provider
        value={{
          areas: [...areas],
          updateAreas: updateAreas,
          isAuthorizedArea: isAuthorizedArea,
          akyAgency: { ...akyAgency },
          updateAkyAgency: updateAkyAgency,
          dapAgency: { ...dapAgency },
          updateDapAgency: updateDapAgency,
          loading,
          updateLoading,
          permission,
          updatePermission,
          offerButtonScroll,
          updateOfferButtonScroll,
          preview,
          updatePreview,
        }}
      >
        {props.children}
      </AuthorizationContext.Provider>
    </div>
  );
};

export default AuthorizationContextProvider;
