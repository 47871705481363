import React, { Component } from "react";
import styles from "./carousel.module.scss";
import { unescape } from "lodash";
import Slider from "react-slick";
import "./slick.scss";
import AS from "../../../lib/ASLibrary";

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

class CarouselHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pause: false,
    };

    this.slider = React.createRef();
  }

  contentHandler = (item) => {
    let unescapedContent = unescape(item.content);
    return (
      <p
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: unescapedContent }}
      />
    );
  };

  play = () => {
    let pause = this.state.pause;
    let newPause = !pause;
    this.setState(
      {
        pause: newPause,
      },
      () => {
        if (!newPause) {
          this.slider.slickPlay();
        } else {
          this.slider.slickPause();
        }
      }
    );
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7000,
      dotsClass: "slick_dot",
      pauseOnHover: false,
    };
    return (
      <div className={styles.carouselContainer}>
        <Slider {...settings} ref={(slider) => (this.slider = slider)}>
          {this.props.items.length > 0 &&
            this.props.items.map((item) => {
              return (
                <div className={styles.contentContainer} key={item.id}>
                  <div className={styles.textContainer}>
                    <div className={styles.titleContainer}>
                      <div className={styles.titleMainContainer}>
                        <div className={styles.title}>
                          {item.title}
                          <div className={styles.divider} />
                        </div>
                      </div>
                      {this.contentHandler(item)}
                    </div>
                  </div>
                  <div className={styles.imgContainer}>
                    <img
                      className={styles.img}
                      alt="slideImage"
                      src={`${BASE_URL}${item.photoUrl}`}
                    />
                  </div>
                </div>
              );
            })}
        </Slider>
        {this.props.items && this.props.items.length > 1 && (
          <div className={styles.playButtonContainer} id="playButton">
            <div className={styles.playButton} onClick={this.play}>
              {this.state.pause ? (
                <AS.PlayArrowIcon className={styles.playIcon} />
              ) : (
                <AS.PauseIcon className={styles.playIcon} />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default CarouselHelper;
