const validateTckn = (tckn) => {
  try {
    const tmp = tckn
      .toString()
      .split("")
      .map((c) => parseInt(c, 10));
    if (tmp.length === 11) {
      let totalOdd = 0,
        totalEven = 0;
      for (let i = 0; i < 9; i++) {
        if (i % 2 === 0) {
          totalOdd += tmp[i];
        } else {
          totalEven += tmp[i];
        }
      }
      const total = totalOdd + totalEven + tmp[9];
      const lastDigit = total % 10;
      if (tmp[10] === lastDigit) {
        const check = (totalOdd * 7 + totalEven * 9) % 10;
        if (tmp[9] === check) {
          return true;
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

const validateVkn = (vergiNo) => {
  try {
    if (vergiNo.toString().length !== 10) {
      return false;
    }
    const vergiNoDigits = vergiNo
      .toString()
      .split("")
      .map((c) => parseInt(c, 10));
    const haneMod10 = [10];
    const haneMod9 = [10];
    let toplam = 0;
    for (let i = 0; i < 9; i++) {
      haneMod10[i] = (vergiNoDigits[i] + 9 - i) % 10;
      haneMod9[i] = (haneMod10[i] * Math.pow(2, 9 - i)) % 9;
      if (haneMod10[i] !== 0 && haneMod9[i] === 0) {
        haneMod9[i] = 9;
      }
      toplam += haneMod9[i];
    }
    haneMod10[9] = vergiNoDigits[9];
    if (toplam % 10 === 0) {
      toplam = 0;
    } else {
      toplam = 10 - (toplam % 10);
    }
    if (haneMod10[9] === toplam) {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};

export const Validation = {
  validateTckn,
  validateVkn,
};
