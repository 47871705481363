import React, { Component } from "react";
import styles from "./cardcarousel.module.scss";
import { unescape } from "lodash";
import Slider from "react-slick";
import "./slick.scss";
import MagicSliderDots from 'react-magic-slider-dots';

const BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL;

class CardCarouselHelper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pause: false,
    };

    this.slider = React.createRef();
  }

  handleContent = (item) => {
    let unescapedContent = unescape(item.content);
    return (
      <div
        className={styles.photoContent}
        dangerouslySetInnerHTML={{ __html: unescapedContent == "undefined" ? '' : unescapedContent }}
        style={{ color: "white" }}
      />
    );
  };

  handleItemsToShow = (view) => {
    let itemsToShow;
    if (this.props.items.length > view) {
      itemsToShow = true;
    } else {
      itemsToShow = false;
    }
    return itemsToShow;
  };

  render() {
    const settings = {
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      draggable: false,
      dots: true,
      speed: 750,
      appendDots: (dots) => {
        return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
      },
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 728,
          settings: {
            infinite: this.handleItemsToShow(1),
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
            focusOnSelect: true,
            className: "center",
            centerMode: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: true,
            arrows: false,
            dots: true,
            className: '',
            centerMode: false,
            speed: 500,
          },
        },
      ],
    };
    return (
      <div className={styles.carouselContainer}>
        <Slider {...settings} ref={(slider) => (this.slider = slider)}>
          {this.props.items.length > 0 &&
            this.props.items.map((item) => {
              if (item.type === "photo") {
                return (
                  <div className={styles.mainContainer} key={item.id}>
                    <div
                      className={styles.photoContainer}
                      style={{
                        backgroundImage: `url(${BASE_URL}${item.photoUrl})`,
                      }}
                    >
                      <div className={styles.imgContainer}>
                        {this.handleContent(item)}
                      </div>
                      <div className={styles.titleContainer}>{item.title}</div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={styles.mainContainer} key={item.id}>
                    <div
                      className={styles.container}
                      style={{
                        backgroundImage: `url(${BASE_URL}${item.photoUrl})`,
                      }}
                    >
                      <div className={styles.imgContainer}>
                        {this.handleContent(item)}
                      </div>
                      <div className={styles.titleContainer}>{item.title}</div>
                    </div>
                  </div>
                );
              }
            })}
        </Slider>
      </div>
    );
  }
}

export default CardCarouselHelper;
