import React from "react";
import { useTranslation } from "react-i18next";
import AS from "../../../lib/ASLibrary";

const AlertHelper = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <AS.Snackbar
        open={props.config.datasource.alertShow}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        action={
          <AS.IconButton
            aria-label="close"
            color="inherit"
            size="medium"
            onClick={() => {
              props.config.functions.closeAlert();
            }}
          >
            <AS.CloseIcon fontSize="inherit" />
          </AS.IconButton>
        }
      >
        <AS.Alert
          className="activateAlert"
          onClose={() => props.config.functions.closeAlert()}
          severity={props.config.datasource.severity}
        >
          {props.config.datasource.message
            ? t(props.config.datasource.message)
            : props.children}
        </AS.Alert>
      </AS.Snackbar>
    </div>
  );
};

export default AlertHelper;
