import React, { Component } from "react";
import styles from "./contact.module.scss";
import MetaDecorator from "../../../util/MetaDecorator";
import _lodash from "lodash";
import { CONTACT_DEFAULT } from "./model";
import MapContainer from "../../../util/MapContainer";
import AS from "../../../lib/ASLibrary";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import ContactFormHelper from "../../helpers/forms/contact/contactform";
import { withRouter } from "react-router";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    textAlign: "center",
    backgroundColor: "#f5f5f5",
    color: "rgb(1, 0, 34)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(20),
    border: "1px solid #dadde9",
    borderRadius: 0,
    fontFamily: "Poppins, sans-serif",
  },
}))(AS.Tooltip);

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasource: {
        model: _lodash.cloneDeep(CONTACT_DEFAULT),
      },
    };
  }

  static contextType = AuthorizationContext;

  handleOpenMail = (mail) => {
    window.location.href = `mailto:${mail}`;
  };

  handlePhoneCall = (phone) => {
    window.location.href = `tel:${phone}`;
  };

  handleContactInfo = () => {
    const dapAgency = this.context.dapAgency;
    const akyAgency = this.context.akyAgency;
    let show = false;
    if (
      akyAgency.addressInfo ||
      akyAgency.phoneInfoList ||
      dapAgency.email ||
      dapAgency.instagram ||
      dapAgency.facebook ||
      dapAgency.twitter ||
      dapAgency.linkedin
    ) {
      show = true;
    }
    return show;
  };

  handleEmail = () => {
    const t = this.props.t;
    const dapAgency = this.context.dapAgency;
    const akyAgency = this.context.akyAgency;
    if (dapAgency.email) {
      return (
        <div className={styles.mailContainer}>
          <div
            className={styles.mail}
            onClick={() => this.handleOpenMail(dapAgency.email)}
          >
            <AS.EmailOutlinedIcon className={styles.infoIcon} />
            {dapAgency.email}
          </div>
          <span className={styles.tooltipText}>{t("footer.send_email")}</span>
        </div>
      );
    } else if (akyAgency.mailInfoList) {
      let generalMailInfo = akyAgency.mailInfoList.find(
        (mail) => mail.emailType.id == "1"
      );
      return (
        <div className={styles.mailContainer}>
          <div
            className={styles.mail}
            onClick={() => this.handleOpenMail(dapAgency.email)}
          >
            <AS.EmailOutlinedIcon className={styles.infoIcon} />
            {generalMailInfo.email}
          </div>
          <span className={styles.tooltipText}>{t("footer.send_email")}</span>
        </div>
      );
    }
    return null;
  };

  render() {
    const dapAgency = this.context.dapAgency;
    const akyAgency = this.context.akyAgency;
    const t = this.props.t;
    return (
      <div className={styles.container}>
        {this.props.location.pathname === "/iletisim" && (
          <MetaDecorator
            title={`${akyAgency.agencyTitle} İletişim Bilgileri | Anadolu Sigorta`}
            description={this.state.datasource.model.helmetConfig.description}
          />
        )}
        <div className={styles.mainContainer}>
          <div className={styles.firstMainContainer}>
            <div className={`${styles.contactTitle} mobile-title-font-size`}>
              {t("footer.sitemap_contact_us")}
            </div>
            <div className={styles.firstContainer}>
              {akyAgency.addressInfo?.coordinate_x &&
                akyAgency.addressInfo?.coordinate_y && (
                  <div className={styles.mapContainer}>
                    <MapContainer
                      coordinateX={akyAgency.addressInfo.coordinate_x}
                      coordinateY={akyAgency.addressInfo.coordinate_y}
                    />
                  </div>
                )}
              {this.handleContactInfo() && (
                <div className={styles.contactContainer}>
                  {akyAgency.agencyTitle ? (
                    <div className={styles.phoneContainer}>
                      <div className={styles.title}>
                        <AS.StoreMallDirectoryOutlinedIcon
                          className={styles.infoIcon}
                        />
                        {akyAgency.agencyTitle}
                      </div>
                    </div>
                  ) : null}
                  {akyAgency.registrationNumber ? (
                    <div className={styles.phoneContainer}>
                      <div className={styles.registry}>
                        <AS.BusinessCenterOutlinedIcon
                          className={styles.infoIcon}
                        />
                        {akyAgency.registrationNumber}
                      </div>
                      <span className={styles.tooltipText}>
                        {t("footer.registry_number")}
                      </span>
                    </div>
                  ) : null}
                  {akyAgency.addressInfo && (
                    <div className={styles.contactContentContainer}>
                      <AS.LocationOnOutlinedIcon className={styles.location} />
                      {akyAgency.addressInfo.fullAddress}
                    </div>
                  )}
                  {akyAgency.phoneInfoList && akyAgency.phoneInfoList[0] ? (
                    <div className={styles.phoneContainer}>
                      <div
                        className={styles.phone}
                        onClick={() =>
                          this.handlePhoneCall(
                            `0${akyAgency.phoneInfoList[0].phoneCode}${akyAgency.phoneInfoList[0].phoneNumber}`
                          )
                        }
                      >
                        <AS.PhoneInTalkOutlinedIcon
                          className={styles.infoIcon}
                        />
                        {`0 (${akyAgency.phoneInfoList[0].phoneCode}) ${akyAgency.phoneInfoList[0].phoneNumber}`}
                      </div>
                      <span className={styles.tooltipText}>
                        {t("footer.call")}
                      </span>
                    </div>
                  ) : null}
                  {this.handleEmail()}
                  <div className={styles.socailsContainer}>
                    {dapAgency.instagram && (
                      <a
                        href={dapAgency.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialLink}
                      >
                        <div className={styles.socialIconContainer}>
                          <AS.InstagramIcon className={styles.socialIcon} />
                        </div>
                      </a>
                    )}
                    {dapAgency.facebook && (
                      <a
                        href={dapAgency.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialLink}
                      >
                        <div className={styles.socialIconContainer}>
                          <AS.FacebookIcon className={styles.socialIcon} />
                        </div>
                      </a>
                    )}
                    {dapAgency.twitter && (
                      <a
                        href={dapAgency.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialLink}
                      >
                        <div className={styles.socialIconContainer}>
                          <AS.TwitterIcon className={styles.socialIcon} />
                        </div>
                      </a>
                    )}
                    {dapAgency.linkedin && (
                      <a
                        href={dapAgency.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.socialLink}
                      >
                        <div className={styles.socialIconContainer}>
                          <AS.LinkedInIcon className={styles.socialIcon} />
                        </div>
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={styles.arrowContainer}>
            <div class="custom-shape-divider-bottom-1618464263">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M649.97 0L599.91 54.12 550.03 0 0 0 0 120 1200 120 1200 0 649.97 0z"
                  class="shape-fill"
                ></path>
              </svg>
            </div>
          </div>
          <div className={styles.contactFormContainer}>
            <ContactFormHelper />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Contact));
