import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AxiosInstance } from "./api/axios-instance";
import App from "./App";
import AuthorizationContextProvider from "./components/contexts/AuthorizationContext";
import MessageContextProvider from "./components/contexts/MessageContext";
import ToastHelper from "./components/helpers/toast/toast";
import "./i18next";
import "./index.css";
import { history } from "./lib/history";
import reportWebVitals from "./reportWebVitals";

const handleBaseName = () => {
  let basename = "";
  if (
    window.location.href.includes("anadolusigorta.com.tr") &&
    process.env.REACT_APP_DAP_ENV !== "development"
  ) {
    basename = "/dap-satis";
  }
  return basename;
};

ReactDOM.render(
  <React.StrictMode>
    <MessageContextProvider>
      <AuthorizationContextProvider>
        <BrowserRouter basename={handleBaseName()} history={history}>
          <App />
          <AxiosInstance />
          <ToastHelper />
        </BrowserRouter>
      </AuthorizationContextProvider>
    </MessageContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
