import { Component } from "react";
import { withRouter } from "react-router";
import { AuthorizationContext } from "../../contexts/AuthorizationContext";
import { ApiService } from "../../services/ApiService";
import { AuthorizationService } from "../../services/authorization";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthorizationContext;

  componentDidMount() {
    if (window.location.href.includes("?")) {
      let location = window.location.href;
      let locationInfoList = location.split("?");
      ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
        let url = locationInfoList[0] + resp;
        let urlParams = this.getUrlParams(url);
        if (urlParams.ac && urlParams.ac != "undefined") {
          ApiService.changeAgent(urlParams.ac).subscribe((resp) => {
            this.changeAgencyValues(resp, urlParams.ac);
          });
        } else {
          const updateLoading = this.context.updateLoading;
          const updatePreview = this.context.updatePreview;
          updatePreview(true);
          sessionStorage.setItem("preview", true);
          updateLoading(false);
          if (urlParams.page) {
            if (urlParams.page === "sale") {
              this.props.history.push(
                `/satis${this.props.history.location.search}`
              );
            } else if (urlParams.page === "about") {
              this.props.history.push(
                `/biz-kimiz${this.props.history.location.search}`
              );
            } else if (urlParams.page === "product") {
              this.props.history.push(
                `/urunler${this.props.history.location.search}`
              );
            } else {
              this.props.history.push(`/${this.props.history.location.search}`);
            }
          }
        }
      });
    }
  }

  getUrlParams = (previewUrl) => {
    let url = new URL(
      decodeURI(previewUrl.replace(/\+/g, "%20"))
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
    );
    let urlParams;
    let match,
      pl = /\+/g,
      search = /([^&=]+)=?([^&]*)/g,
      decode = (s) => decodeURIComponent(s.replace(pl, " ")),
      query = url.href;
    urlParams = {};
    while ((match = search.exec(query))) {
      urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
  };

  changeAgencyValues = (resp, domain) => {
    const updateAkyAgency = this.context.updateAkyAgency;
    const updateDapAgency = this.context.updateDapAgency;
    const updateLoading = this.context.updateLoading;
    const updatePreview = this.context.updatePreview;
    AuthorizationService.setToken(resp);
    ApiService.channelEcommerceInformation().subscribe((akyAgency) => {
      ApiService.getDapAgent("general", domain).subscribe((dapAgency) => {
        updateAkyAgency(akyAgency);
        updateDapAgency(dapAgency);
        this.handlePermission();
        updateLoading(false);
        updatePreview(true);
        sessionStorage.setItem("preview", true);
        let location = window.location.href;
        let locationInfoList = location.split("?");
        ApiService.getRedisInfo(locationInfoList[1]).subscribe((resp) => {
          let url = locationInfoList[0] + resp;
          let urlParams = this.getUrlParams(url);
          if (urlParams.page) {
            if (urlParams.page === "sale") {
              this.props.history.push(
                `/satis${this.props.history.location.search}`
              );
            } else if (urlParams.page === "about") {
              this.props.history.push(
                `/biz-kimiz${this.props.history.location.search}`
              );
            } else if (urlParams.page === "product") {
              this.props.history.push(
                `/urunler${this.props.history.location.search}`
              );
            } else {
              this.props.history.push(`/${this.props.history.location.search}`);
            }
          }
        });
      });
    });
  };

  handlePermission = () => {
    const updatePermission = this.context.updatePermission;
    updatePermission(false);
  };

  render() {
    return null;
  }
}

export default withRouter(Preview);
